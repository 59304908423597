import { GET_DIET_PLAN, GET_DIET_PLAN_FAILURE, GET_DIET_PLAN_SUCCESS } from './actionTypes'

export function getDietPlan(params) {
  return {
    type: GET_DIET_PLAN,
    params,
  }
}

export function getDietPlanSuccess(data) {
  return {
    type: GET_DIET_PLAN_SUCCESS,
    data,
  }
}

export function getDietPlanFailure(error) {
  return {
    type: GET_DIET_PLAN_FAILURE,
    error,
  }
}
