export const ADD_USER = 'ADD_USER'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE'

export const EDIT_USER = 'EDIT_USER'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE'

export const GET_UNAUTH_USERS = 'GET_UNAUTH_USERS'
export const GET_UNAUTH_USERS_SUCCESS = 'GET_UNAUTH_USERS_SUCCESS'
export const GET_UNAUTH_USERS_FAILURE = 'GET_UNAUTH_USERS_FAILURE'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE'

export const ASSIGN_TRAINER = 'ASSIGN_TRAINER'
export const ASSIGN_TRAINER_SUCCESS = 'ASSIGN_TRAINER_SUCCESS'
export const ASSIGN_TRAINER_FAILURE = 'ASSIGN_TRAINER_FAILURE'

export const ASSIGN_PLANS = 'ASSIGN_PLANS'
export const ASSIGN_PLANS_SUCCESS = 'ASSIGN_PLANS_SUCCESS'
export const ASSIGN_PLANS_FAILURE = 'ASSIGN_PLANS_FAILURE'
