/* eslint-disable import/no-cycle */
import {
  combineReducers, applyMiddleware, createStore, compose,
} from 'redux'
import createSagaMiddleware from 'redux-saga'

import rootSaga from './rootSaga'
import dashboard from './containers/Dashboard/DashBoard-reducer'
import auth from './containers/Auth/Auth-reducer'
import sessions from './containers/Sessions/Sessions-reducer'
import common from './containers/Common/Common-reducer'
import payments from './containers/Payments/Payments-reducer'
import yourtrainer from './containers/YourTrainer/YourTrainer-reducer'
import dietplan from './containers/DietPlan/DietPlan-reducer'
import assignedmembers from './containers/AssignedMembers/AssignedMembers-reducer'
import users from './containers/Users/Users-reducer'
import videos from './containers/Videos/Videos-reducer'
import branch from './containers/Branches/Branches-reducer'
import assignedWorkouts from './containers/AssigneWorkouts/AssigneWorkouts-reducer'
import qrcode from './containers/QRcode/QRcode-reducer'

const appReducer = combineReducers({
  dashboard,
  auth,
  sessions,
  common,
  payments,
  yourtrainer,
  dietplan,
  assignedmembers,
  users,
  videos,
  branch,
  qrcode,
  assignedWorkouts,
})

let enhancer

const sagaMiddleware = createSagaMiddleware()

if (process.env.NODE_ENV === 'production') {
  enhancer = applyMiddleware(sagaMiddleware)
} else {
  const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      maxAge: 25,
    })
    : compose
  enhancer = composeEnhancers(applyMiddleware(sagaMiddleware))
}

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_USER_DETAILS') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}
const store = createStore(rootReducer, enhancer)

sagaMiddleware.run(rootSaga)

export default store
