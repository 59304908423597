import { takeLatest, put, call } from 'redux-saga/effects'
import {
  getDocs, query, collection, doc, setDoc, updateDoc,
} from 'firebase/firestore'
import { v4 as uuid } from 'uuid'
import {
  assingNewWorkoutsFailure, assingNewWorkoutsSuccess,
  getWorkoutsSuccess, getWorkoutsFailure,
  updateworkoutsSuccess, updateworkoutsFailure,
  defaultWorkoutSuccess, defaultWorkoutFailure,
} from './actions'
import {
  ASSIGNED_NEW_WORKOUTS, GET_WORKOUTS, UPDATE_WORKOUTS,
  DEFAULT_WORKOUTS,
} from './actionTypes'
import { db } from '../../utils/firebaseConfig'
import { error_msg } from '../../utils/common'
import { GET_PLANS } from '../Common/actionTypes'

function* assingNewWorkouts({ params }) {
  try {
    const sid = uuid()
    const data = yield call(setDoc, doc(db, 'assignworkouts', sid), {
      ...params,
      sid,
    })
    // const data = yield call(getDoc, doc(db, 'users', params.uid))
    if (data && data.error) {
      yield put(assingNewWorkoutsFailure(data))
    } else {
      yield put({ type: GET_WORKOUTS })
      yield put(assingNewWorkoutsSuccess(data))
      // alert('Workout Assined')
    }
  } catch (err) {
    yield put(assingNewWorkoutsFailure(err.message || error_msg))
  }
}

export function* getAssignedMembersSaga() {
  yield takeLatest(ASSIGNED_NEW_WORKOUTS, assingNewWorkouts)
}

function* updateWorkouts({ params }) {
  try {
    const data = yield call(updateDoc, doc(db, 'assignworkouts', params.sid), params)
    // const data = yield call(getDoc, doc(db, 'users', params.uid))
    if (data && data.error) {
      yield put(updateworkoutsFailure(data))
    } else {
      yield put({ type: GET_WORKOUTS })
      yield put(updateworkoutsSuccess(data))
      // alert('Workout updated')
    }
  } catch (err) {
    yield put(updateworkoutsFailure(err.message || error_msg))
  }
}

export function* getupdateMembersSaga() {
  yield takeLatest(UPDATE_WORKOUTS, updateWorkouts)
}

function* getAllAssinedworkouts(actions) {
  try {
    const { userType } = actions

    const q = query(collection(db, 'assignworkouts'))

    const data = yield call(getDocs, q)
    const users = []
    data.forEach(res => users.push(res.data()))
    if (!users.error) {
      yield put(getWorkoutsSuccess(users, userType))
    } else {
      yield put(getWorkoutsFailure(data))
    }
  } catch (err) {
    yield put(getWorkoutsFailure(err.message || error_msg))
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* getAllAssinedworkoutsSaga() {
  yield takeLatest(GET_WORKOUTS, getAllAssinedworkouts)
}

function* assigndefaultWorkouts({ params }) {
  try {
    const { pid, schedule } = params
    const data = yield call(updateDoc, doc(db, 'plans', pid), { schedule })
    if (data && data.error) {
      yield put(defaultWorkoutFailure(data))
    } else {
      yield put({ type: GET_PLANS })
      yield put(defaultWorkoutSuccess(data))
      // alert('Workout Assined')
    }
  } catch (err) {
    yield put(defaultWorkoutFailure(err.message || error_msg))
  }
}

export function* assigndefaultWorkoutsSaga() {
  yield takeLatest(DEFAULT_WORKOUTS, assigndefaultWorkouts)
}
