import Logo from '../assets/svg/common/logo.svg'
import staminlogo from '../assets/svg/common/Staminnewlogo.png'
import LogoPng from '../assets/png/auth/logo.png'
import StaminLogo from '../assets/png/auth/Black.png'

import LoginBanner from '../assets/png/auth/loginBanner.png'
import SignupBanner from '../assets/png/auth/signupBanner.png'
import dashboard from '../assets/png/stamindashboard.jpg'
import Staminlogin from '../assets/png/auth/Staminlogin.png'
import newloginbanner from '../assets/png/auth/newlogin.jpg'
import crown from '../assets/png/auth/crown.png'

import ContactUs from '../assets/png/common/contactus.png'
import USER_IMG from '../assets/png/common/user_img.png'
import ST from '../assets/png/Stamin.png'

import PlaceholderDarkGreyGif from '../assets/gif/placeholder_dark.gif'
import PlaceholderLightGreyGif from '../assets/gif/placeholder-lightgrey.gif'
import PlaceholderLightGif from '../assets/gif/placeholder_light.gif'

import DotLoaderWhite from '../assets/gif/dot_loader_white.gif'
import CircleLoader from '../assets/gif/circle_loader.gif'
import Stamin_loader from '../assets/gif/stamin_loader.gif'

import SUCCESS_LOTTIE from '../assets/lottie_jsons/success.json'
import ERROR_LOTTIE from '../assets/lottie_jsons/failure.json'

export default {
  Logo,
  staminlogo,
  LogoPng,
  dashboard,
  StaminLogo,

  LoginBanner,
  SignupBanner,
  Staminlogin,
  newloginbanner,
  crown,

  ContactUs,
  USER_IMG,

  PlaceholderDarkGreyGif,
  PlaceholderLightGreyGif,
  PlaceholderLightGif,
  Stamin_loader,

  DotLoaderWhite,
  CircleLoader,
  ST,

  SUCCESS_LOTTIE,
  ERROR_LOTTIE,
}
