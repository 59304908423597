import { takeLatest, put, call } from 'redux-saga/effects'
import {
  getDoc, doc,
} from 'firebase/firestore'
import {
  fetchTrainerDetailsSuccess,
  fetchTrainerDetailsFailure,
} from './actions'
import {
  FETCH_TRAINER_DETAILS,
} from './actionTypes'
import { db } from '../../utils/firebaseConfig'

import { error_msg } from '../../utils/common'

function* fetchTrainerDetails({ params }) {
  try {
    // const q = query(collection(db, 'users'), where('uid', '==', params.uid))
    const data = yield call(getDoc, doc(db, 'users', params.uid))
    const trainer = data.data()

    if (trainer.uid) {
      yield put(fetchTrainerDetailsSuccess(trainer))
    } else {
      yield put(fetchTrainerDetailsFailure(data.error))
    }
  } catch (err) {
    yield put(fetchTrainerDetailsFailure(err.message || error_msg))
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* fetchTrainerDetailsSaga() {
  yield takeLatest(FETCH_TRAINER_DETAILS, fetchTrainerDetails)
}
