import React from 'react'
import { capitalize } from 'lodash'
import { SPACING, theme } from '../../Theme'
import { withTheme } from '../../Theme/ThemeProvider'
import Button from '../../UI/Button'
import CustomText from '../../UI/CustomText'
import { CURRENCY_SYMBOL } from '../../utils/consts'

function AdminPlanSection({
  styles, horizontal = true, plans, createPlan,
  onTakeAction,
}) {
  const allPlans = Object.values(plans)

  const onAction = (param, action) => {
    onTakeAction(param, action)
  }

  const renderPlanCard = (payment) => {
    const {
      packageAmount, name, color, plan_id, id,
    } = payment

    return (
      <div key={plan_id} className={styles.card}>
        <div className={styles.cardHeader}>
          <CustomText size="large" weight="medium" color={color}>{capitalize(name)}</CustomText>
        </div>
        <div className={styles.cardFooter}>
          <CustomText weight="semi_bold" color="white" size="large">{`${CURRENCY_SYMBOL} ${packageAmount}`}</CustomText>
          &nbsp;&nbsp;&nbsp;
          <div className={styles.actionBar}>
            <Button
              text="Edit"
              mode="text"
              labelColor="orange"
              transparent
              labelStyle={styles.actionableLabel}
              onPress={() => onAction(payment, 'edit')}
            />
            <Button
              text="Edit Workouts"
              mode="text"
              labelColor="white"
              transparent
              labelStyle={styles.actionableLabel}
              onPress={() => onAction(payment, 'edit_workouts')}
            />
            <Button
              text="Delete"
              mode="text"
              labelColor="red"
              transparent
              labelStyle={styles.actionableLabel}
              onPress={() => onAction(id, 'delete')}
            />
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.plancontainer}>
          <CustomText size="medium" className={styles.plantext} color="white" weight="semi_bold">
            All Plans
          </CustomText>
        </div>
        <Button
          text="Create Plan"
          buttonColor="orange"
          uppercase
          mode="outlined"
          labelStyle={styles.createPlanBtn}
          onPress={createPlan}
        />
      </div>

      <div className={styles[horizontal ? 'cardsContainerHorizontal' : 'cardsContainer']}>
        {allPlans.map(renderPlanCard)}
      </div>
    </div>
  )
}

const stylesheet = {
  container: {
    backgroundColor: '#494949',
    // border: '1px solid #ffcc80',
    borderRadius: 12,
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_20}`,
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: SPACING.SPACE_20,
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING.SPACE_20,
    margin: `${SPACING.SPACE_20} 0`,
  },
  cardsContainerHorizontal: {
    display: 'flex',
    gap: SPACING.SPACE_20,
    margin: `${SPACING.SPACE_20} 0`,
    flexWrap: 'wrap',
  },
  card: {
    borderRadius: 12,
    border: `1px solid ${theme.orangeLightBg}`,
    padding: `${SPACING.SPACE_16} ${SPACING.SPACE_16}`,
    minWidth: 270,
    backgroundColor: '#393939',
    '&:hover': {
      backgroundColor: '#505050',
      borderRadius: '15px',
    },
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: SPACING.SPACE_20,
  },
  cardFooter: {
    // display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: SPACING.SPACE_20,
  },
  cardFooterActionable: {
    display: 'flex',
    gap: SPACING.SPACE_10,
  },
  actionBar: {
    display: 'flex',
    gap: SPACING.SPACE_10,
  },
  actionableLabel: {
    textDecoration: 'underline',
  },
  payNowBtn: {
  },
  plancontainer: {
    backgroundColor: '#FC7124',
    width: '12rem',
    height: '2rem',
    borderRadius: '2rem',
    textAlign: 'center',
    // position: 'relative',
    // top: '-2rem',
    marginTop: '-3.6rem',
    marginBottom: '1rem',
  },
  plantext: {
    padding: '0.3rem',
  },
}

export default withTheme(stylesheet)(AdminPlanSection)
