import { COLORS } from '../../Theme'

export const PLANS = {
  PLAN1: {
    name: 'Plan 1',
    price: 2500,
    features: [
      'Unlimited access to all classes',
      'Free cardio every week once',
      'Free yoga every week once',
      'Free Zumba every week once',
      '5 Leave carry forward',
    ],
    color: 'orange',
    bgColor: COLORS.ORANGE_100,
  },
  PLAN2: {
    name: 'Plan 2',
    price: 3500,
    features: [
      'Unlimited access to all classes',
      'Free cardio every week twice',
      'Free yoga every week twice',
      'Free Zumba every week twice',
      '10 Leave carry forward',
    ],
    color: 'blue',
    bgColor: COLORS.BLUE_100,
  },
  PLAN3: {
    name: 'Plan 3',
    price: 4500,
    features: [
      'Unlimited access to all classes',
      'Free cardio every week thrice',
      'Free yoga every week thrice',
      'Free Zumba every week thrice',
      '15 Leave carry forward',
    ],
    color: 'purple',
    bgColor: COLORS.PURPLE_100,
  },
  PLAN4: {
    name: 'Plan 1',
    price: 2500,
    features: [
      'Unlimited access to all classes',
      'Free cardio every week once',
      'Free yoga every week once',
      'Free Zumba every week once',
      '5 Leave carry forward',
    ],
    color: 'orange',
    bgColor: COLORS.ORANGE_100,
  },
  PLAN5: {
    name: 'Plan 2',
    price: 3500,
    features: [
      'Unlimited access to all classes',
      'Free cardio every week twice',
      'Free yoga every week twice',
      'Free Zumba every week twice',
      '10 Leave carry forward',
    ],
    color: 'blue',
    bgColor: COLORS.BLUE_100,
  },
  PLAN6: {
    name: 'Plan 3',
    price: 4500,
    features: [
      'Unlimited access to all classes',
      'Free cardio every week thrice',
      'Free yoga every week thrice',
      'Free Zumba every week thrice',
      '15 Leave carry forward',
    ],
    color: 'purple',
    bgColor: COLORS.PURPLE_100,
  },
}

export const PLANS_COLOR_MAP = [
  {
    color: 'orange',
    bgColor: COLORS.ORANGE_100,
  },
  {
    color: 'blue',
    bgColor: COLORS.BLUE_100,
  },
  {
    color: 'purple',
    bgColor: COLORS.PURPLE_100,
  },
  {
    color: 'red',
    bgColor: COLORS.RED_100,
  },
  {
    color: 'green',
    bgColor: COLORS.GREEN_100,
  },
  {
    color: 'violet',
    bgColor: COLORS.VOILET_100,
  },
]

export const DUMMY = ''
