import { GET_DIET_PLAN, GET_DIET_PLAN_FAILURE, GET_DIET_PLAN_SUCCESS } from './actionTypes'

const initialState = {
  isFetchingDietPlan: false,
  dietPlanResp: [],
  fetchDietPlanErr: false,
  fetchDietPlanErrMsg: '',
}

const dietplan = (state = initialState, action) => {
  switch (action.type) {
    case GET_DIET_PLAN: {
      return {
        ...state,
        isFetchingDietPlan: true,
      }
    }

    case GET_DIET_PLAN_SUCCESS:
      return {
        ...state,
        isFetchingDietPlan: false,
        dietPlanResp: action.data,
      }

    case GET_DIET_PLAN_FAILURE:
      return {
        ...state,
        isFetchingDietPlan: false,
        fetchDietPlanErr: true,
        fetchDietPlanErrMsg: action.error,
      }

    default:
      return state
  }
}

export default dietplan
