/* eslint-disable import/no-cycle */
import { all, fork } from 'redux-saga/effects'
import * as dashboardSagas from './containers/Dashboard/DashBoard-saga'
import * as authSagas from './containers/Auth/Auth-saga'
import * as sessionsSagas from './containers/Sessions/Sessions-saga'
import * as commonSagas from './containers/Common/Common-saga'
import * as paymentsSagas from './containers/Payments/Payments-saga'
import * as yourTrainer from './containers/YourTrainer/YourTrainer-saga'
import * as dietPlan from './containers/DietPlan/DietPlan-saga'
import * as assignedMembers from './containers/AssignedMembers/AssignedMembers-saga'
import * as usersSagas from './containers/Users/Users-saga'
import * as videosSagas from './containers/Videos/Videos-saga'
import * as branchSagas from './containers/Branches/Branches-saga'
import * as assignedWorkoutssaga from './containers/AssigneWorkouts/AssigneWorkouts-saga'
import * as qrcodesaga from './containers/QRcode/QRcode-saga'

const dashboard = Object.values(dashboardSagas).map((saga) => {
  return fork(saga)
})

const auth = Object.values(authSagas).map((saga) => {
  return fork(saga)
})

const sessions = Object.values(sessionsSagas).map((saga) => {
  return fork(saga)
})

const common = Object.values(commonSagas).map((saga) => {
  return fork(saga)
})

const payments = Object.values(paymentsSagas).map((saga) => {
  return fork(saga)
})

const yourtrainer = Object.values(yourTrainer).map((saga) => {
  return fork(saga)
})

const dietplan = Object.values(dietPlan).map((saga) => {
  return fork(saga)
})

const assignedmembers = Object.values(assignedMembers).map((saga) => {
  return fork(saga)
})

const users = Object.values(usersSagas).map((saga) => {
  return fork(saga)
})

const videos = Object.values(videosSagas).map((saga) => {
  return fork(saga)
})

const branch = Object.values(branchSagas).map((saga) => {
  return fork(saga)
})

const assignedWorkouts = Object.values(assignedWorkoutssaga).map((saga) => {
  return fork(saga)
})

const qrcode = Object.values(qrcodesaga).map((saga) => {
  return fork(saga)
})

export default function* root() {
  yield all([
    ...dashboard,
    ...auth,
    ...sessions,
    ...common,
    ...payments,
    ...yourtrainer,
    ...dietplan,
    ...assignedmembers,
    ...users,
    ...videos,
    ...branch,
    ...assignedWorkouts,
    ...qrcode,
  ])
}
