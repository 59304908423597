import {
  getDocs, query, collection,
} from 'firebase/firestore'
import { call, put, takeLatest } from 'redux-saga/effects'
import { error_msg } from '../../utils/consts'
import { getAllUsersFailure, getAllUsersSuccess } from './actions'
import { GET_ALL_USERS } from './actionTypes'
import { db } from '../../utils/firebaseConfig'

function* getAllUsers(actions) {
  try {
    const { userType } = actions

    const q = query(collection(db, 'users'))

    const data = yield call(getDocs, q)
    const users = []
    data.forEach(res => users.push(res.data()))
    if (!users.error) {
      yield put(getAllUsersSuccess(users, userType))
    } else {
      yield put(getAllUsersFailure(data))
    }
  } catch (err) {
    yield put(getAllUsersFailure(err.message || error_msg))
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* getAllUsersSaga() {
  yield takeLatest(GET_ALL_USERS, getAllUsers)
}
