import {
  doc, setDoc, getDocs, query, collection, updateDoc, deleteDoc,
} from 'firebase/firestore'
import { call, put, takeLatest } from 'redux-saga/effects'
import { v4 as uuid } from 'uuid'
import { error_msg } from '../../utils/consts'
import { db } from '../../utils/firebaseConfig'
import {
  addVideoFailure, addVideoSuccess,
  getVideosFailure, getVideosSuccess,
  deleteVideoFailure, deleteVideoSuccess,
  editVideoFailure, editVideoSuccess,
} from './actions'
import {
  ADD_VIDEO, GET_VIDEOS,
  EDIT_VIDEO, DELETE_VIDEO,
} from './actionTypes'

function* addVideo(action) {
  try {
    const { params } = action

    const id = uuid()
    const data = yield call(setDoc, doc(db, 'videos', id), {
      ...params,
      id,
    })

    if (data && data.error) {
      yield put(addVideoFailure(data))
    } else {
      yield put({ type: GET_VIDEOS })
      yield put(addVideoSuccess(data))
    }
  } catch (err) {
    yield put(addVideoFailure(err.message || error_msg))
  }
}

export function* addVideoSaga() {
  yield takeLatest(ADD_VIDEO, addVideo)
}

function* editVideo({ params }) {
  try {
    const { id, status } = params
    const q = doc(db, 'videos', id)
    const data = yield call(updateDoc, q, { status })

    if (data && data.error) {
      yield put(editVideoFailure(data.error))
    } else {
      yield put({ type: GET_VIDEOS })
      yield put(editVideoSuccess(data))
    }
  } catch (err) {
    yield put(editVideoFailure(err.message || error_msg))
  }
}

export function* editVideoSaga() {
  yield takeLatest(EDIT_VIDEO, editVideo)
}

function* getVideos() {
  try {
    // const { params } = action

    const q = query(collection(db, 'videos'))

    const data = yield call(getDocs, q)
    const videos = []
    data.forEach(res => videos.push(res.data()))

    if (videos && videos.error) {
      yield put(getVideosFailure(data))
    } else {
      yield put(getVideosSuccess(videos))
    }
  } catch (err) {
    yield put(getVideosFailure(err.message || error_msg))
  }
}

export function* getVideosSaga() {
  yield takeLatest(GET_VIDEOS, getVideos)
}

function* deleteVideo({ params }) {
  try {
    const { id } = params

    const q = doc(db, 'videos', id)
    const data = yield call(deleteDoc, q)

    if (data && data.error) {
      yield put(deleteVideoFailure(data.error))
    } else {
      yield put({ type: GET_VIDEOS })
      yield put(deleteVideoSuccess(data))
    }
  } catch (err) {
    yield put(deleteVideoFailure(err.message || error_msg))
  }
}

export function* deleteVideoSaga() {
  yield takeLatest(DELETE_VIDEO, deleteVideo)
}
