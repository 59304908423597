import { FETCH_TRAINER_DETAILS, FETCH_TRAINER_DETAILS_FAILURE, FETCH_TRAINER_DETAILS_SUCCESS } from './actionTypes'

const initialState = {
  isFetchingTrainerDetails: false,
  fetchTrainerDetailsErr: false,
  fetchTrainerDetailsErrMsg: '',
  yourTrainerData: {},
  windows: {},
}

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRAINER_DETAILS:
      return {
        ...state,
        isFetchingTrainerDetails: true,
      }

    case FETCH_TRAINER_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchingTrainerDetails: false,
        yourTrainerData: action.data,
      }

    case FETCH_TRAINER_DETAILS_FAILURE:
      return {
        ...state,
        isFetchingTrainerDetails: false,
        fetchTrainerDetailsErr: true,
        fetchTrainerDetailsErrMsg: action.error,
      }

    default:
      return state
  }
}

export default dashboard
