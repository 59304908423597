import {
  BOOK_SESSION, BOOK_SESSION_FAILURE, BOOK_SESSION_SUCCESS,
  GET_SESSIONS, GET_SESSIONS_FAILURE, GET_SESSIONS_SUCCESS,
  DELETE_SESSION, DELETE_SESSION_FAILURE, DELETE_SESSION_SUCCESS,
  GET_SESSION_DETAILS, GET_SESSION_DETAILS_FAILURE, GET_SESSION_DETAILS_SUCCESS,
  ARCHIVE_SESSION, ARCHIVE_SESSION_FAILURE, ARCHIVE_SESSION_SUCCESS,
  START_SESSION, START_SESSION_FAILURE, START_SESSION_SUCCESS,
} from './actionTypes'

const initialState = {
  isCreatingSession: false,
  createSessionResp: null,
  createSessionErr: false,
  createSessionErrMsg: '',

  isFetchingSessions: false,
  upcomingSessions: [],
  upcomingSessionsUser: [],
  upcomingSessionsTrainer: [],
  allSessions: [],
  allSessionsUser: [],
  allSessionsTrainer: [],
  fetchSessionsErr: false,
  fetchSessionsErrMsg: '',

  isDeletingSession: false,
  sessionDeleted: false,
  deleteSessionErr: false,
  deleteSessionErrMsg: '',

  isFetchingSessionDetails: false,
  sessionDetails: {},
  fetchSessionDetailsErr: false,
  fetchSessionDetailsErrMsg: '',

  isArchivingSession: false,
  sessionArchived: false,
  archiveSessionErr: false,
  archiveSessionErrMsg: '',

  isStartingSession: false,
  sessionStarted: false,
  startSessionErr: false,
  startSessionErrMsg: '',
  alsession: [],
}

const sessions = (state = initialState, action) => {
  switch (action.type) {
    case BOOK_SESSION:
      return {
        ...state,
        isCreatingSession: true,
        createSessionResp: null,
      }

    case BOOK_SESSION_SUCCESS:
      return {
        ...state,
        isCreatingSession: false,
        createSessionResp: action.data,
      }

    case BOOK_SESSION_FAILURE:
      return {
        ...state,
        isCreatingSession: false,
        createSessionErr: true,
        createSessionErrMsg: action.error,
      }

    case GET_SESSIONS:
      return {
        ...state,
        isFetchingSessions: true,
      }

    case GET_SESSIONS_SUCCESS: {
      const { data, userType } = action
      const alsession = data
      const isUser = userType === 'member'
      const upcomingSessions = data.filter((session) => {
        const date = new Date(session.scheduled_for)
        const today = new Date()
        return date >= today
      })
      const allSessions = data.filter((session) => {
        const isArchived = session.is_archived || false
        return !isArchived
      }).map((session) => {
        // check if session is in the past
        const date = new Date(session.scheduled_for)
        const today = new Date()
        const isPast = date < today
        return {
          ...session,
          status: session.status || isPast ? 1 : 2,
        }
      })
      const newState = {
        [isUser ? 'upcomingSessionsUser' : 'upcomingSessionsTrainer']: upcomingSessions,
        [isUser ? 'allSessionsUser' : 'allSessionsTrainer']: allSessions,
        allSessions,
        upcomingSessions,
      }
      return {
        ...state,
        isFetchingSessions: false,
        alsession, // this alsession added by surya
        ...newState,
      }
    }

    case GET_SESSIONS_FAILURE:
      return {
        ...state,
        isFetchingSessions: false,
        fetchSessionsErr: true,
        fetchSessionsErrMsg: action.error,
      }

    case DELETE_SESSION:
      return {
        ...state,
        isDeletingSession: true,
        sessionDeleted: false,
      }

    case DELETE_SESSION_SUCCESS:
      return {
        ...state,
        isDeletingSession: false,
        sessionDeleted: true,
      }

    case DELETE_SESSION_FAILURE:
      return {
        ...state,
        isDeletingSession: false,
        deleteSessionErr: true,
        deleteSessionErrMsg: action.error,
      }

    case GET_SESSION_DETAILS:
      return {
        ...state,
        isFetchingSessionDetails: true,
      }

    case GET_SESSION_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchingSessionDetails: false,
        sessionDetails: action.data,
      }

    case GET_SESSION_DETAILS_FAILURE:
      return {
        ...state,
        isFetchingSessionDetails: false,
        fetchSessionDetailsErr: true,
        fetchSessionDetailsErrMsg: action.error,
      }

    case ARCHIVE_SESSION:
      return {
        ...state,
        isArchivingSession: true,
      }

    case ARCHIVE_SESSION_SUCCESS:
      return {
        ...state,
        isArchivingSession: false,
        sessionArchived: true,
      }

    case ARCHIVE_SESSION_FAILURE:
      return {
        ...state,
        isArchivingSession: false,
        archiveSessionErr: true,
        archiveSessionErrMsg: action.error,
      }

    case START_SESSION:
      return {
        ...state,
        isStartingSession: true,
      }

    case START_SESSION_SUCCESS:
      return {
        ...state,
        isStartingSession: false,
        sessionStarted: true,
      }

    case START_SESSION_FAILURE:
      return {
        ...state,
        isStartingSession: false,
        startSessionErr: true,
        startSessionErrMsg: action.error,
      }

    default:
      return state
  }
}

export default sessions
