import {
  LOGIN_USER, LOGIN_USER_FAILURE, LOGIN_USER_SUCCESS,
  SIGN_UP, SIGN_UP_SUCCESS, SIGN_UP_FAILURE,
  AUTHENTICATE_TOKEN, AUTHENTICATE_TOKEN_FAILURE, AUTHENTICATE_TOKEN_SUCCESS,
  FORGOT_PASSWORD, FORGOT_PASSWORD_FAILURE, FORGOT_PASSWORD_SUCCESS,
  UPDATE_PASSWORD, UPDATE_PASSWORD_FAILURE, UPDATE_PASSWORD_SUCCESS,
  UPDATE_NUMBER, UPDATE_NUMBER_SUCCESS, UPDATE_NUMBER_FAILURE,
  UPDATE_PROFILE_IMG, UPDATE_PROFILE_IMG_SUCCESS, UPDATE_PROFILE_IMG_FAILURE,
  UPDATE_EMAIL_VERIFICATION, UPDATE_EMAIL_VERIFICATION_SUCCESS, UPDATE_EMAIL_VERIFICATION_FAILURE,
} from './actionTypes'

export function loginUser(params, headers) {
  return {
    type: LOGIN_USER,
    params,
    headers,
  }
}

export function loginUserSuccess(data, token) {
  return {
    type: LOGIN_USER_SUCCESS,
    data,
    token,
  }
}

export function loginUserFailure(error) {
  return {
    type: LOGIN_USER_FAILURE,
    error,
  }
}

export function signUp(params, headers) {
  return {
    type: SIGN_UP,
    params,
    headers,
  }
}

export function signUpSuccess(data, token) {
  return {
    type: SIGN_UP_SUCCESS,
    data,
    token,
  }
}

export function signUpFailure(error) {
  return {
    type: SIGN_UP_FAILURE,
    error,
  }
}

export function authenticateToken(params) {
  return {
    type: AUTHENTICATE_TOKEN,
    params,
  }
}

export function authenticateTokenSuccess(data, token) {
  return {
    type: AUTHENTICATE_TOKEN_SUCCESS,
    data,
    token,
  }
}

export function authenticateTokenFailure(error) {
  return {
    type: AUTHENTICATE_TOKEN_FAILURE,
    error,
  }
}

export function forgotPassword(params) {
  return {
    type: FORGOT_PASSWORD,
    params,
  }
}

export function forgotPasswordSuccess(data) {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    data,
  }
}

export function forgotPasswordFailure(error) {
  return {
    type: FORGOT_PASSWORD_FAILURE,
    error,
  }
}

export function updatePassword(params) {
  return {
    type: UPDATE_PASSWORD,
    params,
  }
}

export function updatePasswordSuccess(data) {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    data,
  }
}

export function updatePasswordFailure(error) {
  return {
    type: UPDATE_PASSWORD_FAILURE,
    error,
  }
}
export function updateNumber(params) {
  return {
    type: UPDATE_NUMBER,
    params,
  }
}

export function updateNumberSuccess(data) {
  return {
    type: UPDATE_NUMBER_SUCCESS,
    data,
  }
}

export function updateNumberFailure(error) {
  return {
    type: UPDATE_NUMBER_FAILURE,
    error,
  }
}

export function updatepic(params) {
  return {
    type: UPDATE_PROFILE_IMG,
    params,
  }
}

export function updatepicSuccess(data) {
  return {
    type: UPDATE_PROFILE_IMG_SUCCESS,
    data,
  }
}

export function updatepicFailure(error) {
  return {
    type: UPDATE_PROFILE_IMG_FAILURE,
    error,
  }
}

export function UpdateEmailverifaction(params) {
  return {
    type: UPDATE_EMAIL_VERIFICATION,
    params,
  }
}

export function UpdateEmailverifactionSuccess(data) {
  return {
    type: UPDATE_EMAIL_VERIFICATION_SUCCESS,
    data,
  }
}

export function UpdateEmailverifactionFailure(error) {
  return {
    type: UPDATE_EMAIL_VERIFICATION_FAILURE,
    error,
  }
}
