import {
  getDoc, doc,
} from 'firebase/firestore'
import { call, put, takeLatest } from 'redux-saga/effects'
import { error_msg } from '../../utils/consts'
import { getDietPlanSuccess, getDietPlanFailure } from './actions'
import { GET_DIET_PLAN } from './actionTypes'
import { db } from '../../utils/firebaseConfig'

function* getDietPlan(actions) {
  try {
    const { params, userType } = actions
    const data = yield call(getDoc, doc(db, 'dietplans', params.uid))
    const dietPlan = data.data()

    yield put(getDietPlanSuccess(dietPlan, userType))
  } catch (err) {
    yield put(getDietPlanFailure(err.message || error_msg))
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* getDietPlanSaga() {
  yield takeLatest(GET_DIET_PLAN, getDietPlan)
}
