import {
  ADD_USER, ADD_USER_FAILURE, ADD_USER_SUCCESS,
  GET_UNAUTH_USERS, GET_UNAUTH_USERS_SUCCESS, GET_UNAUTH_USERS_FAILURE,
  DELETE_USER, DELETE_USER_FAILURE, DELETE_USER_SUCCESS,
  ASSIGN_TRAINER, ASSIGN_TRAINER_FAILURE, ASSIGN_TRAINER_SUCCESS,
  EDIT_USER, EDIT_USER_FAILURE, EDIT_USER_SUCCESS,
  ASSIGN_PLANS, ASSIGN_PLANS_SUCCESS, ASSIGN_PLANS_FAILURE,
} from './actionTypes'

export function addUser(params) {
  return {
    type: ADD_USER,
    params,
  }
}

export function addUserSuccess(data) {
  return {
    type: ADD_USER_SUCCESS,
    data,
  }
}

export function addUserFailure(error) {
  return {
    type: ADD_USER_FAILURE,
    error,
  }
}

export function getUnauthUsers(params) {
  return {
    type: GET_UNAUTH_USERS,
    params,
  }
}

export function getUnauthUsersSuccess(data) {
  return {
    type: GET_UNAUTH_USERS_SUCCESS,
    data,
  }
}

export function getUnauthUsersFailure(error) {
  return {
    type: GET_UNAUTH_USERS_FAILURE,
    error,
  }
}

export function deleteUser(params) {
  return {
    type: DELETE_USER,
    params,
  }
}

export function deleteUserSuccess(data) {
  return {
    type: DELETE_USER_SUCCESS,
    data,
  }
}

export function deleteUserFailure(error) {
  return {
    type: DELETE_USER_FAILURE,
    error,
  }
}

export function assignTrainer(params) {
  return {
    type: ASSIGN_TRAINER,
    params,
  }
}

export function assignTrainerSuccess(data) {
  return {
    type: ASSIGN_TRAINER_SUCCESS,
    data,
  }
}

export function assignTrainerFailure(error) {
  return {
    type: ASSIGN_TRAINER_FAILURE,
    error,
  }
}

export function editUser(params) {
  return {
    type: EDIT_USER,
    params,
  }
}

export function editUserSuccess(data) {
  return {
    type: EDIT_USER_SUCCESS,
    data,
  }
}

export function editUserFailure(error) {
  return {
    type: EDIT_USER_FAILURE,
    error,
  }
}

export function assingplan(params) {
  return {
    type: ASSIGN_PLANS,
    params,
  }
}

export function assingPlanSuccess(data) {
  return {
    type: ASSIGN_PLANS_SUCCESS,
    data,
  }
}

export function assingPlanFailure(error) {
  return {
    type: ASSIGN_PLANS_FAILURE,
    error,
  }
}
