// DANGER remove cycle dep
/* eslint-disable import/no-cycle */
import React, { lazy } from 'react'
import {
  Route,
} from 'react-router-dom'
import Plans from './components/Plans'
import SessionDetails from './components/Sessions/SessionDetails'
import Billing from './components/Billing/Billing'
import Profile from './components/Profile/Profile'
import AssignWorkot from './components/AssignedMember/AssingWorkouts'

import { pathname_mapping } from './utils/navigationConstant'
import ProfileBMI from './components/Profile/ProfileBMI'
import AssignWorkoutPlan from './components/AssignedMember/AssignWorkoutPlan'
import EmailVerification from './components/AssignedMember/EmailVerification'
import Invoice from './components/Billing/Invoice'
import Plansubscription from './components/Billing/Plansubscription'
import Verificationpage from './components/AssignedMember/Verificationpage'

const Dashboard = lazy(() => import('./containers/Dashboard/DashBoard-redux'))
const Sessions = lazy(() => import('./containers/Sessions/Sessions-redux'))
const Payments = lazy(() => import('./containers/Payments/Payments-redux'))
const DietPlan = lazy(() => import('./containers/DietPlan/DietPlan-redux'))
const YourTrainer = lazy(() => import('./containers/YourTrainer/YourTrainer-redux'))
const AssignedMembers = lazy(() => import('./containers/AssignedMembers/AssignedMembers-redux'))
const Chats = lazy(() => import('./containers/Chats/Chats-redux'))
const Videos = lazy(() => import('./containers/Videos/Videos-redux'))
const Users = lazy(() => import('./containers/Users/Users-redux'))
const Report = lazy(() => import('./containers/Report/Report-redux'))
const Branches = lazy(() => import('./containers/Branches/Branches-redux'))
const AssignWorkots = lazy(() => import('./containers/AssigneWorkouts/AssigneWorkouts-redux'))
const QRcode = lazy(() => import('./containers/QRcode/QRcode-redux'))

export const AuthRoutes = [
  { Component: Dashboard, path: pathname_mapping.Dashboard, exact: true },
  { Component: Sessions, path: pathname_mapping.Sessions, exact: true },
  { Component: Payments, path: pathname_mapping.Payments, exact: true },
  { Component: DietPlan, path: pathname_mapping.DietPlan, exact: true },
  { Component: YourTrainer, path: pathname_mapping.YourTrainer, exact: true },
  { Component: SessionDetails, path: pathname_mapping.SessionDetails, exact: true },
  { Component: AssignedMembers, path: pathname_mapping.AssignedMembers, exact: true },
  { Component: Chats, path: pathname_mapping.Chats, exact: true },
  { Component: Videos, path: pathname_mapping.Videos, exact: false },
  { Component: Users, path: pathname_mapping.Members, exact: true },
  { Component: Users, path: pathname_mapping.Trainers, exact: true }, // component Added branchadmin
  { Component: Users, path: pathname_mapping.BranchAdmin, exact: true },
  { Component: Payments, path: pathname_mapping.PaymentsAndPlans, exact: true },
  { Component: EmailVerification, path: pathname_mapping.EmailVerification, exact: true },
  { Component: Plans, path: pathname_mapping.Plans, exact: true },
  { Component: Report, path: pathname_mapping.Report, exact: true },
  { Component: Billing, path: pathname_mapping.Billing, exact: true },
  { Component: Plans, path: pathname_mapping.PlansDashboard, exact: true },
  { Component: Profile, path: pathname_mapping.Profile, exact: true },
  { Component: ProfileBMI, path: pathname_mapping.ProfileBMI, exact: true },
  { Component: Branches, path: pathname_mapping.Branches, exact: true },
  { Component: AssignWorkot, path: pathname_mapping.AssignWorkot, exact: true },
  { Component: AssignWorkots, path: pathname_mapping.AssignWorkots, exact: true },
  { Component: AssignWorkoutPlan, path: pathname_mapping.AssignWorkoutPlan, exact: true },
  { Component: Invoice, path: pathname_mapping.Invoice, exact: true },
  { Component: QRcode, path: pathname_mapping.QRcode, exact: true },
  { Component: Plansubscription, path: pathname_mapping.Plansubscription, exact: true },
  { Component: Verificationpage, path: pathname_mapping.Verificationpage, exact: true },
]

const AuthenticatedRoutes = () => {
  return (
    AuthRoutes.map((item) => {
      const { Component, path, exact } = item
      return (
        <Route
          key={path}
          exact={exact}
          path={path}
          component={Component}
        />
      )
    })
  )
}

export default AuthenticatedRoutes
