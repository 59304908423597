export const BOOK_SESSION = 'BOOK_SESSION'
export const BOOK_SESSION_SUCCESS = 'BOOK_SESSION_SUCCESS'
export const BOOK_SESSION_FAILURE = 'BOOK_SESSION_FAILURE'

export const GET_SESSIONS = 'GET_SESSIONS'
export const GET_SESSIONS_SUCCESS = 'GET_SESSIONS_SUCCESS'
export const GET_SESSIONS_FAILURE = 'GET_SESSIONS_FAILURE'

export const GET_SESSION_DETAILS = 'GET_SESSION_DETAILS'
export const GET_SESSION_DETAILS_SUCCESS = 'GET_SESSION_DETAILS_SUCCESS'
export const GET_SESSION_DETAILS_FAILURE = 'GET_SESSION_DETAILS_FAILURE'

export const DELETE_SESSION = 'DELETE_SESSION'
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS'
export const DELETE_SESSION_FAILURE = 'DELETE_SESSION_FAILURE'

export const ARCHIVE_SESSION = 'ARCHIVE_SESSION'
export const ARCHIVE_SESSION_SUCCESS = 'ARCHIVE_SESSION_SUCCESS'
export const ARCHIVE_SESSION_FAILURE = 'ARCHIVE_SESSION_FAILURE'

export const START_SESSION = 'START_SESSION'
export const START_SESSION_SUCCESS = 'START_SESSION_SUCCESS'
export const START_SESSION_FAILURE = 'START_SESSION_FAILURE'
