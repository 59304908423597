import makeStyles from '@material-ui/styles/makeStyles'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { capitalize } from 'lodash'
import { useHistory } from 'react-router-dom'

import {
  SPACING, ASSETS, theme, DIMENSIONS, COLORS,
} from '../../Theme'
import CustomText from '../../UI/CustomText'
import {
  ADMIN_MENU, TRAINER_MENU, USER_MENU, BRANCH_ADMIN_MENU,
} from '../../utils/consts'

const { staminlogo, ContactUs } = ASSETS

const DashboardSideBar = ({ user_details = {}, branches = {} }) => {
  const styles = stylesheet()
  const history = useHistory()

  const [activeMenu, setActiveMenu] = React.useState(history.location.pathname)
  const [staminName, setStaminName] = React.useState({
    name: '',
    phone: '',
  })

  const { branch_id, type } = user_details
  const menuItems = type === 'admin'
    ? ADMIN_MENU : type === 'trainer'
      ? TRAINER_MENU : type === 'branch_admin'
        ? BRANCH_ADMIN_MENU : USER_MENU
  useEffect(() => {
    setActiveMenu(history.location.pathname)
  }, [500])
  useEffect(() => {
    if(branch_id !== '') {
      const foundBranch = branches.find(branche => branche.id === branch_id)
      if (foundBranch) {
        const { name = '', phone = '' } = foundBranch
        setStaminName({
          name,
          phone,
        })
      } else {
        console.error(`Branch with id ${branch_id} not found.`)
      }
    }
  }, [branch_id, branches])
  const onMenuItemPress = ({ page, link }) => {
    if (page) {
      if (link) {
        window.open(page, '_blank')
      } else {
        history.push(page)
        setActiveMenu(page)
      }
    }
  }

  const renderMenuItems = (item) => {
    const { title, page } = item
    const isActive = activeMenu === page

    return (
      <button
        onClick={() => onMenuItemPress(item)}
        type="button"
        key={title}
        style={{ width: '100%' }}
      >
        <div className={isActive ? styles.rowActive : styles.row}>
          <div className={styles.info}>
            <CustomText weight="medium" color={isActive ? 'white' : '#ffead9'} size="medium">{capitalize(title)}</CustomText>
          </div>
        </div>
      </button>
    )
  }

  return (
    <div className={styles.container}>
      <div>
        <img src={staminlogo} className={styles.logo} alt="stamin" />
        <CustomText color="White" size="High" center weight="semi_bold">{capitalize(staminName.name)}</CustomText>
      </div>

      <div className={styles.menuItems}>
        {menuItems.map(item => renderMenuItems(item))}
      </div>
      <div className={styles.contactUsCard}>
        <img src={ContactUs} alt="contact-us" className={styles.contactusIcon} />
        <CustomText size="small" color="white">Need Help? Contact Branch,</CustomText>
        <CustomText weight="semi_bold" size="medium" color="white">{staminName.phone}</CustomText>
      </div>
    </div>
  )
}

const stylesheet = makeStyles({
  container: {
    paddingTop: SPACING.SPACE_20,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.text,
    justifyContent: 'space-around',
  },
  logo: {
    // marginBottom: SPACING.SPACE_8,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '12%',
    width: '80%',
  },
  menuItems: {
    padding: `${SPACING.SPACE_20} 0`,
  },
  row: {
    display: 'flex',
    padding: `${SPACING.SPACE_14} ${DIMENSIONS.SPACE_HORIZONTAL}`,
    '&:hover': {
      background: theme.orangeLightBg,
    },
    '&:hover div p': {
      color: `${theme.orange} !important`,
    },
  },
  rowActive: {
    display: 'flex',
    padding: `${SPACING.SPACE_14} ${DIMENSIONS.SPACE_HORIZONTAL}`,
    backgroundColor: theme.orange,
  },
  contactUsCard: {
    position: 'relative',
    justifySelf: 'flex-end',
    backgroundColor: COLORS.WHITE_60,
    borderRadius: 10,
    padding: `${SPACING.SPACE_14} ${DIMENSIONS.SPACE_HORIZONTAL}`,
    margin: `0 ${SPACING.SPACE_20}`,
  },
  contactusIcon: {
    position: 'absolute',
    top: -25,
  },
})

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
    branches: state.branch.branchesData,
  }
}

export default connect(mapStateToProps, null)(DashboardSideBar)
