export const FETCH_ALL_PURCHASES = 'FETCH_ALL_PURCHASES'
export const FETCH_ALL_PURCHASES_SUCCESS = 'FETCH_ALL_PURCHASES_SUCCESS'
export const FETCH_ALL_PURCHASES_FAILURE = 'FETCH_ALL_PURCHASES_FAILURE'

export const CREATE_PLAN = 'CREATE_PLAN'
export const CREATE_PLAN_SUCCESS = 'CREATE_PLAN_SUCCESS'
export const CREATE_PLAN_FAILURE = 'CREATE_PLAN_FAILURE'

export const UPDATE_PLAN = 'UPDATE_PLAN'
export const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS'
export const UPDATE_PLAN_FAILURE = 'UPDATE_PLAN_FAILURE'

export const DELETE_PLAN = 'DELETE_PLAN'
export const DELETE_PLAN_SUCCESS = 'DELETE_PLAN_SUCCESS'
export const DELETE_PLAN_FAILURE = 'DELETE_PLAN_FAILURE'

export const CREATE_INVOICE = 'CREATE_INVOICE'
export const CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS'
export const CREATE_INVOICE_FAILURE = 'CREATE_INVOICE_FAILURE'

export const UPDATE_INVOICE = 'UPDATE_INVOICE'
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS'
export const UPDATE_INVOICE_FAILURE = 'UPDATE_INVOICE_FAILURE'

export const CREATE_PLANSUBSCRIPTION = 'CREATE_PLANSUBSCRIPTION'
export const CREATE_PLANSUBSCRIPTION_SUCCESS = 'CREATE_PLANSUBSCRIPTION_SUCCESS'
export const CREATE_PLANSUBSCRIPTION_FAILURE = 'CREATE_PLANSUBSCRIPTION_FAILURE'

export const FETCH_ALL_PLANSUBSCRIPTION = 'FETCH_ALL_PLANSUBSCRIPTION'
export const FETCH_ALL_PLANSUBSCRIPTION_FAILURE = 'FETCH_ALL_PLANSUBSCRIPTION_SUCCESS'
export const FETCH_ALL_PLANSUBSCRIPTION_SUCCESS = 'FETCH_ALL_PLANSUBSCRIPTION_FAILURE'
