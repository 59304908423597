import { connect } from 'react-redux'
import { getBranches } from '../Branches/actions'
import { getAllUsers } from '../Dashboard/actions'
import { getPlans } from './actions'

import Common from './Common'

const mapStateToProps = (state) => {
  return {
    ...state.common,
    user_details: state.auth.user_details,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPlans: () => dispatch(getPlans()),
    getBranches: () => dispatch(getBranches()),
    getAllUsers: params => dispatch(getAllUsers(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Common)
