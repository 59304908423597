import classNames from 'classnames'
import dayjs from 'dayjs'
import { isEmpty, isEqual, capitalize } from 'lodash'
import { v4 as uuid } from 'uuid'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getVideos } from '../../containers/Videos/actions'
import { createPlan, deletePlan, updatePlan } from '../../containers/Payments/actions'
import {
  COLORS, ICONS, SPACING, theme,
} from '../../Theme'
import { withTheme } from '../../Theme/ThemeProvider'
import Button from '../../UI/Button'
import CustomInput from '../../UI/CustomInput'
import CustomModal from '../../UI/CustomModal'
import CustomText from '../../UI/CustomText'
import ListDropdown from '../../UI/Dropdown/ListDropdown'
import DeletePromptModal from '../../UI/DeletePromptModal'
import Icon from '../../UI/Icon'
import PageLoader from '../../UI/PageLoader'
import ScreenHeader from '../../UI/ScreenHeader'
import { showSnackbar } from '../../UI/Snackbar'
import { setCookie } from '../../utils/common'
import { CURRENCY_SYMBOL, WORKOUTS } from '../../utils/consts'
import { pathname_mapping } from '../../utils/navigationConstant'
import AdminPlansSection from './AdminPlansSection'
import Planworkouts from './planworkouts'
// const { Logo } = ASSETS

const initialState = {
  planName: '',
  planAmount: 0,
  planGst: 0,
  planDescription: '',
  planWorkout: '',
  planWorkouts: {
    0: {
      WorkoutsName: '',
      WorkoutsSession: '',
    },
  },
  activePlanData: {},
}

class Plans extends Component {
  constructor(props) {
    super(props)
    this.escFunction = this.escFunction.bind(this)

    this.state = {
      selectedPlans: [],
      showCreatePlanModal: false,
      showDeleteModal: false,
      loading: false,
      creatworkout: false,
      planid: '',
      ...initialState,
    }

    this.activePlanId = null
    this.editMode = false
    this.showtogglemode = false
    this.toggleModalname = ''

    this.form = React.createRef()
  }

  escFunction(event) {
    if (event.key === 'Escape') {
      if (this.showtogglemode) {
        this.toggleModal(this.toggleModalname)
        this.toglemodelclose()
      }
    }
  }

  componentDidMount() {
    const { history, plans, getWorkoutVideos } = this.props
    const { state } = history.location
    getWorkoutVideos()
    document.addEventListener('keydown', this.escFunction, false)

    if (state && state.paymentDetails && state.paymentDetails.plan_id) {
      this.setState({ loading: true })
      const planToPay = plans[state.paymentDetails.plan_id] || {}
      if (!isEmpty(planToPay)) {
        const { amount } = planToPay
        this.makePayment(amount.toString())
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false)
  }

  componentDidUpdate(prevProps) {
    const {
      isCreatingPlan, createPlanErr, createPlanErrMsg,
      isDeletingPlan, deletePlanErr, deletePlanErrMsg, planDeleted,
      isUpdatingPlan, updatePlanErr, updatePlanErrMsg, planUpdated,
    } = this.props
    if (!isEqual(prevProps.isCreatingPlan, isCreatingPlan) && !isCreatingPlan) {
      if (createPlanErr) {
        showSnackbar(createPlanErrMsg)
      } else {
        this.setState({ ...initialState }, () => {
          this.toggleModal('showCreatePlanModal')
          this.setState({ creatworkout: true })
          this.toglemodelclose()
          showSnackbar('Plan created successfully', {}, 1)
        })
      }
    }

    if (!isEqual(prevProps.isDeletingPlan, isDeletingPlan) && !isDeletingPlan) {
      this.activePlanId = null
      this.toggleModal('showDeleteModal')
      if (deletePlanErr) {
        showSnackbar(deletePlanErrMsg, {}, 0)
      } else if (planDeleted) {
        showSnackbar('Plan deleted successfully', {}, 1)
      }
    }

    if (!isEqual(prevProps.isUpdatingPlan, isUpdatingPlan) && !isUpdatingPlan) {
      if (updatePlanErr) {
        showSnackbar(updatePlanErrMsg, {}, 0)
      } else if (planUpdated) {
        this.setState({ ...initialState }, () => {
          this.toggleModal('showCreatePlanModal')
          this.setState({ creatworkout: true })
          showSnackbar('Plan updated successfully', {}, 1)
        })
      }
    }
  }

  isDate = (val) => {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === '[object Date]'
  }

 isObj = (val) => {
   return typeof val === 'object'
 }

 stringifyValue = (val) => {
   if (this.isObj(val) && !this.isDate(val)) {
     return JSON.stringify(val)
   }
   return val
 }

  getTotalAmount = () => {
    const { plans } = this.props
    const { selectedPlans } = this.state
    const allPlans = Object.values(plans)

    let totalAmount = 0
    selectedPlans.forEach((item) => {
      const plan = allPlans.find(i => i.id === item)
      totalAmount += Number(plan.amount)
    })
    return totalAmount
  }

  buildForm = ({ action, params }) => {
    if (!action) {
      return null
    }
    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', action)

    if (params) {
      Object.keys(params).forEach((key) => {
        const input = document.createElement('input')
        input.setAttribute('type', 'hidden')
        input.setAttribute('name', key)
        input.setAttribute('value', this.stringifyValue(params[key]))
        form.appendChild(input)
      })
      return form
    }
    return null
  }

 post = (details) => {
   const form = this.buildForm(details)
   if (form) {
     document.body.appendChild(form)
     form.submit()
     form.remove()
   }
 }
 // https://us-central1-stamin-98728.cloudfunctions.net/app/api/payment
 // http://localhost:3044/api/payment
 // https://us-central1-stamin-98728.cloudfunctions.net/app/api/testpayment

  getData = (data) => {
    return fetch('https://us-central1-stamin-98728.cloudfunctions.net/app/api/payment', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Allow-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .catch(err => console.log(err))
  }

  makePayment = (amount) => {
    const { user_details } = this.props
    const { email, phone } = user_details
    if (!email || !phone) {
      showSnackbar('Invalid user', {}, 0)
      return
    }
    try {
      this.getData({ amount, email, phone })
        .then((response) => {
          const information = {
            action: 'https://securegw.paytm.in/order/process',
            params: response,
          }
          this.post(information)
        })
    } catch (error) {
      showSnackbar('Something went wrong', {}, 0)
    }
  }

  onTakeAction = (activePlanData, action) => {
    this.setState({ activePlanData }, () => {
      if (action === 'edit') {
        this.editPlan()
      } else if(action === 'edit_workouts') {
        this.setState({ creatworkout: true, planid: activePlanData.id })
      } else {
        this.activePlanId = activePlanData
        this.deletePlan(activePlanData)
      }
    })
  }

  toglemodelclose = () => {
    this.showtogglemode = false
    this.toggleModalname = ''
  }

  createPlan = () => {
    this.toggleModal('showCreatePlanModal')
    this.showtogglemode = true
    this.toggleModalname = 'showCreatePlanModal'
    // this.setState({
    //   planName: '', planAmount: 0, planGst: 0,
    // planDescription: '', planWorkout: '', WorkoutsName: '',
    // WorkoutsSession: '', planWorkouts: '',
    // })
  }

  deletePlan = () => {
    this.toggleModal('showDeleteModal')
  }

  editPlan = () => {
    const { activePlanData } = this.state
    const {
      name = '', amount = 0, description = '', workouts = [], gst = 0,
      id, duration,
    } = activePlanData
    // check if features is an array
    /* if (typeof features === 'object' && features.length) {
      planFeatures = features.join('\n')
    } else {
      planFeatures = features
    } */
    // planFeatures = planFeatures.filter(feature => feature)
    this.setState({
      planName: name,
      planAmount: amount,
      planGst: gst,
      planDescription: description,
      planWorkouts: workouts,
      planid: id,
      planValiditiy: duration,
    }, () => {
      this.toggleModal('showCreatePlanModal')
    })
  }

  onDelete = () => {
    const { removePlan } = this.props

    if (this.activePlanId) {
      removePlan({ id: this.activePlanId })
    }
  }

  toggleModal = (modal) => {
    this.setState(prevState => ({ [modal]: !prevState[modal] }))
  }

  onChangeText = (e, key) => {
    const value = e.target.value
    this.setState({ [key]: value })
  }

  toggleWorkout = (workout) => {
    const { planWorkouts } = this.state
    const workoutIndex = planWorkouts.indexOf(workout)
    if (workoutIndex > -1) {
      planWorkouts.splice(workoutIndex, 1)
    } else {
      planWorkouts.push(workout)
    }
    this.setState({ planWorkouts })
  }

  onPressSelect = (plan) => {
    const { id } = plan
    let maxSelectedPlans = 2
    const { user_details } = this.props
    const { plan_instance } = user_details
    let active_plan = 0
    plan_instance.map((plan1) => {
      const {
        plan_start_date, duration,
      } = plan1
      const timeline = (duration !== undefined) ? duration : 30
      // const planStartDate = dayjs(plan_start_date * 1000).valueOf()
      const planDueDate = dayjs(plan_start_date).add(timeline, 'days').valueOf()
      const datebool = new Date() < planDueDate
      if(datebool) {
        active_plan++
      }
      return null
    })
    const { selectedPlans } = this.state
    maxSelectedPlans -= active_plan
    // console.log(active_plan, selectedPlans.length, maxSelectedPlans)
    const pId = btoa(selectedPlans.join(':'))
    const planIndex = selectedPlans.indexOf(id)
    if (selectedPlans.length < maxSelectedPlans || planIndex > -1) {
      if (planIndex > -1) {
        selectedPlans.splice(planIndex, 1)
      } else {
        selectedPlans.push(id)
      }
      setCookie({ pId })
      this.setState({ selectedPlans })
    } else {
      showSnackbar(' sorry you have maximum two plans only selected ')
    }
  }

  onPressContinue = () => {
    const { selectedPlans } = this.state
    if (!selectedPlans.length) {
      showSnackbar('Please select a plan', {}, 0)
      return
    }

    const pId = btoa(selectedPlans.join(':'))
    setCookie({ pId })
    // const totalAmount = this.getTotalAmount()
    this.makePayment('1') // String(totalAmount)
  }

  onCreatePlan = () => {
    const {
      planName, planAmount, planWorkouts, planDescription, planGst, planValiditiy,
    } = this.state
    const { createNewPlan, user_details } = this.props
    let packageAmount = ''
    if(planAmount > 0 & planGst > 0) {
      packageAmount = parseInt(planAmount, 10) + (planAmount * (planGst / 100))
    }
    const branch_id = user_details.branch_id
    const id = uuid()
    this.setState({ planid: id })
    createNewPlan({
      id,
      name: planName,
      description: planDescription,
      amount: planAmount,
      gst: planGst,
      duration: planValiditiy,
      packageAmount,
      workouts: planWorkouts,
      branch_id,
    })
  }

  updatePlan = () => {
    const {
      planName, planAmount, planWorkouts, planDescription, planGst,
      activePlanData, planValiditiy,
    } = this.state
    const { editPlan } = this.props
    let packageAmount = planAmount
    if(planAmount > 0 & planGst > 0) {
      packageAmount = parseInt(planAmount, 10) + (planAmount * (planGst / 100))
    }
    editPlan({
      id: activePlanData?.id,
      name: planName,
      description: planDescription,
      amount: planAmount,
      gst: planGst,
      duration: planValiditiy,
      packageAmount,
      workouts: planWorkouts,
    })
  }

  renderWorkouts = () => {
    const { styles } = this.props
    const { planWorkout } = this.state
    return (
      <div className={styles.workoutsContainer}>
        <CustomText size="small" color="textSecondary" weight="medium">
          Select workouts
        </CustomText>
        <div className={styles.workoutsList}>
          {WORKOUTS.map((workout) => {
            const { value, label } = workout
            const isSelected = planWorkout.includes(workout.value)

            return (
              <button
                key={value}
                className={classNames(styles.workoutChip, isSelected && styles.activeChip)}
                type="button"
                onClick={() => this.toggleWorkout(value)}
              >
                <CustomText size="small" weight="medium" color={isSelected ? COLORS.WHITE : COLORS.BLACK}>
                  {label}
                </CustomText>
              </button>
            )
          })}
        </div>
      </div>
    )
  }

  addRoutine = () => {
    const { planWorkouts } = this.state

    const newplanWorkouts = { ...planWorkouts }
    const index = Object.keys(newplanWorkouts).length
    newplanWorkouts[index] = {
      WorkoutsName: '',
      WorkoutsSession: '',
    }
    this.setState({ planWorkouts: newplanWorkouts })
  }

  onChangeRoutineDetails = (e, { key, index }) => {
    const value = e.target.value
    const { planWorkouts } = this.state
    const newplanWorkouts = { ...planWorkouts }
    newplanWorkouts[index][key] = value
    this.setState({ planWorkouts: newplanWorkouts })
  }

  onSelect = (value, obj, { key, index }) => {
    const { planWorkouts } = this.state
    const newplanWorkouts = { ...planWorkouts }
    newplanWorkouts[index][key] = value
    this.setState({ planWorkouts: newplanWorkouts })
  }

  renderModal = () => {
    const {
      styles, isCreatingPlan, isUpdatingPlan, branches, user_details,
    } = this.props
    const {
      showCreatePlanModal,
      planName,
      planAmount,
      planGst,
      planDescription,
      activePlanData,
      planValiditiy,
      planWorkouts,
    } = this.state
    const brancheswork = Object.values(branches)
      .filter(user => user.id === user_details.branch_id)
    const Branch_workouts = brancheswork[0].workouts
    const btnText = !isEmpty(activePlanData) ? 'Update' : 'Create'
    const onPressBtn = !isEmpty(activePlanData) ? this.updatePlan : this.onCreatePlan
    const isLoading = isCreatingPlan || isUpdatingPlan
    return (
      <CustomModal
        visible={showCreatePlanModal}
        onDismiss={this.toggleModal}
        dismissKey="showCreatePlanModal"
      >
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <CustomText size="medium" weight="semi_bold">Create Plan</CustomText>
          </div>
          <div className={styles.modalBody}>
            <CustomInput
              label="Enter Plan Name"
              txt2="*"
              value={planName}
              inputKey="planName"
              onChangeText={this.onChangeText}
              className={styles.input}
            />
            <CustomInput
              label="Enter Plan Description"
              txt2="*"
              value={planDescription}
              inputKey="planDescription"
              onChangeText={this.onChangeText}
              className={styles.input}
              multiline
            />
            {Object.values(planWorkouts).map((item, index) => (
              <div className={styles.dietPlanItem} key={item.name}>
                <CustomText size="small" weight="semi_bold">{`Workout ${index + 1}`}</CustomText>
                <ListDropdown
                  selected={planWorkouts[index].WorkoutsName}
                  onChangeOption={this.onSelect}
                  options={Branch_workouts}
                  labelKey="label"
                  valueKey="value"
                  changeKey={{ key: 'WorkoutsName', index }}
                  placeholder="Select Workouts"
                  className={styles.input}
                />
                <CustomInput
                  label="No. of Sesion"
                  placeholder="No. of Sesion"
                  value={planWorkouts[index].WorkoutsSession}
                  inputKey={{ key: 'WorkoutsSession', index }}
                  onChangeText={this.onChangeRoutineDetails}
                  inputStyles={styles.input}
                />

              </div>
            ))}
            <Button
              labelColor="orange"
              text="Add Workouts"
              mode="text"
              transparent
              btnStyles={styles.addRoutineBtn}
              labelStyles={styles.addRoutineLabel}
              onPress={this.addRoutine}
            />
            <CustomInput
              label="Enter Plan Amount"
              txt2="*"
              value={planAmount}
              inputKey="planAmount"
              onChangeText={this.onChangeText}
              className={styles.input}
            />
            <CustomInput
              label="GST %"
              txt2="*"
              value={planGst}
              inputKey="planGst"
              onChangeText={this.onChangeText}
              className={styles.input}
            />
            <CustomInput
              label="Validity Period(in days)"
              txt2="*"
              value={planValiditiy}
              inputKey="planValiditiy"
              onChangeText={this.onChangeText}
            />
          </div>
          <div className={styles.modalFooter}>
            <Button
              text={btnText}
              buttonColor="orange"
              uppercase
              labelStyle={styles.createBtn}
              onPress={onPressBtn}
              disabled={isLoading}
              loading={isLoading}
            />
          </div>
        </div>
      </CustomModal>
    )
  }

  renderPlanCard = (plan, index) => {
    const {
      styles, // user_details
    } = this.props
    const { selectedPlans } = this.state
    const {
      name, packageAmount, description, color, workouts, // bgColor, // duration,
    } = plan

    const selected = selectedPlans.includes(plan.id)

    const btnText = selected ? 'Remove' : 'Select This'
    const randomColor = ['#f0e68c', '#20b2aa', '#ffdab9', '#f4a460', '#d8bfd8', '#f4a460', '#ffdead', '#dda0dd']
    const randomIndex = Math.floor(Math.random() * randomColor.length)
    const selectedColor = randomColor[randomIndex]

    /* let remaingsession = 0
    if (user_details?.remaingsession) {
      const { remaingsession: remaingsesion = '' } = user_details
      remaingsession = remaingsesion
    }
    remaingsession = parseInt(remaingsession, 10) + parseInt(duration, 10) */
    // const randomIndex = (amount / 30) % PLANS_COLOR_MAP.length
    // const { bgColor, color } = PLANS_COLOR_MAP[randomIndex] || PLANS_COLOR_MAP[0]
    return (
      <div key={index} className={styles.planCard} style={{ backgroundColor: 'white' }}>
        <div style={{ width: '100%' }}>
          <div className={styles.planCardHeader} style={{ backgroundColor: selectedColor }}>
            <CustomText size="large" color="black" weight="semi_bold">{capitalize(name)}</CustomText>
            <CustomText size="large" color="black" weight="semi_bold">{`${CURRENCY_SYMBOL} ${packageAmount}`}</CustomText>
          </div>
          <CustomText size="small" className={styles.textdescription} color="black">{description}</CustomText>
        </div>

        <div className={styles.planCardFeatures}>
          {/* {features && features.map((feature) => {
            if(!feature) return null
            return (
              <div className={styles.planFeature} key={feature}>
                <Icon name={ICONS.TICK_ROUND_FILLED} size={16} color={color} />
                <CustomText size="small">
                  {feature}
                </CustomText>
              </div>
            )
          })} */}
          { Object.keys(workouts).map((workou) => {
            if(!workou) return null
            return (
              <div className={styles.featureRow}>
                <Icon name={ICONS.TICK_ROUND_FILLED} size={16} color={color} />
                <CustomText size="small" color="black">
                  {`${capitalize(workouts[workou].WorkoutsName)} - ${workouts[workou].WorkoutsSession} Classes`}
                </CustomText>
              </div>
            )
          })}
        </div>
        <Button
          text={btnText}
          uppercase
          mode={selected ? 'contained' : 'outlined'}
          buttonColor={color}
          btnStyles={styles.selectBtn}
          params={plan}
          onPress={this.onPressSelect}
        />
      </div>
    )
  }

  renderPremiumPlanCard = (plan, index) => {
    const {
      styles, // user_details
    } = this.props
    const { selectedPlans } = this.state
    const {
      name, packageAmount, description, color, workouts, // bgColor // duration,
    } = plan

    const selected = selectedPlans.includes(plan.id)

    const btnText = selected ? 'Remove' : 'Select This'
    return (
      <div key={index} className={styles.planpremiumcard} style={{ backgroundColor: 'white' }}>
        <div className="ribbon-wrapper-green">
          <div className="ribbon-green">Stamin</div>
        </div>
        <div style={{ width: '100%' }}>
          <div className={styles.planpremiumheader}>
            <CustomText size="large" color="black" weight="semi_bold">
              {capitalize(name)}
              &nbsp;&nbsp;
              {/* <Icon name={ICONS.FAVOURITE} size={20} color="red" /> */}
            </CustomText>
            <br />
            <CustomText size="large" style={{ fontSize: '2rem' }} color="black" weight="semi_bold">{`${CURRENCY_SYMBOL} ${packageAmount}`}</CustomText>
          </div>
          <CustomText size="medium" color="black">{description}</CustomText>
        </div>

        <div className={styles.planpremiumCardFeatures}>
          { Object.keys(workouts).map((workou) => {
            if(!workou) return null
            return (
              <div className={styles.featureRow}>
                <CustomText size="small" color="black">
                  <Icon name={ICONS.TICK_ROUND_FILLED} size={16} color={color} />
                  {`${capitalize(workouts[workou].WorkoutsName)} - ${workouts[workou].WorkoutsSession}`}
                </CustomText>
              </div>
            )
          })}
        </div>
        <Button
          text={btnText}
          uppercase
          mode={selected ? 'contained' : 'outlined'}
          buttonColor={color}
          btnStyles={styles.selectBtn}
          params={plan}
          onPress={this.onPressSelect}
        />
      </div>
    )
  }

  render() {
    const {
      styles, plans, history, user_details,
      videos,
    } = this.props
    const { location = {} } = history
    const { pathname = '' } = location
    const {
      showDeleteModal, loading, selectedPlans,
      creatworkout, planid,
    } = this.state
    const plan = Object.values(plans).filter(user => user.branch_id === user_details.branch_id)
    const premiumplan = Object.values(plans).filter(user => user.name === 'Premium Plan')
    let Premiumplan = []
    if(premiumplan !== undefined) {
      Premiumplan = premiumplan
    }
    console.log(Premiumplan)
    const allPlans = Object.values(plan)
    const isPlanDashboard = pathname === pathname_mapping.PlansDashboard
    const isScheduleFetched = Object.values(plan).filter(pla => planid.includes(pla.id)).length > 0
    console.log(creatworkout, isScheduleFetched)
    if (loading) {
      return <PageLoader fullScreen />
    }

    if (isPlanDashboard) {
      return (
        <div className={styles.dashboardContainer}>
          <ScreenHeader
            title="Plans Dashboard"
          />
          <br />
          {(!creatworkout) ? (
            <div className={styles.detailsContainer}>
              <AdminPlansSection
                plans={plan}
                onTakeAction={this.onTakeAction}
                createPlan={this.createPlan}
                deletePlan={this.deletePlan}
              />
            </div>
          ) : (
            <>
              {isScheduleFetched ? (
                <Planworkouts
                  planid={planid}
                  plans={plan}
                  videos={videos}
                />
              ) : (<></>)}
            </>
          )}
          {this.renderModal()}
          <DeletePromptModal
            visible={showDeleteModal}
            onDismiss={this.toggleModal}
            dismissKey="showDeleteModal"
            onDelete={this.onDelete}
            typeText="Plan"
          />
        </div>

      )
    }
    return (
      <div className={styles.container}>
        {/* <img src={Logo} alt="Stamin" className={styles.logo} /> */}
        <CustomText size="large" color="white" weight="semi_bold">
          Choose your plan
        </CustomText>
        <div className={styles.plansContainer}>
          {Premiumplan.map(this.renderPremiumPlanCard)}
          {allPlans.map(this.renderPlanCard)}
        </div>
        <div className={styles.plansFooterDisc}>
          <CustomText size="medimum" color="white">
            *You can select multiple plans
          </CustomText>
        </div>
        {selectedPlans.length ? (
          <div className={styles.plansFooter}>
            <CustomText color="white" weight="medium">
              {`Total Amount: ${CURRENCY_SYMBOL} ${this.getTotalAmount()}`}
            </CustomText>
            <Button
              text="Continue"
              uppercase
              buttonColor="orange"
              onPress={this.onPressContinue}
            />
          </div>
        ) : null}
      </div>
    )
  }
}

const stylesheet = {
  container: {
    backgroundColor: '#494949',
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${SPACING.SPACE_40} ${SPACING.SPACE_20}`,
  },
  textdescription: {
    padding: '5px',
  },
  featureRow: {
    display: 'flex',
  },
  plansContainer: {
    display: 'flex',
    width: '100%',
    gap: SPACING.SPACE_40,
    padding: `${SPACING.SPACE_40} 0`,
    flexWrap: 'wrap',
  },
  planCard: {
    // padding: `${SPACING.SPACE_20} ${SPACING.SPACE_20}`,
    paddingBottom: '1.5rem',
    borderRadius: 10,
    minWidth: 250,
    minHeight: 400,
    maxWidth: 250,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  planpremiumcard: {
    // padding: `${SPACING.SPACE_20} ${SPACING.SPACE_20}`,
    borderRadius: 10,
    minWidth: 250,
    minHeight: 400,
    maxWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    alignItems: 'flex-start',
    '-webkit-box-shadow': '0px 0px 8px rgba(0,0,0,0.3)',
    '-moz-box-shadow': '0px 0px 8px rgba(0,0,0,0.3)',
    boxShadow: '0px 0px 8px rgba(0,0,0,0.3)',
    position: 'relative',
    zindex: 90,
  },
  planCardHeader: {
    width: '100%',
    display: 'flex',
    borderRadius: 5,
    flexDirection: 'column',
    gap: '1rem',
    padding: '1.5rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: SPACING.SPACE_20,
  },
  planpremiumheader: {
    padding: '1rem',
    borderRadius: 5,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FC7300',
    // marginBottom: SPACING.SPACE_20,
  },
  planCardFeatures: {
    display: 'flex',
    padding: '1rem',
    gap: SPACING.SPACE_16,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    // marginTop: SPACING.SPACE_40,
  },
  planpremiumCardFeatures: {
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: SPACING.SPACE_16,
    marginTop: SPACING.SPACE_40,
  },
  planFeature: {
    display: 'flex',
    alignItems: 'center',
    gap: SPACING.SPACE_10,
  },
  selectBtn: {
    marginTop: SPACING.SPACE_40,
    alignSelf: 'center',
  },

  dashboardContainer: {
    padding: SPACING.SPACE_20,
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING.SPACE_20,
    alignItems: 'center',
    flex: '1 1 40%',
    marginTop: SPACING.SPACE_20,
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING.SPACE_20,
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING.SPACE_20,
  },
  workoutsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING.SPACE_10,
  },
  workoutsList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: SPACING.SPACE_10,
    alignItems: 'center',
  },
  workoutChip: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.borderColor}`,
    borderRadius: 10,
    padding: SPACING.SPACE_10,
  },
  activeChip: {
    backgroundColor: theme.orange,
  },
  plansFooterDisc: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    padding: `0 ${SPACING.SPACE_40}`,
  },
  plansFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    alignItems: 'center',
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    gap: SPACING.SPACE_20,
    margin: `${SPACING.SPACE_10} 0`,
  },
}

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
    ...state.payments,
    branches: state.branch.branchesData,
    videos: state.videos.videos,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createNewPlan: plans => dispatch(createPlan(plans)),
    removePlan: planId => dispatch(deletePlan(planId)),
    editPlan: plan => dispatch(updatePlan(plan)),
    getWorkoutVideos: () => dispatch(getVideos()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(stylesheet)(Plans))
