import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import Datetime from 'react-datetime'
import { capitalize, isEmpty, lowerCase } from 'lodash'
import dayjs from 'dayjs'
import { v4 as uuid } from 'uuid'
import classNames from 'classnames'
import {
  COLORS,
  DIMENSIONS,
  ICONS,
  SPACING, theme,
} from '../../Theme'
import { bookSession, deleteSession } from '../../containers/Sessions/actions'
import { getAllUsers } from '../../containers/Dashboard/actions'
import { getWorkouts, updateWorkouts } from '../../containers/AssigneWorkouts/actions'
import { withTheme } from '../../Theme/ThemeProvider'
import CustomText from '../../UI/CustomText'
import CustomModal from '../../UI/CustomModal'
import Icon from '../../UI/Icon'
import DateTimePicker from '../../UI/DateTimePicker'
import Button from '../../UI/Button'
import { showSnackbar } from '../../UI/Snackbar'
import DeletePromptModal from '../../UI/DeletePromptModal'
import { pathname_mapping } from '../../utils/navigationConstant'

// import Icon from '../../UI/Icon'

function AssignedWorkouts({
  allworkots = [], getAssingWorkouts, user_details, styles, CreatbookSession, deleteSessionM,
  isCreatingSession, updateSchedule, createSessionErr, createSessionErrMsg, createSessionResp,
  isDeletingSession, deleteSessionErr, deleteSessionErrMsg,
}) {
  let BookSchedule = []
  let BookSchedule1 = []
  // let showBookSessionModal = false
  const [BookScheduled, setBookScheduled] = React.useState([])
  const [showBookSessionModal, toogleBookSessionModal] = React.useState(false)
  const [deletePromptModalv, toogleDelete] = React.useState(false)
  const [planid, setplanid] = React.useState('0')
  const [edit, setEdit] = React.useState(false)
  // let planc = 0
  const [BookScheduled1, setBookScheduled1] = React.useState([])
  const initialState = {
    selectedDate: moment(),
    selectedTime: moment(),
    objs: [],
    indx: 0,
    plan: 1,
    sid: '',
    sid2: '',
    available1: 0,
    booked1: 0,
    redeemed1: 0,
    Missed1: 0,
    available2: 0,
    booked2: 0,
    redeemed2: 0,
    Missed2: 0,
  }
  const [state, setState] = React.useState(initialState)
  let planInstance1 = []
  let planInstance2 = []
  let dataAssign = false
  // Fetching Data from Db
  useEffect(() => {
    getAssingWorkouts()
    setState(initialState)
  }, [])
  const uid = user_details?.uid
  const userWorkouts = Object.values(allworkots)
    .filter(p => uid.includes(p.uid))
  // getting Active plan and Secheule
  let plancount = 0
  Object.values(userWorkouts).map((obj) => {
    const { plan1 } = obj
    if(plan1 !== {} && plancount === 0) {
      let { plan_end_date } = plan1
      plan_end_date = dayjs(plan_end_date * 1000).valueOf()
      const activeDate = new Date().valueOf() < plan_end_date
      if(activeDate) {
        const { Schedule, sid } = obj
        BookSchedule = Schedule
        planInstance1 = plan1
        state.sid = sid
      }
      plancount++
    }else if(plan1 !== {} && plancount === 1) {
      let { plan_end_date } = plan1
      plan_end_date = dayjs(plan_end_date * 1000).valueOf()
      const activeDate = new Date().valueOf() < plan_end_date
      if(activeDate) {
        const { Schedule, sid } = obj
        BookSchedule1 = Schedule
        planInstance2 = plan1
        state.sid2 = sid
      }
    }
    return null
  })
  // console.log(planInstance1, planInstance2)
  if(Object.values(planInstance1).length === 0) {
    const { plan_instance } = user_details
    Object.values(plan_instance).map((objs) => {
      const{
        plan_start_date, duration, name, workouts,
      } = objs
      let days = duration
      if(duration === undefined) {
        days = 30
      }
      const plan_end_date = dayjs(plan_start_date * 1000).add(days, 'day').valueOf()
      const activeDate = new Date().valueOf() < plan_end_date
      if(activeDate) {
        for(let i = 0; i < days; i++) {
          BookSchedule[i] = {
            BookingStatus: 'Not Booked',
            Workout: '',
            scheduled_for: '',
          }
        }
        planInstance1 = {
          plan_start_date,
          plan_end_date,
          duration: days,
          name,
          workouts,
        }
      }
      return null
    })
  }
  if(Object.values(planInstance2).length === 0) {
    const { plan_instance } = user_details
    Object.values(plan_instance).map((objs) => {
      const{
        plan_start_date, duration, name, workouts,
      } = objs
      let days = duration
      if(duration === undefined) {
        days = 30
      }
      const plan_end_date = dayjs(plan_start_date * 1000).add(days, 'day').valueOf()
      const activeDate = new Date().valueOf() < plan_end_date
      if(activeDate && name !== planInstance1.name) {
        for(let i = 0; i < days; i++) {
          BookSchedule1[i] = {
            BookingStatus: 'Not Booked',
            Workout: '',
            scheduled_for: '',
          }
        }
        planInstance2 = {
          plan_start_date,
          plan_end_date,
          duration: days,
          name,
          workouts,
        }
      }
      return null
    })
  }
  const available = 'not booked'
  const completed = 'completed'
  const missed = 'missed'
  state.available1 = Object.values(BookScheduled)
    .filter(avi => lowerCase(avi.BookingStatus).includes(available)).length
  state.redeemed1 = Object.values(BookScheduled)
    .filter(avi => completed.includes(lowerCase(avi.BookingStatus))).length
  state.Missed1 = Object.values(BookScheduled)
    .filter(avi => missed.includes(lowerCase(avi.BookingStatus))).length
  state.booked1 = Object.values(BookScheduled)
    .length - state.available1 - state.redeemed1 - state.Missed1
  state.available2 = Object.values(BookScheduled1)
    .filter(avi => lowerCase(avi.BookingStatus).includes(available)).length
  state.redeemed2 = Object.values(BookScheduled1)
    .filter(avi => completed.includes(lowerCase(avi.BookingStatus))).length
  state.Missed2 = Object.values(BookScheduled1)
    .filter(avi => missed.includes(lowerCase(avi.BookingStatus))).length
  state.booked2 = Object.values(BookScheduled1)
    .length - state.available2 - state.redeemed2 - state.Missed2
  // toggle modal for Book Session
  const toggleModal = () => {
    toogleBookSessionModal(!showBookSessionModal)
  }
  // Toggle mode for delete
  const toggleModald = () => {
    toogleDelete(!deletePromptModalv)
  }
  // setting Input for date and time in Book session Model
  const yesterday = moment().subtract(1, 'day')
  const disablePastDt = (current) => {
    return current.isAfter(yesterday)
  }
  const renderDateInput = (dateProps) => {
    return (
      <div
        className={classNames(styles.fieldInputContainer, styles.dateInputContainer)}
      >
        <input {...dateProps} className={classNames(styles.fieldInput, styles.dateInput)} />
        <Icon
          name={ICONS.CALENDAR}
          color={theme.textSecondary}
          size={12}
          className={styles.inputIcon}
        />
      </div>
    )
  }
  const onSelect = (value, obj, key) => {
    setState(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }
  const renderTimeInput = (timeProps) => {
    return (
      <div className={classNames(styles.fieldInputContainer, styles.timeInputContainer)}>
        <input {...timeProps} className={classNames(styles.fieldInput, styles.timeInput)} />
        <Icon
          name={ICONS.CLOCK_FILLED}
          color={theme.textSecondary}
          size={12}
          className={styles.inputIcon}
        />
      </div>
    )
  }
  // getting responce from db for Booking/Edit
  useEffect(() => {
    if (!isCreatingSession) {
      if (createSessionErr) {
        showSnackbar(createSessionErrMsg, {}, 0)
      } else if (isEmpty(createSessionResp)) {
        toogleBookSessionModal(false)
        showSnackbar('Session Created successfully', {}, 1)
      }
    }
  }, [!isCreatingSession])
  // getting responce from db for delete
  useEffect(() => {
    if (!isDeletingSession) {
      if (deleteSessionErr) {
        showSnackbar(deleteSessionErrMsg, {}, 0)
      } else {
        toogleDelete(false)
        showSnackbar('Session Deleted successfully', {}, 1)
      }
    }
  }, [!isDeletingSession])
  // Book/Edit Session code to Creat/update in DB
  const bookNewSession = () => {
    let updateBookSchedule = ''
    const session_id = edit ? state.objs.session_id : uuid()
    if(planid === 1) {
      updateBookSchedule = BookScheduled
    } else {
      updateBookSchedule = BookScheduled1
    }
    updateBookSchedule[state.indx].BookingStatus = 'Booked'
    updateBookSchedule[state.indx].session_id = session_id
    const { branch_id, trainer: trainerDe } = user_details
    const { name: tname, uid: trainerId } = trainerDe
    const scheduled_for = moment(state.selectedDate).set({
      hour: moment(state.selectedTime).get('hour'),
      minute: moment(state.selectedTime).get('minute'),
      second: 0,
    }).unix() * 1000
    updateBookSchedule[state.indx].scheduled_for = scheduled_for
    const workout = state.objs.Workout
    const plan = planid === 1 ? planInstance1?.name : planInstance2.name
    const creatsessionkey = session_id
    const updateKey = edit ? state.objs.session_id : false
    // showBookSessionModal = true
    let parmse = {}
    let parms = {}
    if(planid === 1) {
      parms = {
        branch_id,
        scheduled_for,
        workout,
        uid,
        video: state.objs.WorkoutVideo,
        Comments: state.objs.Comments,
        trainer: trainerId,
        name: user_details.name,
        trainer_name: tname,
        plan,
        sid: state.sid,
        index: state.indx,
        planid,
      }
      parmse = {
        Schedule: updateBookSchedule,
        sid: state.sid,
      }
    } else {
      parms = {
        branch_id,
        scheduled_for,
        workout,
        uid,
        video: state.objs.WorkoutVideo,
        Comments: state.objs.Comments,
        trainer: trainerId,
        name: user_details.name,
        trainer_name: tname,
        plan,
        sid: state.sid2,
        index: state.indx,
        planid,
      }
      parmse = {
        Schedule: updateBookSchedule,
        sid: state.sid2,
      }
    }
    CreatbookSession(parms, user_details?.type, creatsessionkey, updateKey)
    updateSchedule(parmse)
  }
  // Book/Edit Session front end Model
  const booksessionmodel = () => {
    return(
      <CustomModal
        visible={showBookSessionModal}
        onDismiss={toggleModal}
        dismissKey="showBookSessionModal"
        containerStyles={styles.modalContainer}
      >
        <div className={styles.modalContentContainer}>
          <CustomText size="large" weight="semi_bold">
            Book a Session
          </CustomText>
          <div className={styles.modalContent}>
            <CustomText size="large" weight="semi_bold">
              Day
              {state.indx + 1}
            </CustomText>

            <CustomText size="large" weight="semi_bold">
              Workouts:
              {state.objs.Workout}
            </CustomText>
            <CustomText size="small" weight="medium" className={styles.dateInputLabel}>Select Date</CustomText>
            <div className={styles.sessionTime}>
              <DateTimePicker
                props={{
                  timeFormat: false,
                  closeOnSelect: true,
                  dateFormat: 'DD MMM YYYY',
                  isValidDate: disablePastDt,
                  value: state.selectedDate,
                  renderInput: dateProps => renderDateInput(dateProps),
                  onChange: date => onSelect(date, null, 'selectedDate'),
                }}
              />
            </div>
            <div className={styles.sessionTime}>
              <CustomText size="small" weight="medium">Start Time</CustomText>
              <Datetime
                dateFormat={false}
                timeFormat="HH:mm"
                value={state.selectedTime}
                renderInput={renderTimeInput}
                onChange={date => onSelect(date, null, 'selectedTime')}
              />
            </div>
            <Button
              buttonColor="orange"
              text="Confirm"
              btnStyles={styles.bookSessionBtn}
              params="showBookSessionModal"
              loading={isCreatingSession}
              disabled={isCreatingSession}
              onPress={bookNewSession}
            />
          </div>
        </div>
      </CustomModal>
    )
  }
  // Delete Session Codeto update in firebase
  const onDeleteSession = () => {
    let updateBookSchedule = ''
    const session_id = state.objs.session_id
    if(planid === 1) {
      updateBookSchedule = BookScheduled
    } else {
      updateBookSchedule = BookScheduled
    }
    updateBookSchedule[state.indx].BookingStatus = 'Not Booked'
    updateBookSchedule[state.indx].session_id = ''
    updateBookSchedule[state.indx].scheduled_for = ''
    const parm = {
      session_id,
    }
    deleteSessionM(parm)
    let parmse = {}
    if(planid === 1) {
      parmse = {
        Schedule: updateBookSchedule,
        sid: state.sid,
      }
    } else {
      parmse = {
        Schedule: updateBookSchedule,
        sid: state.sid2,
      }
    }
    updateSchedule(parmse)
  }
  // function while Delete button is Clicked
  const DeleteSession = async (objs, i) => {
    state.objs = objs
    state.indx = i
    await toggleModald()
  }
  // Putting Value in table
  const getdate = (obj) => {
    const scheduledfor = obj.scheduled_for
    let dateString = '--'
    if(scheduledfor !== '') {
      dateString = new Date(Number(scheduledfor)).toDateString()
    }
    return(
      <div>
        {dateString}
      </div>
    )
  }
  const gettime = (obj) => {
    const scheduledfor = obj.scheduled_for
    let dateString = '--'
    if(scheduledfor !== '') {
      dateString = new Date(Number(scheduledfor)).toTimeString().slice(0, 5)
    }
    return(
      <div>
        {dateString}
      </div>
    )
  }
  const getBookingStatus = (obj) => {
    // const BookingStatus = obj.BookingStatus
    const status = obj.BookingStatus === 'Booked'
    return(
      <div>
        {status ? (
          <div style={{ color: 'white', fontSize: '18px', fontWeight: '600' }}>
            {obj.BookingStatus}
          </div>
        ) : (
          <div style={{ color: 'white' }}>
            {obj.BookingStatus}
          </div>
        )}
      </div>
    )
  }
  const history = useHistory()
  const goToSessionDetails = (session) => {
    history.push({
      pathname: `${pathname_mapping.Sessions}/${session}`,
      state: { session },
    })
  }
  // for table 1
  const getOptions = (obj, i) => {
    // const BookingStatus = obj.BookingStatus
    const status = obj.BookingStatus === 'Booked' || 'Completed'
    const scheduledfor = obj.scheduled_for
    const session_id = obj.session_id
    const ispast = new Date() > scheduledfor
    console.log(ispast)
    return(
      <div>
        {!status ? (
          <div style={{ color: 'green' }}>
            <button id="plan1" onClick={() => { setplanid(1); setEdit(false); onBook(obj, i) }} type="button" style={{ color: 'green', fontSize: '18px', fontWeight: '600' }}>
              Book
            </button>
          </div>
        ) : (ispast ? (
          <div className={styles.Icon}>
            <Button iconName="VIEW" iconProps={{ color: COLORS.WHITE, size: 20 }} onPress={() => goToSessionDetails(session_id)} />
          </div>
        ) : (
          <div style={{ color: 'green', display: 'flex', flexDirection: 'row' }}>
            <div>
              <button onClick={() => { setplanid(1); setEdit(true); onBook(obj, i) }} type="button" style={{ color: 'orange', fontSize: '18px', fontWeight: '600' }}>
                <Icon
                  name={ICONS.EDIT}
                  color={COLORS.GREEN}
                  size={15}
                  // className={styles.inputIcon1}
                />
              </button>
            </div>
            <div>
              <button onClick={() => { setplanid(1); DeleteSession(obj, i) }} type="button" style={{ color: 'red', fontSize: '18px', fontWeight: '600' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Icon
                  name={ICONS.DELETE}
                  color={COLORS.RED}
                  size={15}
                  // className={styles.inputIcon1}
                />
              </button>
            </div>
          </div>
        ))}
      </div>
    )
  }
  // for table 2
  const getOptions2 = (obj, i) => {
    // const BookingStatus = obj.BookingStatus
    const status = obj.BookingStatus === 'Booked'
    return(
      <div>
        {!status ? (
          <div style={{ color: 'green' }}>
            <button id="plan2" onClick={() => { setplanid(2); onBook(obj, i) }} type="button" style={{ color: 'green', fontSize: '18px', fontWeight: '600' }}>
              Book
            </button>
          </div>
        ) : (
          <div style={{ color: 'gray' }}>
            <button onClick={() => { setplanid(2); setEdit(true); onBook(obj, i) }} type="button" style={{ color: 'orange', fontSize: '18px', fontWeight: '600' }}>
              <Icon
                name={ICONS.EDIT}
                color={COLORS.GREEN}
                size={15}
                // className={styles.inputIcon1}
              />
            </button>
            <button onClick={() => { setplanid(2); DeleteSession(obj, i) }} type="button" style={{ color: 'red', fontSize: '18px', fontWeight: '600' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;
              <Icon
                name={ICONS.DELETE}
                color={COLORS.RED}
                size={15}
                // className={styles.inputIcon1}
              />
            </button>
          </div>
        )}
      </div>
    )
  }
  // function while Book r Edit button is Clicked
  const onBook = async (objs, iX) => {
    state.objs = objs
    state.indx = iX
    // state.plan = plan
    state.selectedDate = edit ? new Date(Number(objs.scheduled_for)).toDateString() : moment()
    state.selectedTime = edit
      ? new Date(Number(objs.scheduled_for)).toTimeString().slice(0, 5) : moment()
    if(objs.Workout !== '') {
      await toggleModal()
    } else {
      showSnackbar('Ask You trainer to asssing Workouts')
    }
  }
  const isPlan2 = BookSchedule1.length !== 0
  const isPlan1 = BookSchedule.length !== 0
  useEffect(() => {
    if(!dataAssign) {
      dataAssign = true
      setBookScheduled(BookSchedule)
      setBookScheduled1(BookSchedule1)
    }
  }, [isPlan1, dataAssign])
  return (
    <div>
      <div className={styles.modalContentContainer}>
        <CustomText size="large" color="white" weight="semi_bold">
          Assign workouts
        </CustomText>
      </div>
      {isPlan1 ? (
        <div className={styles.Containerworkout}>
          <CustomText className={styles.textelement} size="large" color="white" weight="semi_bold">
            {capitalize(planInstance1.name)}
          </CustomText>
          <br />
          <div style={{ paddingLeft: '40px', paddingRight: '40px' }}>
            <table className={styles.tabletotal}>
              <thead>
                <tr className={styles.palnhead}>
                  <th>
                    <span className={styles.tabletd1} style={{ color: 'white' }}>Total slot</span>
                  </th>
                  <th>
                    <span className={styles.tabletd1} style={{ color: 'white' }}>Available</span>
                  </th>
                  <th>
                    <span className={styles.tabletd1} style={{ color: 'white' }}>Booked</span>
                  </th>
                  <th>
                    <span className={styles.tabletd1} style={{ color: 'white' }}>
                      Redeemed
                    </span>
                  </th>
                  <th>
                    <span className={styles.tabletd1} style={{ color: 'white' }}>Missed</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <span
                      className={styles.tabletd1}
                      style={{
                        color: 'black', backgroundColor: 'white', borderRadius: '50%', fontWeight: 'bold',
                      }}
                    >
                      {planInstance1.duration}
                    </span>
                  </td>
                  <td style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <span
                      className={styles.tabletd1}
                      style={{
                        color: '#D142D1', backgroundColor: 'white', borderRadius: '50%', fontWeight: 'bold',
                      }}
                    >
                      {state.available1}
                    </span>
                  </td>
                  <td style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <span
                      className={styles.tabletd1}
                      style={{
                        color: '#5C6ABF', backgroundColor: 'white', borderRadius: '50%', fontWeight: 'bold',
                      }}
                    >
                      {state.booked1}
                    </span>
                  </td>
                  <td style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <span
                      className={styles.tabletd1}
                      style={{
                        color: '#6BE0A4', backgroundColor: 'white', borderRadius: '50%', fontWeight: 'bold',
                      }}
                    >
                      {state.redeemed1}
                    </span>
                  </td>
                  <td style={{ textAlign: 'center', paddingTop: '1rem' }}>
                    <span
                      className={styles.tabletd1}
                      style={{
                        color: 'red', backgroundColor: 'white', borderRadius: '50%', fontWeight: 'bold',
                      }}
                    >
                      {state.Missed1}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={styles.dietPlanItem}>
            <table className={styles.tableContainor}>
              <thead>
                <tr>
                  <th className={styles.tablehead}>
                    Day
                  </th>
                  <th className={styles.tablehead} style={{ color: 'black', backgroundColor: 'white' }}>
                    Workout
                  </th>
                  <th className={styles.tablehead} style={{ backgroundColor: '#FF7630' }}>
                    Booked on
                  </th>
                  <th className={styles.tablehead} style={{ backgroundColor: '#F94294' }}>
                    Time
                  </th>
                  <th className={styles.tablehead} style={{ backgroundColor: '#D145FE' }}>
                    Status
                  </th>
                  <th className={styles.tablehead} style={{ backgroundColor: '#3085FF' }}>
                    Option
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.values(BookScheduled).map((obj, i) => (
                  <tr key={obj.workouts}>
                    <td className={styles.tabletd} style={{ color: 'white' }}>{i + 1}</td>
                    <td className={styles.tabletd} style={{ color: 'white' }}>{capitalize(obj.Workout)}</td>
                    <td className={styles.tabletd} style={{ color: 'white' }}>
                      {getdate(obj)}
                    </td>
                    <td className={styles.tabletd} style={{ color: 'white' }}>
                      {gettime(obj)}
                    </td>
                    <td className={styles.tabletd}>{getBookingStatus(obj)}</td>
                    <td className={styles.tabletd}>
                      {getOptions(obj, i)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <br />
          <br />
          {isPlan2 && (
            <div>
              <CustomText size="large" className={styles.textelement} color="white" weight="semi_bold">
                {capitalize(planInstance2.name)}
              </CustomText>
              <br />
              <div style={{ paddingLeft: '40px', paddingRight: '40px' }}>
                <table className={styles.tabletotal}>
                  <thead>
                    <tr className={styles.palnhead}>
                      <th>
                        <span className={styles.tabletd1} style={{ color: 'white' }}>Total slot</span>
                      </th>
                      <th>
                        <span className={styles.tabletd1} style={{ color: 'skyblue' }}>Available</span>
                      </th>
                      <th>
                        <span
                          className={styles.tabletd1}
                          style={{ color: COLORS.BLUE }}
                        >
                          Booked
                        </span>
                      </th>
                      <th>
                        <span className={styles.tabletd1} style={{ color: COLORS.GREEN }}>
                          Redeemed
                        </span>
                      </th>
                      <th>
                        <span className={styles.tabletd1} style={{ color: 'red' }}>Missed</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: 'center', paddingTop: '1rem' }}>
                        <span
                          className={styles.tabletd1}
                          style={{
                            color: 'black', backgroundColor: 'white', borderRadius: '50%', fontWeight: 'bold',
                          }}
                        >
                          {planInstance2.duration}
                        </span>
                      </td>
                      <td style={{ textAlign: 'center', paddingTop: '1rem' }}>
                        <span
                          className={styles.tabletd1}
                          style={{
                            color: '#D142D1', backgroundColor: 'white', borderRadius: '50%', fontWeight: 'bold',
                          }}
                        >
                          {state.available2}
                        </span>
                      </td>
                      <td style={{ textAlign: 'center', paddingTop: '1rem' }}>
                        <span
                          className={styles.tabletd1}
                          style={{
                            color: '#5C6ABF', backgroundColor: 'white', borderRadius: '50%', fontWeight: 'bold',
                          }}
                        >
                          {state.booked2}
                        </span>
                      </td>
                      <td style={{ textAlign: 'center', paddingTop: '1rem' }}>
                        <span
                          className={styles.tabletd1}
                          style={{
                            color: '#6BE0A4', backgroundColor: 'white', borderRadius: '50%', fontWeight: 'bold',
                          }}
                        >
                          {state.redeemed2}
                        </span>
                      </td>
                      <td style={{ textAlign: 'center', paddingTop: '1rem' }}>
                        <span
                          className={styles.tabletd1}
                          style={{
                            color: 'red', backgroundColor: 'white', borderRadius: '50%', fontWeight: 'bold',
                          }}
                        >
                          {state.Missed2}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <div className={styles.dietPlanItems}>
                <table className={styles.tableContainor}>
                  <thead>
                    <tr>
                      <th className={styles.tablehead}>
                        Day
                      </th>
                      <th className={styles.tablehead} style={{ color: 'black', backgroundColor: 'white' }}>
                        Workout
                      </th>
                      <th className={styles.tablehead} style={{ backgroundColor: '#FF7630' }}>
                        Booked on
                      </th>
                      <th className={styles.tablehead} style={{ backgroundColor: '#F94294' }}>
                        Time
                      </th>
                      <th className={styles.tablehead} style={{ backgroundColor: '#D145FE' }}>
                        Status
                      </th>
                      <th className={styles.tablehead} style={{ backgroundColor: '#3085FF' }}>
                        Option
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(BookScheduled1).map((obj, i) => (
                      <tr key={obj.workouts}>
                        <td className={styles.tabletd} style={{ color: 'white' }}>{i + 1}</td>
                        <td className={styles.tabletd} style={{ color: 'white' }}>{obj.Workout}</td>
                        <td className={styles.tabletd} style={{ color: 'white' }}>
                          {getdate(obj)}
                        </td>
                        <td className={styles.tabletd} style={{ color: 'white' }}>
                          {gettime(obj)}
                        </td>
                        <td className={styles.tabletd} style={{ color: 'white' }}>
                          {getBookingStatus(obj)}
                        </td>
                        <td className={styles.tabletd}>
                          {getOptions2(obj, i)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {booksessionmodel()}
          <DeletePromptModal
            visible={deletePromptModalv}
            onDismiss={toggleModald}
            dismissKey="showDeleteModal"
            onDelete={onDeleteSession}
            typeText="Session"
          />
        </div>
      ) : (
        <div style={{
          textAlign: 'center',
          paddingTop: '15%',
          fontWeight: '800',
          fontSize: '25px',
          color: 'white',
        }}
        >
          No Active Plans
        </div>
      )}
    </div>
  )
}

const stylesheet = ({
  modalContainer: {
    height: '50%',
  },
  timeInputContainer: {
    position: 'unset',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    width: '100%',
    '& > div:nth-child(2)': {
      position: 'absolute',
      top: '10px',
      left: '0',
    },
  },
  inputIcon: {
    position: 'absolute',
    right: 10,
    zIndex: 1,
  },
  timeInput: {
    border: `1px solid ${theme.inputBorder}`,
    borderRadius: 4,
    padding: `${SPACING.SPACE_6} ${SPACING.SPACE_10}`,
    boxSizing: 'border-box',
    zIndex: '9',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: theme.textSecondary,
      fontWeight: 300,
    },
  },
  dateInput: {
    border: `1px solid ${theme.inputBorder}`,
    // color: COLORS.ORANGE,
    // borderColor: theme.orangeLightBg,
    borderRadius: 4,
    backgroundColor: 'transparent',
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_10}`,
    boxSizing: 'border-box',
    zIndex: '9',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: theme.textSecondary,
      fontWeight: 300,
    },
  },
  tableContainor: {
    width: '100%',
    // border: '2px solid #ff8c00',
    color: 'white',
    minHeight: 580,
    maxHeight: 580,
    overflowY: 'auto',
  },
  tabletd: {
    border: '1px solid #ddd',
    padding: '10px',
    // fontWeight: 'bold',
  },
  tablehead: {
    padding: '20px',
    border: '0.5px solid #ddd',
  },
  tabletd1: {
    padding: '10px',
  },
  tabletotal: {
    width: '100%',
    border: '1px solid white',
    borderRadius: '1rem',
    padding: '1.5rem',
  },
  cardtabletwo: {
    backgroundColor: theme.bgPrimary,
    boxShadow: '0 2px 4px',
    padding: SPACING.SPACE_16,
    height: '9%',
    alignItems: 'center',
    width: '100%',
  },
  videoPlayerModal: {
    maxWidth: `${DIMENSIONS.WIDTH * 0.5}px !important`,
  },
  cardtabletwo1: {
    backgroundColor: theme.bgPrimary,
    height: '10%',
    boxShadow: '0 2px 4px',
    padding: SPACING.SPACE_10,
    alignItems: 'center',
    width: '100%',
  },
  cardtitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '8px',
    height: '40%',
    width: '30px',
    paddingLeft: '50px',
    paddingRight: '40px',
  },
  card1: {
    backgroundColor: theme.bgPrimary,
    height: '55px',
    boxShadow: '0 2px 4px',
    padding: SPACING.SPACE_10,
    alignItems: 'center',
  },
  cardtitle1: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '8px',
    padding: SPACING.SPACE_48,
  },
  modalContentContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: SPACING.SPACE_20,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING.SPACE_20,
    margin: `${SPACING.SPACE_10} 0`,
  },
  dropDownContainer: {
    width: '30%',
    height: '30px',
  },
  inputone: {
    width: '100%',
    position: 'relative',
    left: '35%',
    top: '-32px',
  },
  comment: {
    position: 'relative',
    left: '70%',
    top: '-4.2rem',
    height: '0px',
    width: '30%',
  },
  inputtwo: {
    width: '30%',
    position: 'relative',
    left: '35%',
    top: '-57px',
    height: '0px',
  },
  fieldInput: {
    backgroundColor: theme.inputLightBg,
  },
  fieldInputContainer: {
    // maxWidth: 200,
  },
  dropDownInput: {
    marginTop: '2px',
  },
  inputLabel: {
    marginBottom: SPACING.SPACE_12,
  },
  bookSessionBtn: {
    justifySelf: 'flex-end',
  },
  sessionDate: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: SPACING.SPACE_20,
    flexDirection: 'column',
  },
  sessionTime: {
    display: 'flex',
    gap: SPACING.SPACE_20,
    flexDirection: 'column',
  },
  dietPlanItem: {
    // backgroundColor: '#494949',
    // border: '1px solid #ffcc80',
    borderRadius: 12,
    // margin: 10,
    padding: `${SPACING.SPACE_40} ${SPACING.SPACE_40}`,
    width: '100%',
    minHeight: 580,
    maxHeight: 580,
    overflowY: 'auto',
  },
  dietPlanItems: {
    // backgroundColor: '#494949',
    // border: '1px solid #ffcc80',
    borderRadius: 12,
    // margin: 10,
    padding: `${SPACING.SPACE_40} ${SPACING.SPACE_40}`,
    width: '100%',
    minHeight: 580,
    maxHeight: 580,
    overflowY: 'auto',
    // overflowY: 'auto',
  },
  Containerworkout: {
    // display: 'flex',
  },
  '@media only screen and (max-width: 940px) ': {
    Containerworkout: {
      flexDirection: 'column',
    },
    palnhead: {
      gap: SPACING.SPACE_20,
    },
    tabletotal: {
      fontSize: '10px',
      overflowY: 'auto',
      padding: '0.5rem',
    },
    tabletd1: {
    },
  },
  text: {
    textAlign: 'center',
    paddingTop: '15%',
    fontWeight: '800',
    fontSize: '25px',
    position: 'absolute',
    top: '17%',
    left: '45%',
  },
  textelement: {
    paddingLeft: '30px',
  },
})

const mapStateToProps = state => ({
  adminUsers: state.dashboard.adminUsers,
  user_details: state.auth.user_details,
  allworkots: state.assignedWorkouts.allworkots,
  isCreatingSession: state.sessions.isCreatingSession,
  createSessionErr: state.sessions.createSessionErr,
  createSessionErrMsg: state.sessions.createSessionErrMsg,
  createSessionResp: state.sessions.createSessionResp,
  isDeletingSession: state.sessions.isDeletingSession,
  sessionDeleted: state.sessions.sessionDeleted,
  deleteSessionErr: state.sessions.deleteSessionErr,
  deleteSessionErrMsg: state.sessions.deleteSessionErrMsg,
})

const mapDispatchToProps = dispatch => ({
  fetchAssignedMembers: () => dispatch(getAllUsers()),
  getAssingWorkouts: () => dispatch(getWorkouts()),
  CreatbookSession: (params, userType, creatsessionkey) => dispatch(
    bookSession(params, userType, creatsessionkey),
  ),
  deleteSessionM: params => dispatch(deleteSession(params)),
  updateSchedule: params => dispatch(updateWorkouts(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(stylesheet)(AssignedWorkouts))
