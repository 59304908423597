import { takeLatest, put, call } from 'redux-saga/effects'
import {
  getDocs, query, collection, where, doc, updateDoc,
} from 'firebase/firestore'
import {
  getAssignedMembersSuccess,
  getAssignedMembersFailure,
  createDietPlanFailure,
  createDietPlanSuccess,
} from './actions'
import {
  GET_ASSIGNED_MEMBERS,
  CREATE_DIET_PLAN,
} from './actionTypes'
import { db } from '../../utils/firebaseConfig'

import { error_msg } from '../../utils/common'

function* getAssignedMembers({ params }) {
  try {
    const q = query(collection(db, 'users'), where('type', '==', 'member'), where('trainer.uid', '==', params.uid))
    const data = yield call(getDocs, q)
    const members = []
    data.forEach(res => members.push(res.data()))

    if (members.length) {
      yield put(getAssignedMembersSuccess(members))
    } else {
      yield put(getAssignedMembersFailure(data.error))
    }
  } catch (err) {
    yield put(getAssignedMembersFailure(err.message || error_msg))
  }
}

export function* getAssignedMembersSaga() {
  yield takeLatest(GET_ASSIGNED_MEMBERS, getAssignedMembers)
}

function* createDietPlan({ params }) {
  try {
    const docRef = doc(db, 'users', params.uid)
    delete params.uid
    yield call(updateDoc, docRef, { diet: params })
    yield put(createDietPlanSuccess())
  } catch (err) {
    yield put(createDietPlanFailure(err.message || error_msg))
  }
}

export function* createDietPlanSaga() {
  yield takeLatest(CREATE_DIET_PLAN, createDietPlan)
}
