import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'

import dayjs from './helpers/dayjs'
import { baseUrl } from './apiEndpoints'
import { LIGHT, DARK, ICONS } from '../Theme'
import { auth } from './firebaseConfig'

const { PARTIAL_1, PARTIAL_2, PARTIAL_3 } = ICONS

export const headersGenerator = (obj, json) => {
  const headers = new Headers()
  Object.keys(obj).forEach(key => headers.append(key, obj[key]))
  headers.append('referer', `${baseUrl}dashboard_metrics/`)
  if (json) {
    headers.append('accept', 'application/json text/plain')
    headers.append('content-type', 'application/json')
  } else {
    headers.append('content-type', 'application/x-www-form-urlencoded; charset=UTF-8')
  }
  // headers.append('Cache-Control', 'no-cache, no-store, must-revalidate')
  // headers.append('Pragma', 'no-cache')
  // headers.append('Expires', 0)
  return headers
}

export const getEncodedParams = (params) => {
  const searchParams = []
  Object.keys(params).forEach((key) => {
    const encodedKey = encodeURIComponent(key)
    if (
      key === 'seg_sym_list'
      || (params[key] && params[key].constructor.name === 'Array')
    ) {
      const encodedValue = encodeURIComponent(JSON.stringify(params[key]))
      searchParams.push(`${encodedKey}=${encodedValue}`)
    } else {
      const encodedValue = encodeURIComponent(params[key])
      searchParams.push(`${encodedKey}=${encodedValue}`)
    }
  })
  return searchParams.join('&')
}

// returns a non-encoded string representation of params with `?` appended at the start.
export const getPlainQueryParams = (params, encode) => {
  let queryParamString = ''
  if (params && Object.keys(params).length) {
    queryParamString = Object.keys(params)
      .map(key => `${key}=${encode ? btoa(params[key]) : params[key]}`)
      .join('&')
    queryParamString = `?${queryParamString}`
  }
  return queryParamString
}

export const getQueryParams = (encodeUrl) => {
  // const url = decodeURIComponent(encodeUrl)
  // const query = url.split('?')[1] || ''
  const encodedParams = encodeUrl.split('?')[1] || ''
  const query = decodeURIComponent(encodedParams)
  const queryArray = query.split('&') || []
  const queries = {}
  queryArray.map((q) => {
    const keyValue = q.split('=')
    queries[keyValue[0]] = keyValue[1]
    return null
  })
  return queries
}

// used to get params from url
export const parseQuery = (str, decode) => {
  const queries = {}
  if (!str) return queries
  const index = str.indexOf('?')
  let query = str.substring(index + 1)
  query = query.split('&')
  for (let j = 0; j < query.length; j++) {
    const [key, value] = query[j].split('=')
    if (decode) {
      try {
        queries[key] = atob(value)
      } catch (e) {
        queries[key] = value
      }
    } else if (key === 'stock') {
      queries.stock = decodeURIComponent(value).split(',')
      // if (queries.stock) {
      //   queries.stock.push(decodeURIComponent(value))
      // } else {
      //   queries.stock = [value]
      // }
    } else {
      queries[key] = decodeURIComponent(value)
    }
  }
  return queries
}

export const mergeHeaders = (defaultHeaders, newheaders) => {
  const mergedHeaders = cloneDeep(defaultHeaders)
  const headersLocation = newheaders instanceof Headers ? newheaders.map : newheaders
  if (!isEmpty(headersLocation)) {
    Object.keys(newheaders).forEach((head) => {
      mergedHeaders.set(head, headersLocation[head])
    })
  }
  return mergedHeaders
}

export const validateMail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const hexCodeGenerator = (str) => {
  const s = unescape(encodeURIComponent(str))
  let h = ''
  for (let i = 0; i < s.length; i++) {
    h += s.charCodeAt(i).toString(16)
  }
  return h
}

export const navigationOptions = (screen, passProps = {}) => {
  return {
    component: {
      name: screen,
      passProps,
    },
  }
}

export const areEqual = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps)
}

export const removeUndefinedKeys = (obj) => {
  let modObj = {}
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      modObj = {
        ...modObj,
        [key]: obj[key],
      }
    }
  })
  return modObj
}

export const getLocalDate = ({ dateObj, format } = {}) => {
  // const finalOffset = offset || dayjs().utcOffset()
  const localDate = dayjs(dateObj).utc().format(format || 'DD/MM/YYYY HH:mm:ss')
  return localDate
}

export const getProgressColor = (progress = 0, isDark) => {
  const themeType = isDark ? DARK : LIGHT
  if (progress <= 25) return { progressColor: themeType.progress25, progressImg: PARTIAL_1 }
  if (progress <= 50) return { progressColor: themeType.progress50, progressImg: PARTIAL_2 }
  if (progress <= 75) return { progressColor: themeType.progress75, progressImg: PARTIAL_3 }
  return { progressColor: themeType.progress25, progressImg: PARTIAL_1 }
}

// web specific

export const setCookie = (obj_to_save, expiryTime) => {
  const keys = Object.keys(obj_to_save)
  let expiry = new Date(Date.now() + 36000000).toUTCString()
  if (expiryTime) {
    expiry = expiryTime
  }
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const value = obj_to_save[key]
    document.cookie = `${key}=${value};expires=${expiry};path=/;`
  }
}

export const deleteCookies = (cookieArr) => {
  cookieArr.map((item) => {
    document.cookie = `${item}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;`
    return null
  })
}

export const getCookies = () => {
  let cookies = decodeURIComponent(document.cookie)
  cookies = cookies.split(';')
  const cookie_obj = {}
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split('=')
    const [key, value] = cookie
    if (key && value) {
      const finalValue = cookie.slice(1, cookie.length).join('=')
      cookie_obj[key.trim()] = finalValue.trim()
    }
  }
  return cookie_obj
}

export const headerGenerator = (auth_token = '', sessionid = '') => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    authorization: `csrftoken=${auth_token};csrfmiddlewaretoken=${auth_token};sessionid=${sessionid}`,
    'x-csrftoken': sessionid,
  }
  return headers
}

export const setLocalStorage = (item, convert) => {
  Object.keys(item).forEach((key) => {
    localStorage.setItem(key, convert ? JSON.stringify(item[key]) : item[key])
  })
}

export const getLocalStorage = (key) => {
  return localStorage.getItem(key)
}

export const deleteLocalStorage = (keyArr, clearAll) => {
  if (clearAll) {
    localStorage.clear()
    return
  }
  keyArr.forEach(item => localStorage.removeItem(item))
}

export const removeObjectProperties = (obj, props) => {
  for (let i = 0; i < props.length; i++) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(props[i])) {
      delete obj[props[i]]
    }
  }
}

export const error_msg = 'Error getting response'

export const createHex = () => {
  let hexCode1 = ''
  const hexValues1 = '0123456789abcdef'

  for (let i = 0; i < 6; i++) {
    hexCode1 += hexValues1.charAt(Math.floor(Math.random() * hexValues1.length))
  }
  return hexCode1
}

export const getGradient = () => {
  const deg = Math.floor(Math.random() * 360)

  const gradient = `linear-gradient(${deg}deg, #${createHex()}, #${createHex()})`
  return gradient
}

export function onAuthStateChanged() {
  return new Promise((resolve) => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        resolve(user)
      } else {
        resolve({ error: 'No user' })
      }
    })
  })
}

export const getIdToken = () => {
  return new Promise((resolve, reject) => {
    auth.onIdTokenChanged((user, err) => {
      if (user) {
        resolve(user.toJSON())
      } else {
        reject(err)
      }
    })
  })
}

export const checkActivePlan = (plans) => {
  let hasActivePlan = false
  plans.forEach((plan) => {
    const planStartDate = plan.plan_end_date
    if (!dayjs().isAfter(planStartDate) || !dayjs().isSame(planStartDate)) {
      hasActivePlan = true
    }
  })

  return hasActivePlan
}

export const getRemainingSessions = (user_details) => {
  if (user_details.type !== 'member') return 0
  const sessionDone = user_details.session_count || 0
  const sessionAvailable = user_details?.plan_instance.map((plan) => {
    const daysLeft = dayjs(plan.plan_start_date * 1000).add(30, 'days').diff(dayjs(), 'days')
    return daysLeft
  }).reduce((a, b) => a + b, 0)
  const totalSessionAvailable = sessionAvailable - sessionDone
  return totalSessionAvailable
}
