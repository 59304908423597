export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE'

export const SIGN_UP = 'SIGN_UP'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE'

export const AUTHENTICATE_TOKEN = 'AUTHENTICATE_TOKEN'
export const AUTHENTICATE_TOKEN_SUCCESS = 'AUTHENTICATE_TOKEN_SUCCESS'
export const AUTHENTICATE_TOKEN_FAILURE = 'AUTHENTICATE_TOKEN_FAILURE'

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE'

export const UPDATE_NUMBER = 'UPDATE_NUMBER'
export const UPDATE_NUMBER_SUCCESS = 'UPDATE_NUMBER_SUCCESS'
export const UPDATE_NUMBER_FAILURE = 'UPDATE_NUMBER_FAILURE'

export const UPDATE_PROFILE_IMG = 'UPDATE_PROFILE_IMG'
export const UPDATE_PROFILE_IMG_SUCCESS = 'UPDATE_PROFILE_IMG_SUCCESS'
export const UPDATE_PROFILE_IMG_FAILURE = 'UPDATE_PROFILE_IMG_FAILURE'

export const UPDATE_EMAIL_VERIFICATION = 'UPDATE_EMAIL_VERIFICATION'
export const UPDATE_EMAIL_VERIFICATION_SUCCESS = 'UPDATE_EMAIL_VERIFICATION_SUCCESS'
export const UPDATE_EMAIL_VERIFICATION_FAILURE = 'UPDATE_EMAIL_VERIFICATION_FAILURE'
