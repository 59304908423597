import React from 'react'

import CustomText from '../../UI/CustomText'
import ListDropdown from '../../UI/Dropdown/ListDropdown'

import { SPACING } from '../../Theme'

const labelRender = (optionObj, label, selected) => {
  const color = optionObj.label.includes('Nearest') ? 'green' : selected ? 'linkColor' : 'text'
  return (
    <CustomText
      size="small"
      style={{
        padding: `${SPACING.SPACE_10} 0`,
      }}
      color={color}
    >
      {optionObj.label}
    </CustomText>
  )
}

const AutoComplete = ({
  onSearch,
  options,
  onSelect,
  searchValue,
  // defaultLabel,
  selected,
  placeholder,
  txt2,
  itemBtnStyles,
  toggleCallback,
  inputBtnStyles,
  containerStyles,
  inputContainerStyles,
  label,
  changeKey,
  labelcolor,
}) => {
  const onChangeOption = (name, item = {}) => {
    onSelect(name, item, changeKey)
  }

  return (
    <ListDropdown
      options={options}
      selected={selected}
      selectedLabel="GGG"
      label={label}
      // searchValue={searchValue}
      placeholder={placeholder}
      onChangeOption={onChangeOption}
      showDropDownIcon={false}
      labelKey="label"
      txt2={txt2}
      valueKey="value"
      labelcolor={labelcolor}
      searchProps={{
        onChange: onSearch,
        value: searchValue,
      }}
      itemBtnStyles={itemBtnStyles}
      inputBtnStyles={inputBtnStyles}
      containerStyles={containerStyles}
      inputContainerStyles={inputContainerStyles}
      labelRender={labelRender}
      minWidth={200}
      toggleCallback={toggleCallback}
      showKeyNav
    />
  )
}

export default AutoComplete
