import { capitalize } from 'lodash'
import moment from 'moment'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getSessionDetails, startSession } from '../../containers/Sessions/actions'
import { getVideos } from '../../containers/Videos/actions'
import { SPACING, theme } from '../../Theme'
import { withTheme } from '../../Theme/ThemeProvider'
import Button from '../../UI/Button'
import CustomText from '../../UI/CustomText'
import ScreenHeader from '../../UI/ScreenHeader'
import { showSnackbar } from '../../UI/Snackbar'
import ViewAllCard from '../../UI/ViewAllCard'
import MediaPlayer from '../../UI/MediaPlayer/Player'

import { WORKOUTS_MAP } from '../../utils/consts'
import CustomModal from '../../UI/CustomModal'

function SessionDetails(props) {
  const {
    styles, user_details = {}, match = {}, fetchSession,
    sessionDetails = {}, startWorkoutSession, isStartingSession,
    sessionStarted, startSessionErr, getWorkoutVideos, videos = {},
  } = props

  const { name = '', type, uid } = user_details

  const isTrainer = type === 'trainer'

  const session_id = match.params.id

  const [showVideoModal, toggleVideoModal] = React.useState(false)
  const [activeVideo, setActiveVideo] = React.useState({})

  useEffect(() => {
    fetchSession({ session_id })

    getWorkoutVideos({ workouts: [sessionWorkouts] })
  }, [])

  useEffect(() => {
    if (!isStartingSession) {
      if (sessionStarted) {
        showSnackbar('Session started', {}, 1)
      } else if (startSessionErr) {
        showSnackbar('Error starting session', {}, 1)
      }
    }
  }, [isStartingSession])

  const {
    scheduled_for, trainer_name, workout: sessionWorkouts = '', name: memberName, status = 0, video: WorkoutVideo, Comments,
  } = sessionDetails

  const userName = isTrainer ? name : capitalize(memberName)

  const time = moment(scheduled_for).format('hh:mm A')
  const date = moment(scheduled_for).format('DD MMM YYYY')

  const onStartWorkout = () => {
    startWorkoutSession({ uid, session_id })
  }

  const openVideo = async (video) => {
    await setActiveVideo(video)
    await toggleModal()
  }

  const toggleModal = () => {
    toggleVideoModal(!showVideoModal)
  }

  const actionBarRenderer = () => (
    <Button
      buttonColor="orange"
      text="Start Workout"
      uppercase
      btnStyles={styles.actionBarBtn}
      params="showNewSesssionModal"
      onPress={onStartWorkout}
      loading={isStartingSession}
      disabled={isStartingSession}
    />
  )

  const renderSessionCard = () => {
    const dateString = new Date(date).toDateString()
    // const workouts = [sessionWorkouts].map(workout => WORKOUTS_MAP[workout])
    // const workoutString = workouts?.join(', ')
    return (
      <div key={session_id} className={styles.card}>
        <div className={styles.cardHeader}>
          <div>
            <CustomText size="large" weight="medium" color="#DEFFE7">{dateString}</CustomText>
            <br />
            <CustomText size="small" weight="medium" color="#DEFFE7">{`Workout: ${sessionWorkouts}`}</CustomText>
          </div>
          <CustomText size="large" weight="medium" color="#DEFFE7">{time}</CustomText>
        </div>
        <div className={styles.cardFooter}>
          {!isTrainer && <CustomText color="#F7F7FA" weight="medium" size="small">{`Trainer: ${trainer_name}`}</CustomText>}
        </div>
      </div>
    )
  }

  const renderVideoCard = (video) => {
    return (
      <button
        type="button"
        className={styles.videoCard}
        key={video.name}
        onClick={() => openVideo(video)}
      >
        <CustomText weight="medium" size="large" color="#f0fff0">{capitalize(video.name)}</CustomText>
      </button>
    )
  }

  const renderVideoModal = () => {
    const { name: videoName, video } = activeVideo || {}
    return (
      <CustomModal
        visible={showVideoModal}
        onDismiss={toggleModal}
        dismissKey="showVideoModal"
        containerStyles={styles.videoPlayerModal}
        hideScroll
      >
        <MediaPlayer name={videoName} url={video} />
      </CustomModal>
    )
  }

  const renderWorkoutVidsRow = (workout) => {
    const label = WORKOUTS_MAP[workout]
    let videosForWorkout = videos[workout] || []
    if(WorkoutVideo !== '') {
      videosForWorkout = Object.values(videosForWorkout).filter(v => WorkoutVideo.includes(v.id))
    }
    return (
      <ViewAllCard
        title={label}
        viewAllText="none"
        contentStyles={styles.workoutVidsRowContent}
        cardStyles={styles.viewAllContainer}
      >
        {videosForWorkout.length
          ? videosForWorkout.map(renderVideoCard) : (
            <div className={styles.viewAllContainer}>
              <CustomText size="small">{`No ${label} videos`}</CustomText>
            </div>
          )}
      </ViewAllCard>
    )
  }

  return (
    <div className={styles.container}>
      <ScreenHeader
        title="Sessions"
        actionBarRenderer={status < 1 ? actionBarRenderer : null}
      />
      <div className={styles.contentContainer}>
        <CustomText uppercase size="medium" color="white" weight="semi_bold">{`Welcome, ${userName}`}</CustomText>
        <div className={styles.upcomingSessionContainer}>
          <CustomText weight="medium" color="#0EC213" size="medium">Upcoming Session</CustomText>
          {renderSessionCard()}
        </div>
        <div>
          <CustomText weight="medium" color="#FCDECA" size="medium">Trainer Instructions</CustomText>
          <br />
          <div style={{ color: 'white' }}>{Comments}</div>
        </div>
        <br />
        <br />
        <div>
          <CustomText weight="medium" color="#FCDECA" size="medium">Workout Videos</CustomText>
          <div style={{ color: 'white' }}>{[sessionWorkouts].map(renderWorkoutVidsRow)}</div>
        </div>
      </div>
      {renderVideoModal()}
    </div>
  )
}

const stylesheet = {
  container: {
    padding: SPACING.SPACE_20,
  },
  contentContainer: {
    backgroundColor: '#494949',
    border: '1px solid #ffcc80',
    borderRadius: 12,
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_20}`,
    width: '100%',
    marginTop: SPACING.SPACE_20,

  },
  upcomingSessionContainer: {
    margin: `${SPACING.SPACE_20} 0`,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: SPACING.SPACE_20,
  },
  card: {
    backgroundColor: '#393939',
    borderRadius: 12,
    border: `1px solid ${theme.orangeLightBg}`,
    padding: `${SPACING.SPACE_16} ${SPACING.SPACE_16}`,
    maxWidth: 400,
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    // alignItems: 'center',
    gap: SPACING.SPACE_20,
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: SPACING.SPACE_20,
  },
  viewAllContainer: {
    boxShadow: 'none',
  },
  workoutVidsRowContent: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: SPACING.SPACE_20,
    padding: `0 ${SPACING.SPACE_16}`,
  },
  loader: {
    height: 120,
    maxWidth: 240,
  },
  videoCard: {
    height: 120,
    width: 240,
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: SPACING.SPACE_16,
    background: 'linear-gradient(90deg, hsla(12, 84%, 68%, 1) 0%, hsla(343, 100%, 66%, 1) 100%)',
  },
}

const mapStateToProps = (state) => {
  const { user_details } = state.auth
  const {
    sessionDetails, isStartingSession, sessionStarted, startSessionErr,
  } = state.sessions
  const { videos } = state.videos
  return {
    user_details,
    sessionDetails,
    isStartingSession,
    sessionStarted,
    startSessionErr,
    videos,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSession: params => dispatch(getSessionDetails(params)),
    startWorkoutSession: params => dispatch(startSession(params)),
    getWorkoutVideos: params => dispatch(getVideos(params)),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    withTheme(stylesheet)(SessionDetails),
  ),
)
