import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Plans from './Plans'

const dummy = () => {
}

const mapStateToProps = (state) => {
  return {
    ...state.common,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dummy: params => dispatch(dummy(params)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Plans))
