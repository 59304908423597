import React, { Component } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'
import dayjs from 'dayjs'
import CustomText from '../../UI/CustomText'
import ScreenHeader from '../../UI/ScreenHeader'
import { withTheme } from '../../Theme/ThemeProvider'
// import CustomInput from '../../UI/CustomInput'
import Button from '../../UI/Button'
import ListDropdown from '../../UI/Dropdown/ListDropdown'
import { PAYMENT_METHODS } from '../../utils/consts'
import DateTimePicker from '../../UI/DateTimePicker'
import Icon from '../../UI/Icon'
import AutoComplete from '../AutoComplete'
import { getAllUsers } from '../../containers/Dashboard/actions'
import { createInvoice } from '../../containers/Payments/actions'
import {
  theme, ICONS,
  SPACING, COLORS,
} from '../../Theme'
import { showSnackbar } from '../../UI/Snackbar'
import CustomInput from '../../UI/CustomInput'

class Invoice extends Component {
  constructor(props) {
    super(props)
    this.state = {
      invoiceUser: '',
      invoicename: '',
      invoicenumber: '',
      invoiceEmail: '',
      invoiceBranch: '',
      InvoiceDate: new Date().valueOf(),
      invoicePlans: [],
      paymentMethod: '',
      transactionId: '',
      transactionDate: '',
      planMethod: '',
      planstartdate: new Date().valueOf(),
      planenddate: '',
      purchase_data: [],
      invoicedata: [],
      plan_method: '',
      planstart: '',
      planend: '',
      transactionAmount: 0,
      Discountamount: '',
      tamount: 0,
      cgsttamount: '',
      sgsttamount: '',
      balance: 0,
      totalAmount: 0,
      paidamount: 0,
      totalgst: 0,
      planId: {},
      invoiceUserId: '',
      plandetails: [],
      paymentDiscount: 0,
    }
    this.editMode = false
    this.editIndx = 0
  }

  componentDidMount() {
    const { fetchAllUsers } = this.props
    const {
      name, phone, email, uid, branch_id,
    } = this.props.location.state || {}
    this.setState({
      invoicenumber: phone,
      invoiceEmail: email,
      invoiceUser: name,
      invoiceUserId: uid,
    })
    if(branch_id !== undefined) {
      this.setState({
        invoiceBranch: branch_id,
      })
    }
    fetchAllUsers()
  }

  componentDidUpdate(prevProps) {
    const {
      isCreatingInvoice, createInvoiceErr, createInvoiceErrMsg, invoiceCreated,
    } = this.props
    if (!isEqual(prevProps.isCreatingInvoice, isCreatingInvoice) && !isCreatingInvoice) {
      if (createInvoiceErr) {
        showSnackbar(createInvoiceErrMsg, {}, 0)
      } else if (invoiceCreated) {
        showSnackbar('invioce Created', {}, 1)
      }
    }
  }

  onChangeText = (e, key) => {
    const value = e.target.value
    this.setState({ [key]: value })
  }

  onChangeTextPaid = (e, key) => {
    const { totalAmount } = this.state
    const value = e.target.value
    const balance = (totalAmount - value)
    this.setState({ [key]: value, balance })
  }

  onSelect = (value, obj, key) => {
    this.setState({
      [key]: value,
    })
  }

  onSelectDate = (value, obj, key) => {
    const { allplans } = this.props
    const { planMethod } = this.state
    let plandetails = allplans.filter(p => planMethod.includes(p.id))
    if(this.editMode && plandetails.length === 0) {
      plandetails = allplans.filter(p => planMethod.includes(p.name))
    }
    const duration = plandetails[0].duration
    const planenddate = moment(value, 'DD/MM/YYYY').add(Number(duration), 'days').valueOf()
    const vv = value.valueOf()
    this.setState({
      [key]: vv,
      planenddate,
    })
  }

  getAmount = (pIds) => {
    const { allplans } = this.props
    const Plans = Object.values(allplans)
    let amount = 1
    pIds.forEach((plan) => {
      const purchase = Plans.find(p => p.id === plan)
      if (purchase) {
        amount += Number(purchase.amount)
      }
    })
    return amount
  }

  onSelectplan = (value, obj, key) => {
    const { allplans } = this.props
    // const { planstartdate } = this.state
    const plandetails = allplans.filter(plans => value.includes(plans.id))
    const amount = plandetails[0].amount
    this.setState({
      [key]: value, transactionAmount: amount,
    })
    const duration = plandetails[0].duration
    const planenddate = moment().add(Number(duration), 'days').valueOf()
    const vv = new Date().valueOf()
    this.setState({
      planstartdate: vv,
      planenddate,
    })
  }

  disablePastDt = (current) => {
    const yesterday = moment().subtract(1, 'day')
    return current.isAfter(yesterday)
  }

  onCreateMembership = () => {
    const {
      invoiceBranch, planMethod, planenddate,
      planstartdate, purchase_data, transactionAmount,
      tamount, paidamount, planId, plandetails,
    } = this.state
    let { paymentDiscount } = this.state
    if (!invoiceBranch || !planMethod || !planstartdate) {
      showSnackbar('Please enter all detailes')
    }
    const {
      allplans,
    } = this.props
    let SelectedPlan = allplans.filter(p => planMethod.includes(p.id))
    if(this.editMode && SelectedPlan.length === 0) {
      SelectedPlan = allplans.filter(p => planMethod.includes(p.name))
    }
    const data = {
      branch_id: invoiceBranch,
      plan_method: SelectedPlan[0].name,
      planstart: planstartdate,
      planend: planenddate,
      transactionAmount,
      totaldiscount: paymentDiscount,
    }
    let ttamount = Number(tamount)
    const pdata = purchase_data
    let len = purchase_data.length
    const PlanIds = planId
    if(this.editMode) {
      len = this.editIndx
      const { transactionAmount: editAmout, totaldiscount: editDiscount } = pdata[len]
      ttamount -= Number(editAmout)
      ttamount += Number(transactionAmount)
      paymentDiscount -= editDiscount
      this.editMode = false
    } else {
      ttamount += Number(transactionAmount)
    }
    pdata[len] = data
    PlanIds[len] = SelectedPlan[0].id
    const pDetails = plandetails
    const plande = {
      ...SelectedPlan[0], plan_start_date: planstartdate, plan_end_date: planenddate,
    }
    pDetails[len] = plande
    const Discountamount = Math.round((ttamount * paymentDiscount) / 100)
    ttamount -= Math.round(Discountamount)
    const cgsttamount = Math.round((ttamount * 9) / 100)
    const sgsttamount = Math.round((ttamount * 9) / 100)
    const totalgst = Math.round(sgsttamount + cgsttamount)
    const totalAmount = Math.round(sgsttamount + cgsttamount + ttamount)
    const balance = Math.round(totalAmount - paidamount)
    this.setState({
      purchase_data: pdata,
      tamount: ttamount,
      cgsttamount,
      sgsttamount,
      totalgst,
      totalAmount,
      Discountamount,
      balance,
      planId: PlanIds,
      plandetails: pDetails,
      planname: planMethod,
    })
    // this.setState({
    //   invoiceBranch: '',
    //   planMethod: '',
    //   planstartdate: '',
    //   planenddate: '',
    //   paymentDiscount: '',
    //   transactionAmount: '',
    // })
    return null
  }

  onEditPlan = (ind) => {
    this.editMode = true
    this.editIndx = ind
    const { purchase_data } = this.state
    const {
      plan_method, branch_id, planstart, planend, transactionAmount, totaldiscount,
    } = purchase_data[ind]
    this.setState({
      planMethod: plan_method,
      invoiceBranch: branch_id,
      planstartdate: planstart,
      planenddate: planend,
      transactionAmount,
      paymentDiscount: totaldiscount,
    })
  }

  DeletePlan = (ind) => {
    const {
      purchase_data, tamount, planId, paidamount, plandetails,
    } = this.state
    let { paymentDiscount } = this.state
    const pdata = []
    const PlanIds = {}
    const pDetails = []
    let i = 0
    purchase_data.map((obj, indx) => {
      if(indx !== ind) {
        pdata[i] = obj
        i++
      }
      return null
    })
    i = 0
    for(let j = 0; j < Object.values(planId).length; j++) {
      if(j !== ind) {
        PlanIds[i] = planId[j]
        i++
      }
    }
    i = 0
    plandetails.map((obj, indx) => {
      if(indx !== ind) {
        pDetails[i] = obj
        i++
      }
      return null
    })
    const {
      transactionAmount: deleteAmout,
      totaldiscount: deleteDiscount,
    } = purchase_data[ind]
    let ttamount = tamount
    ttamount -= deleteAmout
    paymentDiscount -= deleteDiscount
    const Discountamount = (ttamount * paymentDiscount) / 100
    ttamount -= Discountamount
    const cgsttamount = (ttamount * 9) / 100
    const sgsttamount = (ttamount * 9) / 100
    const totalgst = sgsttamount + cgsttamount
    const totalAmount = sgsttamount + cgsttamount + ttamount
    const balance = totalAmount - paidamount
    this.setState({
      purchase_data: pdata,
      tamount: ttamount,
      cgsttamount,
      sgsttamount,
      totalgst,
      totalAmount,
      Discountamount,
      balance,
      planId: PlanIds,
      plandetails: pDetails,
    })
  }

  onCreateInvoice = () => {
    const { createInvoices, user_details } = this.props
    const {
      invoicenumber,
      invoiceBranch,
      paidamount,
      paymentMethod,
      Discountamount,
      tamount,
      sgsttamount,
      cgsttamount,
      totalAmount,
      balance,
      planId,
      invoiceUserId,
      totalgst,
      purchase_data,
      plandetails,
      // planMethod,
      transactionAmount,
      paymentDiscount,
      transactionId,
      InvoiceDate,
    } = this.state
    // console.log(createInvoices)
    const { type } = user_details
    // if (!invoiceUserId) {
    //   return showSnackbar('Please enter invoiceUser name')
    // }
    if (!transactionId) {
      return showSnackbar('Please enter Transaction ID')
    }
    if (!paymentMethod) {
      return showSnackbar('Please enter paymentMethod id')
    }

    createInvoices({
      UID: invoiceUserId,
      BRANCH_ID: invoiceBranch,
      PAYMENTMODE: paymentMethod,
      STATUS: 'TXN_SUCCESS',
      TXNAMOUNT: totalAmount,
      TOTALTAX: totalgst,
      CGST: cgsttamount,
      SGST: sgsttamount,
      DISCOUNT: Discountamount,
      PLANDETAILS: purchase_data,
      SUBTOTATAL: tamount,
      PAID: paidamount,
      TXNID: invoicenumber,
      balance,
      type,
      PLANS: planId,
      plandetails,
      transactionAmount,
      paymentDiscount,
      TXNDATE: InvoiceDate,
    })

    this.setState({
      invoiceUser: '',
      transactionId: '',
      invoicenumber: '',
      invoiceEmail: '',
      Discountamount: '',
      sgsttamount: '',
      cgsttamount: '',
      tamount: '',
      totalgst: '',
      totalAmount: '',
      paidamount: '',
      balance: '',
      paymentMethod: '',
      purchase_data: '',
      invoiceBranch: '',
      planMethod: '',
      planstartdate: '',
      planenddate: '',
      paymentDiscount: '',
      transactionAmount: '',
    })
    return null
  }

  onSelectUser = (value) => {
    const { adminUsers, user_details } = this.props
    const userlist = adminUsers.filter(user => user.branch_id === user_details.branch_id)
    const uid = value
    const selectedUserDetails = userlist.filter(user => uid === user.uid)
    this.setState({
      invoiceUser: value,
      invoicePlans: selectedUserDetails[0].name,
      invoicenumber: selectedUserDetails[0].phone,
      invoiceEmail: selectedUserDetails[0].email,
      invoiceUserId: value,
    })
  }

  headerData() {
    const {
      styles, adminUsers, user_details, branches, allplans,
    } = this.props
    // const { uid, name, phone } = this.props.location.state || {}
    // let member = []
    // if(uid !== undefined) {
    //   member = adminUsers.filter(item => item.uid === uid)
    // }
    // console.log(member)

    const {
      invoiceUser,
      // invoicename,
      invoicenumber,
      invoiceEmail,
      invoiceBranch,
      paymentMethod,
      planstartdate,
      planenddate,
      transactionAmount,
      transactionId,
      planMethod,
      purchase_data,
      paymentDiscount,
      paidamount,
      tamount,
      cgsttamount,
      sgsttamount,
      totalgst,
      totalAmount,
      Discountamount,
      balance,
    } = this.state

    const branchess = branches.filter(user => user.branch_id === branches.branch_id)
    const branchName = branchess.map(item => ({
      label: `${item.name}`,
      value: item.id,
    }))

    const userlist = adminUsers.filter(user => user.branch_id === user_details.branch_id)
    const userName = userlist.map(item => ({
      label: `${item.name}`,
      value: item.uid,
    }))

    const renderDateInputone = (dateProps) => {
      return (
        <div
          className={classNames(styles.fieldInputContainer, styles.dateInputContainer)}
        >
          <input {...dateProps} className={classNames(styles.fieldInput, styles.dateInput)} />
          <Icon
            name={ICONS.CALENDAR}
            color={theme.textSecondary}
            size={13}
            className={styles.inputIcon}
          />
        </div>
      )
    }
    const yesterday = moment().subtract(1, 'day')
    const disablePastDt = (current) => {
      return current.isAfter(yesterday)
    }

    const plansoption = allplans.map(plan => ({
      label: `${plan.name} - ${plan.amount} - ${plan.duration} days`,
      value: plan.id,
      amount: plan.amount,
    }))
    const end_date = planenddate === '' ? '' : dayjs(planenddate).format('DD MMM YYYY')
    const isplanAdded = Object.values(purchase_data).length > 0

    return (
      <div className={styles.totalcontainerone}>
        <div className={styles.container}>
          <div className={styles.cardsContainerHorizontal}>
            <CustomText size="medium" weight="semi_bold" txt2="*" style={{ color: '#fffafa' }}>Create Invoice</CustomText>
            <div className={styles.containerCreateInvoice}>
              <AutoComplete
                defaultLabel="Select User"
                selected={invoiceUser}
                placeholder="Search User.."
                label="Select a user"
                changeKey="invoiceUser"
                options={userName}
                onSelect={this.onSelectUser}
                inputStyles={styles.filterInput}
                inputBtnStyles={styles.filterInputBtn}
                containerStyles={styles.dropDownContainer}
                inputContainerStyles={styles.dropDownInput}
              />
              <CustomInput
                label="Enter Transaction Id"
                txt2="*"
                labelColor="white"
                value={transactionId}
                inputKey="transactionId"
                onChangeText={this.onChangeText}
                className={styles.input}
              />
              <CustomInput
                label="Phone No"
                labelColor="white"
                txt2="*"
                value={invoicenumber}
                disabled="true"
              />
              <CustomInput
                label="Mail Id"
                txt2="*"
                labelColor="white"
                value={invoiceEmail}
                disabled="true"
              />
              <CustomInput
                label="Date"
                labelColor="white"
                txt2="*"
                value={dayjs(new Date()).format('DD-MM-YYYY')}
                disabled="true"
              />
            </div>
          </div>
        </div>
        <br />
        <div className={styles.containerinvoice}>
          <div className={styles.invoiceDetails}>
            <div className={styles.modalContent}>
              <CustomText size="medium" weight="semi_bold" txt2="*" style={{ color: '#fffafa' }}>Add Membership</CustomText>
              <br />
              <AutoComplete
                defaultLabel={invoiceBranch}
                selected={invoiceBranch}
                placeholder="Search Branch.."
                label="Select a Stamin branch"
                changeKey="invoiceBranch"
                options={branchName}
                onSelect={this.onSelect}
                inputStyles={styles.filterInput}
                inputBtnStyles={styles.filterInputBtn}
                containerStyles={styles.dropDownContainer}
                inputContainerStyles={styles.dropDownInput}
              />
              <br />
              <ListDropdown
                label="Select plans"
                txt2="*"
                selected={planMethod}
                onChangeOption={this.onSelectplan}
                options={plansoption}
                labelKey="label"
                valueKey="value"
                changeKey="planMethod"
                placeholder="Select Method"
                className={styles.input}
              />
              <br />
              <CustomText size="small" weight="medium" color="white" txt2="*" className={styles.dateInputLabel}>Validity From</CustomText>
              <div className={styles.sessionTime}>
                <DateTimePicker
                  props={{
                    timeFormat: false,
                    closeOnSelect: true,
                    dateFormat: 'DD MMM YYYY',
                    isValidDate: disablePastDt,
                    value: planstartdate,
                    renderInput: dateProps => renderDateInputone(dateProps),
                    onChange: date => this.onSelectDate(date, null, 'planstartdate'),
                  }}
                />
              </div>
              <br />
              <CustomText size="small" weight="medium" txt2="*" color="white" className={styles.dateInputLabel}>Validity To</CustomText>
              <div className={styles.sessionTime}>
                <CustomInput
                  value={end_date}
                  disabled="true"
                />
              </div>
              <br />
              <CustomInput
                label="Discount %"
                txt2="*"
                labelColor="white"
                value={paymentDiscount}
                inputKey="paymentDiscount"
                onChangeText={this.onChangeText}
                className={styles.input}
                extraStyles={{ paddingLeft: '10px' }}
              />
              <br />
              <CustomInput
                label="Transaction Amount"
                txt2="*"
                value={transactionAmount}
                labelColor="white"
                inputKey="transactionAmount"
                onChangeText={this.onChangeText}
                className={styles.input}
                extraStyles={{ paddingLeft: '10px' }}
              />
              <br />
              <div className={styles.modalFooter}>
                <Button
                  text="add"
                  buttonColor="orange"
                  uppercase
                  labelStyle={styles.createBtn}
                  onPress={this.onCreateMembership}
                />
              </div>
            </div>
          </div>
          <div className={styles.modalContentone}>
            <div className={styles.tableContainor1}>
              <table className={styles.tableStyle}>
                <thead>
                  <tr>
                    <th className={styles.thStyle} style={{ color: '#e91e63' }}>S.No</th>
                    <th className={styles.thStyle} style={{ color: 'white' }}>Membership Name</th>
                    <th className={styles.thStyle} style={{ color: '#ff9800' }}>Validity From</th>
                    <th className={styles.thStyle} style={{ color: '#87cefa' }}>Validity To</th>
                    <th className={styles.thStyle} style={{ color: '#009688', width: '15%' }}>Total Price</th>
                    <th className={styles.thStyle} style={{ color: '#ffd600', width: '15%' }}>Options</th>
                  </tr>
                </thead>
                <tbody>
                  {isplanAdded && (
                    Object.values(purchase_data).map((obj, i) => (
                      <tr>
                        <td style={{ color: 'white' }} className={styles.thStyle}>{i + 1}</td>
                        <td style={{ color: 'white' }} className={styles.thStyle}>{obj.plan_method}</td>
                        <td style={{ color: 'white' }} className={styles.thStyle}>{dayjs(obj.planstart).format('DD-MM-YYYY')}</td>
                        <td style={{ color: 'white', width: '15%' }} className={styles.thStyle}>{dayjs(obj.planend).format('DD-MM-YYYY')}</td>
                        <td style={{ color: 'white', width: '15%' }} className={styles.thStyle}>{obj.transactionAmount}</td>
                        <td style={{ color: 'white', width: '15%' }} className={styles.thStyle}>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className="actionhovertext" data-hover="Edit">
                              <Button
                                iconName="EDIT"
                                iconProps={{ color: COLORS.BLUE, size: 16 }}
                                onPress={() => this.onEditPlan(i)}
                              />
                            </div>
                            <div className="actionhovertext" data-hover="Delite">
                              <Button
                                iconName="DELETE"
                                iconProps={{ color: COLORS.RED, size: 16 }}
                                onPress={() => this.DeletePlan(i)}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <th className={styles.thStyleone} colSpan="4" style={{ color: 'white' }}>
                      Discount( in ₹)
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </th>
                    <td className={styles.thStyle} colSpan="2">
                      <CustomInput
                        disabled="true"
                        value={Discountamount}
                        inputKey="Discount"
                        onChangeText={this.onChangeText}
                        className={styles.inputtable}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className={styles.thStyleone} colSpan="4" style={{ color: 'white' }}>
                      Sub-Total(in ₹)
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </th>
                    <td className={styles.thStyle} colSpan="2" style={{ color: 'orange' }}>
                      {tamount}
                    </td>
                  </tr>
                  <tr>
                    <th className={styles.thStyleone} colSpan="4" style={{ color: 'white' }}>
                      GST ( 18 % )
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </th>
                    <td className={styles.thStyle1} style={{ color: 'white' }}>
                      <CustomInput
                        disabled="true"
                        value={cgsttamount}
                        placeholder="CGST(9%)"
                        inputKey="cgst"
                        onChangeText={this.onChangeText}
                        className={styles.inputtable}
                      />
                    </td>
                    <td className={styles.thStyle1} style={{ color: 'white' }}>
                      <CustomInput
                        disabled="true"
                        value={sgsttamount}
                        placeholder="SGST(9%)"
                        inputKey="sgst"
                        onChangeText={this.onChangeText}
                        className={styles.inputtable}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className={styles.thStyleone} colSpan="4" style={{ color: 'white' }}>
                      Total Tax ( in ₹)
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </th>
                    <td className={styles.thStyle} colSpan="2" style={{ color: 'orange' }}>{totalgst}</td>
                  </tr>
                  <tr>
                    <th className={styles.thStyleone} style={{ color: 'white' }} colSpan="4">
                      Total ( in ₹)
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </th>
                    <td className={styles.thStyle} colSpan="2" style={{ color: 'orange' }}>{totalAmount}</td>
                  </tr>
                  <tr>
                    <th className={styles.thStyleone} colSpan="4" style={{ color: 'white' }}>
                      Paid( in ₹)
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </th>
                    <td className={styles.thStyle} colSpan="2" style={{ color: 'white' }}>
                      <CustomInput
                        value={paidamount}
                        inputKey="paidamount"
                        onChangeText={this.onChangeTextPaid}
                        className={styles.inputtable}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className={styles.thStyleone} colSpan="4" style={{ color: 'white' }}>
                      Balance( in ₹ )
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </th>
                    <td className={styles.thStyle} colSpan="2" style={{ color: 'orange' }}>{balance}</td>
                  </tr>
                  <tr>
                    <th className={styles.thStyleone} style={{ color: 'white' }} colSpan="4">
                      Payment Mode
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </th>
                    <td className={styles.thStyle} colSpan="2">
                      <ListDropdown
                        selected={paymentMethod}
                        onChangeOption={this.onSelect}
                        options={PAYMENT_METHODS}
                        labelKey="label"
                        valueKey="value"
                        changeKey="paymentMethod"
                        placeholder="Select Method"
                        className={styles.inputtable}
                      />
                    </td>
                  </tr>
                </tfoot>
              </table>
              <br />
              <div className={styles.modalFooter}>
                <Button
                  text="Submit Invoice"
                  buttonColor="orange"
                  uppercase
                  labelStyle={styles.createBtn}
                  onPress={this.onCreateInvoice}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {
      styles,
    } = this.props
    return (
      <div className={styles.containerheader}>
        <ScreenHeader
          title="Invoice"
        />
        <hr />
        {this.headerData()}
      </div>
    )
  }
}

const stylesheet = {
  table: {
    width: '100%',
    padding: SPACING.SPACE_20,
  },
  containerheader: {
    padding: '10px',
  },
  container: {
    backgroundColor: '#494949',
    border: '1px solid #ffcc80',
    borderRadius: 12,
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_30}`,
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  containerinvoice: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    borderRadius: 12,
    border: '1px solid #ffcc80',
    backgroundColor: '#494949',
    padding: SPACING.SPACE_20,
    gap: SPACING.SPACE_30,
  },
  totalcontainerone: {
    padding: SPACING.SPACE_20,
  },
  invoiceDetails: {
    width: '25%',
    flexDirection: 'row',
    gap: SPACING.SPACE_20,
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'right',
    marginLeft: '20px',
  },
  tableContainor1: {
    margin: `${SPACING.SPACE_10} 0`,
    padding: SPACING.SPACE_20,
  },
  modalContent: {
    // display: 'flex',
    gap: SPACING.SPACE_10,
    margin: `${SPACING.SPACE_20} 0`,
    border: `1px solid ${theme.orangeLightBg}`,
    padding: SPACING.SPACE_16,
    borderRadius: 12,
  },
  modalContentone: {
    gap: SPACING.SPACE_10,
    margin: `${SPACING.SPACE_20} 0`,
    border: `1px solid ${theme.orangeLightBg}`,
    borderRadius: 12,
    width: '70%',
  },
  dateInputContainer: {
    // position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: theme.orangeLightBg,
    borderRadius: 4,
    width: '100%',
  },
  sessionTime: {
    display: 'flex',
    gap: SPACING.SPACE_20,
    flexDirection: 'column',
  },
  dateInputLabel: {
    marginBottom: 3,
  },
  dateInput: {
    border: `1px solid ${theme.inputBorder}`,
    // color: COLORS.ORANGE,
    // borderColor: theme.orangeLightBg,
    borderRadius: 4,
    backgroundColor: 'transparent',
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_10}`,
    boxSizing: 'border-box',
    // zIndex: '9',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: theme.textSecondary,
      fontWeight: 300,
    },
  },
  inputIcon: {
    position: 'absolute',
    right: 10,
    zIndex: 1,
  },
  tableStyle: {
    borderCollapse: 'collapse',
    width: '100%',
    hight: '100%',
    border: '1px solid #ddd',
  },
  thStyle: {
    border: '1px solid #ddd',
  },
  thStyle1: {
    borderLeft: '1px solid #ddd',
  },
  tdStyle: {
    border: '1px solid #ddd',
  },
  thStyleone: {
    borderCollapse: 'collapse',
    borderBottom: '1px solid #ddd',
    textAlign: 'right',
  },
  thfoot: {
    borderBottom: '1px solid #ddd',
    textAlign: 'right',
  },
  containerCreateInvoice: {
    display: 'flex',
    gap: '30px',
    justifyContent: 'space-evenly',
    margin: '30px',
    flexWrap: 'wrap',
  },
  '@media only screen and (max-width: 940px) ': {
    tableStyle: {
      fontSize: '8px',
    },
    containerinvoice: {
      flexDirection: 'column',
      padding: SPACING.SPACE_5,
    },
    invoiceDetails: {
      width: '100%',
    },
    modalContentone: {
      width: '100%',
    },
    containerCreateInvoice: {
      gap: '20px',
    },
  },
  '@media only screen and (min-width: 940px) ': {
    tableStyle: {
      fontSize: '14px',
      padding: '3%',
    },
    thStyle: {
      padding: '10px',
    },
    thStyle1: {
      padding: '13px',
    },
    table: {
      padding: SPACING.SPACE_20,
    },
  },
}
const mapStateToProps = state => ({
  user_details: state.auth.user_details,
  adminUsers: state.dashboard.adminUsers,
  branches: state.branch.branchesData,
  allplans: state.common.allplans,
  invoiceCreated: state.payments.invoiceCreated,
  isCreatingInvoice: state.payments.isCreatingInvoice,
  createInvoiceErr: state.payments.createInvoiceErr,
  createInvoiceErrMsg: state.payments.createInvoiceErrMsg,
})

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllUsers: () => dispatch(getAllUsers()),
    createInvoices: parms => dispatch(createInvoice(parms)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(stylesheet)(Invoice))
