import { initializeApp } from 'firebase/app'

import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getDatabase } from 'firebase/database'

const firebaseConfig = {
  apiKey: 'AIzaSyCto9GpIlK98ECFSTX3nHK7FBgIh-JpcEI',
  authDomain: 'stamin-98728.firebaseapp.com',
  databaseURL: 'https://stamin-98728-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'stamin-98728',
  storageBucket: 'stamin-98728.appspot.com',
  messagingSenderId: '1084558848441',
  appId: '1:1084558848441:web:138a2b87541f5afcabc668',
}

const app = initializeApp(firebaseConfig)

export default app
export const auth = getAuth(app)
export const db = getFirestore(app)
export const rtdb = getDatabase(app)
