import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTheme } from '../../Theme/ThemeProvider'
import { ASSETS, SPACING } from '../../Theme'
import { parseQuery } from '../../utils/common'
import { UpdateEmailverifaction } from '../../containers/Auth/actions'
import { pathname_mapping } from '../../utils/navigationConstant'

const { SignupBanner, Logo } = ASSETS

class EmailVerification extends Component {
  constructor(props) {
    super(props)
    this.state = {
      timeRemaining: 60,
    }
  }

  componentDidMount() {
    const {
      history, findandUpdateEmail,
    } = this.props
    const {
      location: {
        search,
      },
    } = history
    // const userLogin = user_details?.name === undefined
    const { email = '' } = parseQuery(search)
    const params = {
      email,
      email_verification: true,
    }
    findandUpdateEmail(params)
  }

  startTimer() {
    // const { timeRemaining } = this.state
    this.timer = setInterval(() => {
      const { timeRemaining } = this.state
      this.setState(
        prevState => ({ timeRemaining: prevState.timeRemaining - 1 }),
        () => {
          if (timeRemaining <= 0) {
            this.redirectToNextPage()
          }
        },
      )
    }, 1000)
  }

  redirectToNextPage() {
    const { history } = this.props
    history.push(pathname_mapping.Auth)
  }

  render() {
    const {
      styles, user_details,
    } = this.props
    const userLogin = user_details?.name === undefined

    return(
      <div className={styles.container} style={{ backgroundImage: `url(${SignupBanner})` }}>
        <div className={styles.staminlogo}>
          <img src={Logo} alt="Stamin" className={styles.logo} />
        </div>
        <p style={{
          fontWeight: 'bold',
          fontSize: '20px',
          fontFamily: 'cursive',
          marginLeft: '22%',
        }}
        >
          Welcome To Stamin.
        </p>
        <br />
        <div className={styles.modealcontainer}>
          {this.startTimer()}
          {userLogin ? (
            <p style={{ fontWeight: 'bold', fontSize: '20px', fontFamily: 'cursive' }}>
              Email verfication Done
              <br />
              <br />
              Please goto Login Page...
            </p>
          ) : (
            <p>
              Email verfication Done continue the
            </p>
          )}
        </div>
      </div>
    )
  }
}
const stylesheet = () => ({
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: SPACING.SPACE_10,
    padding: SPACING.SPACE_20,
  },
  container: {
    width: '100%',
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  modealcontainer: {
    justifyContent: 'center',
    border: '5px solid #494949',
    borderRadius: 12,
    padding: '20px',
    backgroundColor: '#708090',
    display: 'flex',
    width: '27vw',
    color: 'white',
    marginLeft: '20%',
  },
  staminlogo: {
    padding: '3%',
  },
})

const mapStateToProps = state => ({
  user_details: state.auth.user_details,
})
const mapDispatchToProps = dispatch => ({
  findandUpdateEmail: parms => dispatch(UpdateEmailverifaction(parms)),
})
export default connect(
  mapStateToProps, mapDispatchToProps,
)(withTheme(stylesheet)(EmailVerification))
