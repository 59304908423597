import React, { Component } from 'react'
import { Media, Player, controls } from 'react-media-player'
import {
  FONTS, FONTWEIGHT, SPACING, theme,
} from '../../Theme'
import { withTheme } from '../../Theme/ThemeProvider'
import CustomText from '../CustomText'
import Mute from './Mute'
import PlayPause from './PlayPause'

const {
  CurrentTime, SeekBar, // Duration, Volume,
} = controls

class MediaPlayer extends Component {
  render() {
    const { url = '', styles, name = '' } = this.props
    return (
      <Media>
        <div className={styles.mediaContainer}>
          <CustomText weight="medium" size="medium">{name}</CustomText>
          <div className={styles.media}>
            <Player
              src={url}
              style={{
                width: '100%',
                height: '100%',
                maxHeight: 400,
                borderRadius: 12,
              }}
            />
          </div>
          <div className={styles.mediaControls}>
            <PlayPause />
            <Mute />
            <CurrentTime className={styles.currentTime} />
            <SeekBar className={styles.seekbar} />
          </div>
        </div>
      </Media>
    )
  }
}

const stylesheet = {
  mediaContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING.SPACE_10,
  },
  media: {
    width: '100%',
    height: '100%',
  },
  mediaControls: {
    display: 'flex',
    alignItems: 'center',
    gap: SPACING.SPACE_10,
  },
  seekbar: {
    width: '100%',
    padding: `0 ${SPACING.SPACE_10}`,
    color: theme.orange,
  },
  currentTime: {
    fontSize: FONTS.MEDIUM,
    fontWeight: FONTWEIGHT.MEDIUM,
  },
}

export default withTheme(stylesheet)(MediaPlayer)
