import { PLANS_COLOR_MAP } from '../../components/Plans/Plans-Utils'
import {
  GET_PLANS, GET_PLANS_FAILURE, GET_PLANS_SUCCESS,
} from './actionTypes'

const initialState = {
  isFetchingPlans: false,
  plans: [],
  allplans: [],
  plansError: false,
  plansErrorMsg: '',
}

const serverTicks = (state = initialState, action) => {
  switch (action.type) {
    case GET_PLANS:
      return {
        ...state,
        isFetchingPlans: true,
      }

    case GET_PLANS_SUCCESS: {
      const { data } = action
      const allplans = data
      const plans = data.map((item) => {
        const randomIndex = Math.floor(Math.random() * PLANS_COLOR_MAP.length) - 1
        const { color, bgColor } = PLANS_COLOR_MAP[randomIndex] || PLANS_COLOR_MAP[0]
        return ({
          ...item,
          color,
          bgColor,
        })
      }).reduce(
        (obj, item) => Object.assign(obj, { [item.plan_id]: item }), {},
      )

      return {
        ...state,
        isFetchingPlans: false,
        plans,
        allplans,
      }
    }

    case GET_PLANS_FAILURE:
      return {
        ...state,
        isFetchingPlans: false,
        plansError: true,
        plansErrorMsg: action.error,
      }

    default:
      return state
  }
}

export default serverTicks
