import React, { Component } from 'react'
import Lottie from 'react-lottie'

import { connect } from 'react-redux'
import { isEqual } from 'lodash'
import dayjs from 'dayjs'
import {
  ASSETS,
  SPACING, theme,
} from '../../Theme'
import { withTheme } from '../../Theme/ThemeProvider'
import CustomText from '../../UI/CustomText'
// import ScreenHeader from '../../UI/ScreenHeader'
import { deleteCookies, getCookies, parseQuery } from '../../utils/common'
import { updateInvoice } from '../../containers/Payments/actions'
import { showSnackbar } from '../../UI/Snackbar'

// http://localhost:3000/billing?status=txn_success&orderId=fa6f2b16-b902-4331-92a3-153fb4fee139

const { SUCCESS_LOTTIE, ERROR_LOTTIE } = ASSETS

class Billing extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount() {
    const { pId = '' } = getCookies()
    const {
      user_details, updateUserInvoice, history, plans,
    } = this.props
    const {
      location: {
        search,
      },
    } = history
    const { uid, type, branch_id } = user_details
    const { orderId = '', status = '' } = parseQuery(search)

    if (pId && type === 'member') {
      const plan_id = atob(pId)
      const plan = plan_id.split(':')

      const plan_instance = Object.values(plans)
        .filter(p => plan.includes(p.id))
        .map(p => ({ ...p, plan_start_date: new Date().valueOf(), plan_end_date: dayjs(new Date()).add(p.duration, 'day').valueOf() }))
      console.log(plan_instance)
      const InvoiceDate = new Date()
      const purchase_data = []
      let tamount = 0
      Object.values(plan_instance).map((obj, inds) => {
        const {
          name, duration, amount,
        } = obj
        const planend = dayjs(new Date()).add(duration, 'day').valueOf()
        purchase_data[inds] = {
          plan_method: name,
          planstart: new Date().valueOf(),
          planend,
          totalDiscount: 0,
          transactionAmount: amount,
        }
        tamount += Number(amount)
        return obj
      })
      const cgsttamount = Number((tamount * 9) / 100).toFixed(2)
      const sgsttamount = Number(cgsttamount)
      const Discountamount = 0
      const totalgst = Number(cgsttamount) + Number(sgsttamount)
      const totalAmount = Number(tamount) + Number(totalgst)
      const purchaseStatus = status.toLowerCase() === 'txn_success' ? 'TXN_SUCCESS' : 'TXN_FAILED'
      const params = {
        UID: uid,
        BRANCH_ID: branch_id,
        PAYMENTMODE: 'UPI',
        order_id: orderId,
        STATUS: purchaseStatus,
        TXNAMOUNT: totalAmount,
        TOTALTAX: totalgst,
        CGST: cgsttamount,
        SGST: sgsttamount,
        DISCOUNT: Discountamount,
        PLANDETAILS: purchase_data,
        SUBTOTATAL: tamount,
        PAID: totalAmount,
        balance: 0,
        type,
        PLANS: plan,
        plandetails: plan_instance,
        TXNDATE: InvoiceDate.valueOf(),
      }
      console.log(params)
      deleteCookies(['pId'])
      updateUserInvoice(params)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isUpdatingInvoice, updateInvoiceErr, updateInvoiceErrMsg,
    } = this.props

    if (!isEqual(prevProps.isUpdatingInvoice, isUpdatingInvoice) && !isUpdatingInvoice) {
      if (updateInvoiceErr) {
        showSnackbar(updateInvoiceErrMsg, {}, 0)
      }
    }
  }

  render() {
    const {
      styles, history,
    } = this.props

    const {
      location: {
        search,
      },
    } = history
    const { status = '', orderId = '' } = parseQuery(search)

    const isSuccess = status.toLowerCase() === 'txn_success'

    const animationData = isSuccess ? SUCCESS_LOTTIE : ERROR_LOTTIE

    const defaultOptions = {
      loop: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }

    return (
      <section className={styles.container}>
        {/* <ScreenHeader
          title="Billing"
        /> */}
        <div className={styles.detailsContainer}>
          <CustomText size="large" weight="semi_bold">Payment Status</CustomText>
          <Lottie
            options={defaultOptions}
            width={240}
          />
          {isSuccess ? (
            <>
              <CustomText color="green" weight="medium" size="medium_1">Your Payment was success</CustomText>
              <CustomText color="green">{`Your order id is ${orderId}`}</CustomText>
            </>
          ) : <CustomText color="red" weight="medium" size="medium_1">Your Payment was failed</CustomText>}
        </div>
      </section>
    )
  }
}

const stylesheet = {
  container: {
    padding: SPACING.SPACE_20,
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING.SPACE_10,
    alignItems: 'center',
    marginTop: SPACING.SPACE_20,
    backgroundColor: theme.bgPrimary,
    border: `1px solid ${theme.orangeLightBg}`,
    borderRadius: 12,
    padding: `${SPACING.SPACE_24} ${SPACING.SPACE_20}`,
    width: '50%',
    margin: '0 auto',
  },
}

const mapStateToProps = state => ({
  user_details: state.auth.user_details,
  isUpdatingInvoice: state.payments.isUpdatingInvoice,
  updateInvoiceErr: state.payments.updateInvoiceErr,
  updateInvoiceErrMsg: state.payments.updateInvoiceErrMsg,
  plans: state.common.plans,
})

const mapDispatchToProps = dispatch => ({
  updateUserInvoice: params => dispatch(updateInvoice(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(stylesheet)(Billing))
