import {
  ASSIGNED_NEW_WORKOUTS, ASSIGNED_NEW_WORKOUTS_SUCCESS, ASSIGNED_NEW_WORKOUTS_FAILURE,
  GET_WORKOUTS, GET_WORKOUTS_SUCCESS, GET_WORKOUTS_FAILURE,
  UPDATE_WORKOUTS, UPDATE_WORKOUTS_SUCCESS, UPDATE_WORKOUTS_FAILURE,
  DEFAULT_WORKOUTS, DEFAULT_WORKOUTS_SUCCESS, DEFAULT_WORKOUTS_FAILURE,
} from './actionTypes'

const initialState = {
  assignedworkoutData: [],
  isAssignWorkouts: false,
  isAssignWorkoutsErr: false,
  isAssignWorkoutsErrMsg: '',
  workoutsassined: false,

  isUpdateWorkouts: false,
  isUpdateWorkoutsErr: false,
  isUpdateWorkoutsErrMsg: '',
  workoutsUpdated: false,

  allworkots: [],
  defaultWorkouts: false,
  isdefaultWorkouts: false,
  isdefaultWorkoutsErr: false,
  isdefaultWorkoutsErrMsg: '',

}

const assignedWorkouts = (state = initialState, action) => {
  switch (action.type) {
    case ASSIGNED_NEW_WORKOUTS:
      return {
        ...state,
        isAssignWorkouts: true,
      }

    case ASSIGNED_NEW_WORKOUTS_SUCCESS: {
      return {
        ...state,
        isAssignWorkouts: false,
        workoutsassined: true,
      }
    }

    case ASSIGNED_NEW_WORKOUTS_FAILURE:
      return {
        ...state,
        isAssignWorkouts: false,
        isAssignWorkoutsErr: true,
        isAssignWorkoutsErrMsg: action.error,
      }

    case UPDATE_WORKOUTS:
      return {
        ...state,
        isUpdateWorkouts: true,
      }

    case UPDATE_WORKOUTS_SUCCESS: {
      return {
        ...state,
        isUpdateWorkouts: false,
        workoutsUpdated: true,
      }
    }

    case UPDATE_WORKOUTS_FAILURE:
      return {
        ...state,
        isUpdateWorkouts: false,
        isUpdateWorkoutsErr: true,
        isUpdateWorkoutsErrMsg: action.error,
      }

    case GET_WORKOUTS:
      return {
        ...state,
        isFetchingUsers: true,
      }

    case GET_WORKOUTS_SUCCESS: {
      const { data } = action
      const allworkots = data
      return {
        ...state,
        isFetchingUsers: false,
        allworkots,
      }
    }

    case GET_WORKOUTS_FAILURE:
      return {
        ...state,
        isFetchingUsers: false,
        fetchUsersErr: true,
        fetchUsersErrMsg: action.error,
      }

    case DEFAULT_WORKOUTS:
      return {
        ...state,
        isdefaultWorkouts: true,
      }

    case DEFAULT_WORKOUTS_SUCCESS: {
      // const { data } = action
      // const allworkots = data
      return {
        ...state,
        isdefaultWorkouts: false,
      }
    }

    case DEFAULT_WORKOUTS_FAILURE:
      return {
        ...state,
        isdefaultWorkouts: false,
        isdefaultWorkoutsErr: true,
        isdefaultWorkoutsErrMsg: action.error,
      }

    default:
      return state
  }
}
export default assignedWorkouts
