import {
  GET_BRANCHES, GET_BRANCHES_FAILURE, GET_BRANCHES_SUCCESS,
  ADD_BRANCH, ADD_BRANCH_FAILURE, ADD_BRANCH_SUCCESS,
} from './actionTypes'

const initialState = {

  isFetchingBranches: false,
  branches: [],
  branchesData: [],
  branchesError: false,
  branchesErrorMsg: '',

  isAddingBranch: false,
  addBranchError: false,
  addBranchErrorMsg: '',
}

const serverTicks = (state = initialState, action) => {
  switch (action.type) {
    case GET_BRANCHES:
      return {
        ...state,
        isFetchingBranches: true,
      }

    case GET_BRANCHES_SUCCESS: {
      const { data } = action
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords
        data.sort((a, b) => {
          const aDistance = Math.sqrt(
            (a.lat - latitude) ** 2 + (a.lon - longitude) ** 2,
          )
          const bDistance = Math.sqrt(
            (b.lat - latitude) ** 2 + (b.lon - longitude) ** 2,
          )
          return aDistance - bDistance
        })
      })

      const branches = data.map((item, index) => {
        let label = `${item.name}, ${item.address}, ${item.zip}`
        if (index === 0) {
          label = `${label} (Nearest)`
        }
        return ({
          label,
          value: item.id,
        })
      })

      return {
        ...state,
        isFetchingBranches: false,
        branchesData: data,
        branches,
      }
    }

    case GET_BRANCHES_FAILURE:
      return {
        ...state,
        isFetchingBranches: false,
        branchesError: true,
        branchesErrorMsg: action.error,
      }

    case ADD_BRANCH:
      return {
        ...state,
        isAddingBranch: true,
      }

    case ADD_BRANCH_SUCCESS:
      return {
        ...state,
        isAddingBranch: false,
      }

    case ADD_BRANCH_FAILURE:
      return {
        ...state,
        isAddingBranch: false,
        addBranchError: true,
        addBranchErrorMsg: action.error,
      }

    default:
      return state
  }
}

export default serverTicks
