import { isEmpty, isEqual } from 'lodash'
import React, { Component } from 'react'

import { withTheme } from '../../Theme/ThemeProvider'

class Common extends Component {
  constructor(props) {
    super(props)

    this.state = {
      app_state: 'active',
    }
  }

  componentDidMount() {
    const {
      getPlans, getBranches,
    } = this.props
    getPlans()
    getBranches()
  }

  componentDidUpdate(prevProps) {
    const { user_details, getAllUsers } = this.props

    if (!isEqual(prevProps.user_details, user_details) && !isEmpty(user_details)) {
      const { type } = user_details
      if(type === 'admin' || type === 'branch_admin') {
        getAllUsers({ type })
      }
    }
  }

  componentWillUnmount = () => {

  }

  initializeApp = async () => {

  }

  render() {
    return (
      <div />
    )
  }
}

export default withTheme({})(Common)
