import { WORKOUTS } from '../../utils/consts'
import {
  ADD_VIDEO, ADD_VIDEO_FAILURE, ADD_VIDEO_SUCCESS,
  GET_VIDEOS, GET_VIDEOS_FAILURE, GET_VIDEOS_SUCCESS,
  EDIT_VIDEO, EDIT_VIDEO_FAILURE, EDIT_VIDEO_SUCCESS,
  DELETE_VIDEO, DELETE_VIDEO_SUCCESS, DELETE_VIDEO_FAILURE,
} from './actionTypes'

const initialState = {
  AllVideos: {},

  isAddingVideo: false,
  videoAdded: false,
  addingVideoErr: false,
  addingVideoErrMsg: '',

  isEditVideo: false,
  videoEdited: false,
  editVideoErr: false,
  editVideoErrMsg: '',

  isDeleteVideo: false,
  videoDeleted: false,
  deleteVideoErr: false,
  deleteVideoErrMsg: '',

  isFetchingVideos: false,
  videos: {},
  fetchingVideosErr: false,
  fetchingVideosErrMsg: '',

}

const videos = (state = initialState, action) => {
  switch (action.type) {
    case ADD_VIDEO: {
      return {
        ...state,
        isAddingVideo: true,
      }
    }
    case ADD_VIDEO_SUCCESS: {
      return {
        ...state,
        isAddingVideo: false,
        videoAdded: true,
      }
    }
    case ADD_VIDEO_FAILURE: {
      return {
        ...state,
        isAddingVideo: false,
        addingVideoErr: true,
        addingVideoErrMsg: action.error,
      }
    }

    case EDIT_VIDEO: {
      return {
        ...state,
        isEditVideo: true,
        videoEdited: false,
      }
    }
    case EDIT_VIDEO_SUCCESS: {
      return {
        ...state,
        isEditVideo: false,
        videoEdited: true,
      }
    }
    case EDIT_VIDEO_FAILURE: {
      return {
        ...state,
        isEditVideo: false,
        editVideoErr: true,
        editVideoErrMsg: action.error,
      }
    }

    case DELETE_VIDEO: {
      return {
        ...state,
        isDeleteVideo: true,
      }
    }
    case DELETE_VIDEO_SUCCESS: {
      return {
        ...state,
        isDeleteVideo: false,
        videoDeleted: true,
      }
    }
    case DELETE_VIDEO_FAILURE: {
      return {
        ...state,
        isDeleteVideo: false,
        deleteVideoErr: true,
        deleteVideoErrMsg: action.error,
      }
    }

    case GET_VIDEOS: {
      return {
        ...state,
        isFetchingVideos: true,
      }
    }

    case GET_VIDEOS_SUCCESS: {
      const { data } = action
      //  allVideosData = data
      const allVideos = {
        all: data,
      }
      const videodb = data

      WORKOUTS.map(workout => (
        Object.assign(allVideos,
          { [workout.value]: data.filter(video => video.workouts.includes(workout.value)) })
      ))

      return {
        ...state,
        isFetchingVideos: false,
        videos: allVideos,
        AllVideos: videodb,
      }
    }

    case GET_VIDEOS_FAILURE: {
      return {
        ...state,
        isFetchingVideos: false,
        fetchingVideosErr: true,
        fetchingVideosErrMsg: action.error,
      }
    }

    default: {
      return state
    }
  }
}

export default videos
