import dayjs from 'dayjs'
import {
  doc, setDoc, getDocs, query, collection, where, deleteDoc, updateDoc, getDoc,
} from 'firebase/firestore'
import { call, put, takeLatest } from 'redux-saga/effects'
// import { v4 as uuid } from 'uuid'
import { error_msg } from '../../utils/consts'
import { db } from '../../utils/firebaseConfig'
import {
  bookSessionFailure, bookSessionSuccess,
  getSessionsFailure, getSessionsSuccess,
  deleteSessionFailure, deleteSessionSuccess,
  getSessionDetailsFailure, getSessionDetailsSuccess,
  archiveSessionFailure, archiveSessionSuccess,
  startSessionFailure, startSessionSuccess,
} from './actions'
import {
  BOOK_SESSION, GET_SESSIONS, DELETE_SESSION,
  GET_SESSION_DETAILS, ARCHIVE_SESSION, START_SESSION,
} from './actionTypes'

function* bookSession(action) {
  try {
    const { params, updateKey, creatsessionkey } = action
    const sessionUuid = updateKey || creatsessionkey
    let data = null
    // const uid = params.uid
    // const order = yield call(getDoc, doc(db, 'users', uid))
    // const sessionCount = params.session_count
    // delete params.session_count
    if (updateKey) {
      const olddata = yield call(getDoc, doc(db, 'sessions', updateKey))
      const oldworkout = olddata.data().workout
      if(oldworkout === params.workout) {
        data = yield call(updateDoc, doc(db, 'sessions', updateKey), {
          ...params,
          session_id: updateKey,
        })
      } else {
        /* let totalsession = order.data().totalsession
        console.log(oldworkout)
        totalsession = Object.values(totalsession).map(obj => {
          console.log(obj.WorkoutsName, oldworkout, params.workout)
          if (oldworkout === obj.WorkoutsName) {
            obj = { ...obj, workcount: obj.workcount + 1 }
            console.log(obj)
            return obj
          }
          if(obj.WorkoutsName === params.workout) {
            obj = { ...obj, workcount: obj.workcount - 1 }
            return obj
          }
          return obj
        })
        console.log(totalsession) */
        data = yield call(updateDoc, doc(db, 'sessions', updateKey), {
          ...params,
          session_id: updateKey,
        })
        // yield call(updateDoc, doc(db, 'users', uid), { totalsession })
      }
    } else {
      /* const sessioncount = order.data().sessioncount - 1
      let totalsession = order.data().totalsession
      totalsession = Object.values(totalsession).map(obj => {
        if(obj.WorkoutsName === params.workout) {
          obj = { ...obj, workcount: obj.workcount - 1 }
          return obj
        }
        return obj
      }) */
      yield
      data = yield call(setDoc, doc(db, 'sessions', sessionUuid), {
        ...params,
        session_id: sessionUuid,
        status: 0,
      })
      // yield call(updateDoc, doc(db, 'users', uid), { sessioncount, totalsession })
    }

    if (data && data.error) {
      yield put(bookSessionFailure(data))
    } else {
      yield put(bookSessionSuccess(data))
    }
  } catch (err) {
    yield put(bookSessionFailure(err.message || error_msg))
  }
}

export function* bookSessionSaga() {
  yield takeLatest(BOOK_SESSION, bookSession)
}

function* getSessions(actions) {
  try {
    const { params = {}, userType } = actions
    const { uid = '', branch_id = '' } = params
    let q = query(collection(db, 'sessions'), where('uid', '==', uid))

    if (userType === 'branch_admin') {
      q = query(collection(db, 'sessions'), where('branch_id', '==', branch_id))
    } else if (userType === 'trainer') {
      q = query(collection(db, 'sessions'), where('trainer', '==', uid))
    } else if (userType === 'admin') {
      q = query(collection(db, 'sessions'))
    }

    const data = yield call(getDocs, q)
    const sessions = []
    data.forEach(res => sessions.push(res.data()))

    if (!sessions.error) {
      yield put(getSessionsSuccess(sessions, userType))
    } else {
      yield put(getSessionsFailure(data))
    }
  } catch (err) {
    yield put(getSessionsFailure(err.message || error_msg))
  }
}

export function* getSessionsSaga() {
  yield takeLatest(GET_SESSIONS, getSessions)
}

function* deleteSession(action) {
  try {
    const { params } = action
    /* let data = ''
    const olddata = yield call(getDoc, doc(db, 'sessions', params.session_id))
    const order = yield call(getDoc, doc(db, 'users', params.uid))
    let { totalsession, sessioncount } = order.data()
    const workout = olddata.data().workout
    console.log(totalsession, sessioncount, workout)
    totalsession = Object.values(totalsession).map(obj => {
      if(obj.WorkoutsName === workout) {
        obj = { ...obj, workcount: obj.workcount + 1 }
        console.log(obj)
        return obj
      }
      return obj
    })
    sessioncount = parseInt(sessioncount, 10) + 1
    console.log(totalsession, sessioncount, workout)
    yield call(updateDoc, doc(db, 'users', params.uid), { sessioncount, totalsession }) */
    const data = yield call(deleteDoc, doc(db, 'sessions', params.session_id))
    if (data && data.error) {
      yield put(deleteSessionFailure(data))
    } else {
      yield put(deleteSessionSuccess(data))
    }
  } catch (err) {
    yield put(deleteSessionFailure(err.message || error_msg))
  }
}

export function* deleteSessionSaga() {
  yield takeLatest(DELETE_SESSION, deleteSession)
}

function* getSessionDetails(action) {
  try {
    const { params } = action
    const data = yield call(getDoc, doc(db, 'sessions', params.session_id))

    if (data && data.error) {
      yield put(getSessionDetailsFailure(data))
    } else {
      yield put(getSessionDetailsSuccess(data.data()))
    }
  } catch (err) {
    yield put(getSessionDetailsFailure(err.message || error_msg))
  }
}

export function* getSessionDetailsSaga() {
  yield takeLatest(GET_SESSION_DETAILS, getSessionDetails)
}

function* archiveSession(action) {
  try {
    const { params } = action
    const { session_id, userType } = params
    const data = yield call(updateDoc, doc(db, 'sessions', session_id), {
      is_archived: true,
    })

    if (data && data.error) {
      yield put(archiveSessionFailure(data))
    } else {
      yield put(archiveSessionSuccess(data))
      yield put({ type: GET_SESSIONS, userType })
    }
  } catch (err) {
    yield put(archiveSessionFailure(err.message || error_msg))
  }
}

export function* archiveSessionSaga() {
  yield takeLatest(ARCHIVE_SESSION, archiveSession)
}

function* startSession(action) {
  try {
    const { params } = action
    const { uid, session_id } = params
    yield call(updateDoc, doc(db, 'users', uid), {
      last_session_started: dayjs().unix(),
    })

    const data = yield call(updateDoc, doc(db, 'sessions', session_id), {
      status: 1,
    })

    yield put(startSessionSuccess(data))
  } catch (err) {
    yield put(startSessionFailure(err.message || error_msg))
  }
}

export function* startSessionSaga() {
  yield takeLatest(START_SESSION, startSession)
}
