import React, { useState } from 'react'
import JsPdf from 'jspdf'
// import dayjs from 'dayjs'
import Button from '../../UI/Button'
import { withTheme } from '../../Theme/ThemeProvider'
import {
  ASSETS, SPACING,
} from '../../Theme'
import CustomText from '../../UI/CustomText'

const { StaminLogo } = ASSETS

function InvoiceTable({
  branchdata,
  styles,
}) {
  const [users, setuser] = useState([])
  console.log(users)
  const [member, setmember] = useState(false)

  const openMemberDetails = (user) => {
    setmember(true)
    setuser(user)
  }

  const closeMemberDetails = () => {
    setmember(false)
  }

  // const itemsPerPage = 10
  // const [currentPage, setCurrentPage] = useState(1)
  // const startIndex = (currentPage - 1) * itemsPerPage
  // const endIndex = startIndex + itemsPerPage
  // const dataToShow = Object.values(branchdata).slice(startIndex, endIndex)
  // const remainingMembers = Object.values(branchdata).length - endIndex

  // console.log(openMemberDetails)

  const downloadInvoice = (obj) => {
    console.log(obj)
    const {
      username,
      phone,
      date,
      transactionId,
      totalgst,
      discount,
      amount,
      TotalAmount,
      name,
    } = obj
    const pdf = new JsPdf('p', 'px', 'a4', false)
    pdf.line(20, 10, 425, 10)
    pdf.line(20, 10, 20, 620)
    pdf.addImage(StaminLogo, 'png', 150, -30, 140, 150)
    pdf.line(20, 85, 425, 85)
    pdf.setFontSize(13)
    pdf.setTextColor(0, 51, 0)
    pdf.setFontSize(13)
    pdf.text('TAX INVOICE', 188, 100)
    pdf.setFontSize(12)
    pdf.setFontSize(16)
    pdf.setTextColor(0, 0, 0)
    pdf.setFontSize(14)
    pdf.text('STAMIN', 220, 113, 'center')
    pdf.setFontSize(12)
    pdf.text('address', 220, 125, 'center')
    // pdf.text('Phone', 190,110, 'center')
    // pdf.text('jointext', 220, 130, 'center')
    // pdf.text(`GST No : ${gst_number}`, 220, 145, 'center')
    pdf.setFontSize(14)
    pdf.setFontSize(12)
    pdf.line(20, 155, 425, 155)
    // pdf.text(`Invoice No  : ${ondenum}`, 297, 170)
    pdf.text(`Date  :  ${date}`, 297, 186)
    pdf.text(`Name  :  ${username}`, 44, 170)
    pdf.text(`phone  : ${phone}`, 44, 186)
    pdf.setFontSize(15)
    pdf.line(20, 195, 425, 195)
    pdf.setFontSize(11)
    pdf.text('S.No ', 24, 210)
    pdf.line(45, 195, 45, 280)
    pdf.line(20, 217, 425, 217)
    pdf.text('Plan Description ', 110, 210)
    pdf.line(230, 195, 230, 340)
    pdf.text('Validity From', 240, 210)
    pdf.line(295, 195, 295, 280)
    pdf.text('Validity To', 310, 210)
    pdf.text('Net Amount ', 370, 210)
    pdf.line(365, 195, 365, 340)
    pdf.setFontSize(15)
    let y = 235
    // for(let i = 0; i < Object.values(branchdata).length; i++) {
    //   const {
    //     name,
    //   } = branchdata[i]
    //   console.log(name)
    pdf.setFontSize(10)
    pdf.text((1).toLocaleString(), 30, y)
    pdf.setTextColor(102, 0, 85)
    pdf.text(name, 130, y)
    pdf.setTextColor(0, 0, 0)
    pdf.text(amount, 380, y)
    //   pdf.text(dayjs(planstart).format('DD-MM-YYYY'), 250, y)
    //   pdf.text(dayjs(planend).format('DD-MM-YYYY'), 310, y)
    y += 23
    // }
    pdf.setFontSize(11)
    pdf.setTextColor(0, 64, 77)
    pdf.setFontSize(10)
    pdf.text('GST 18 %', 322, 309)
    pdf.setFontSize(8)
    pdf.text('(CGST 9 + SGST 9)', 308, 317)
    pdf.setFontSize(11)
    pdf.line(20, 300, 425, 300)
    pdf.text(`${totalgst}`, 380, 314)
    pdf.setTextColor(0, 0, 0)
    pdf.setFontSize(11)
    pdf.text('Discount ', 315, 275)
    pdf.text(`${discount}.00`, 380, 275)
    pdf.text('Net Total', 315, 292)
    pdf.text(`${TotalAmount}`, 380, 292)
    pdf.setFontSize(12)
    pdf.text('Total in (Rs)', 315, 335)
    pdf.text(`${TotalAmount}`, 380, 335)
    // pdf.getFontSize(20, 20, 20, 20)
    pdf.line(20, 280, 425, 280)
    pdf.line(20, 320, 425, 320)
    pdf.line(20, 340, 425, 340)
    pdf.setTextColor(128, 21, 0)
    pdf.setTextColor(0, 0, 0)
    pdf.text('Payment Mode : UPI', 30, 375)
    pdf.text(`Tranacation Id  : ${transactionId}`, 30, 390)
    pdf.text('Pament Status  : STATUS', 30, 405)
    pdf.setFontSize(15)
    pdf.text('Powered by STAMIN', 280, 600)
    pdf.line(20, 415, 425, 415)
    pdf.setFontSize(13)
    pdf.text('Terms and Conditions:', 30, 433)
    pdf.setFontSize(11)
    pdf.setTextColor(51, 0, 77)
    pdf.text('We declare that this Invoice Shows The Actual Price Of The Goods', 30, 448)
    pdf.text('Described And That All Particulars Are True And', 30, 460)
    pdf.setFontSize(15)
    pdf.setTextColor(0, 0, 0)
    pdf.line(20, 475, 425, 475)
    pdf.line(20, 620, 425, 620)
    pdf.line(425, 10, 425, 620)
    pdf.save(`Invoice_${username}_.pdf`)
  }
  return (
    <div>
      <div>
        {member ? (
          <div className={styles.container}>
            <div className={styles.closedbutton}>
              <Button
                iconName="CLOSE"
                iconProps={{ size: 18, color: '#fc7124' }}
                // params={row.original}
                onPress={() => closeMemberDetails()}
              />
            </div>
            {/* <div>
              <div className={styles.tablecontainerview}>
                <div className={styles.labeldata}>
                  <span style={{ fontWeight: 'bold' }} className={styles.header}>Name</span>
                  <span className={styles.label}>{users.username}</span>
                </div>
                <div className={styles.labeldata}>
                  <span style={{ fontWeight: 'bold' }} className={styles.header}>Phone</span>
                  <span className={styles.label}>{users.phone}</span>
                </div>
                <div className={styles.labeldata}>
                  <span style={{ fontWeight: 'bold' }} className={styles.header}>Email</span>
                  <span className={styles.label}>{users.mail}</span>
                </div>
                <div className={styles.labeldata}>
                  <span style={{ fontWeight: 'bold' }} className={styles.header}>Invoice Date</span>
                  <span className={styles.label}>{users.date}</span>
                </div>
                <div className={styles.labeldata}>
                  <span style={{ fontWeight: 'bold' }} className={styles.header}>Plan Name</span>
                  <span className={styles.label}>{users.name}</span>
                </div>
                <div className={styles.labeldata}>
                  <span style={{ fontWeight: 'bold' }}
                  className={styles.header}>Validity From</span>
                  <span className={styles.label}>02.10.2023</span>
                </div>
                <div className={styles.labeldata}>
                  <span style={{ fontWeight: 'bold' }} className={styles.header}>Validity To</span>
                  <span className={styles.label}>02.11.2023</span>
                </div>
                <div className={styles.labeldata}>
                  <span style={{ fontWeight: 'bold' }} className={styles.header}>CGST</span>
                  <span className={styles.label}>{users.cgsttamount}</span>
                </div>
                <div className={styles.labeldata}>
                  <span style={{ fontWeight: 'bold' }} className={styles.header}>SGST</span>
                  <span className={styles.label}>{users.sgsttamount}</span>
                </div>
                <div className={styles.labeldata}>
                  <span style={{ fontWeight: 'bold' }} className={styles.header}>TOTAL TAX</span>
                  <span className={styles.label}>{users.totalgst}</span>
                </div>
                <div className={styles.labeldata}>
                  <span style={{ fontWeight: 'bold' }} className={styles.header}>Discount</span>
                  <span className={styles.label}>{users.discount}</span>
                </div>
                <div className={styles.labeldata}>
                  <span style={{ fontWeight: 'bold' }} className={styles.header}>Total Amount</span>
                  <span className={styles.label}>{users.TotalAmount}</span>
                </div>
              </div>
            </div> */}
            <div>
              <div className={styles.maincontainerone}>
                <div className={styles.subtablecontainer}>
                  <table className={styles.tableContainor1}>
                    <thead>
                      <tr className={styles.tabl}>
                        <th className={styles.thStyle} style={{ color: 'white' }}>Name</th>
                        <th className={styles.thStyle} style={{ color: 'white' }}>Plan</th>
                        <th className={styles.thStyle} style={{ color: 'white' }}>Validity From</th>
                        <th className={styles.thStyle} style={{ color: 'white' }}>Validity To</th>
                        <th className={styles.thStyle} style={{ color: 'white' }}>Plan Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <td className={styles.thStyle}>
                        <span style={{ fontWeight: 'bold' }} className={styles.label}>{users.username}</span>
                      </td>
                      <td className={styles.thStyle}>
                        <div>
                          <span className={styles.label}>{users.name}</span>
                        </div>
                      </td>
                      <td className={styles.thStyle}>
                        <div>
                          <span className={styles.label}>02.10.2023</span>
                        </div>
                      </td>
                      <td className={styles.thStyle}>
                        <div>
                          <span className={styles.label}>02.10.2023</span>
                        </div>
                      </td>
                      <td className={styles.thStyle}>
                        <div>
                          <span style={{ color: 'orange', fontWeight: 'bold' }} className={styles.label}>{users.amount}</span>
                        </div>
                      </td>
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <div className={styles.paymenthistory}>
                  <CustomText size="medium" className={styles.paymenttext1} color="white" weight="semi_bold">
                    Payment Summary
                  </CustomText>
                  <br />
                  <div className={styles.paymenttable}>
                    <table className={styles.tableContainor}>
                      <thead>
                        <tr className={styles.tabl}>
                          <th className={styles.thStyle} style={{ color: 'white' }}>Sub Total</th>
                          <td className={styles.thStyle1} style={{ color: 'white' }}>{users.amount}</td>
                        </tr>
                        <tr>
                          <th className={styles.thStyle} style={{ color: 'white' }}>Total Tax</th>
                          <td className={styles.thStyle1} style={{ color: 'white' }}>{users.totalgst}</td>
                        </tr>
                        <tr>
                          <th className={styles.thStyle} style={{ color: 'white' }}>Total Amount</th>
                          <td className={styles.thStyle1} style={{ color: 'orange', fontWeight: 'bold' }}>{users.TotalAmount}</td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.tablecontainer}>
            <table className={styles.expiredtable} style={{ textAlign: 'center' }}>
              <thead className={styles.headhover}>
                <tr>
                  <th className={styles.tableheads}>Sno</th>
                  <th className={styles.tableheads}>Branch Name</th>
                  <th className={styles.tableheads}>Date</th>
                  <th className={styles.tableheads}>Plan Name</th>
                  <th className={styles.tableheads}>Validity From</th>
                  <th className={styles.tableheads}>Validity To</th>
                  <th className={styles.tableheads}>GST</th>
                  <th className={styles.tableheads}>Amount</th>
                  <th className={styles.tableheads}>Invoice</th>
                  <th className={styles.tableheads}>View details</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(branchdata).map((obj, i) => (
                  <tr className={styles.tabledatahover}>
                    <td style={{ color: 'white', marginLeft: '1rem' }} className={styles.tabletd}>{i + 1}</td>
                    <td style={{ color: 'white' }} className={styles.tabletd}>{obj.username}</td>
                    <td style={{ color: 'white' }} className={styles.tabletd}>{obj.date}</td>
                    <td style={{ color: 'white' }} className={styles.tabletd}>{obj.name}</td>
                    <td style={{ color: 'white' }} className={styles.tabletd}>05.10.2023</td>
                    <td style={{ color: 'white' }} className={styles.tabletd}>05.10.2023</td>
                    <td style={{ color: 'white' }} className={styles.tabletd}>{obj.totalgst}</td>
                    <td style={{ color: 'white' }} className={styles.tabletd}>{obj.TotalAmount}</td>
                    <td style={{ color: 'white' }} className={styles.tabletd}>
                      <div className={styles.Icon}>
                        <Button
                          iconName="DOWNLOAD_CLOUD"
                          iconProps={{ size: 18, color: 'white' }}
                          onPress={() => downloadInvoice(obj)}
                        />
                      </div>
                    </td>
                    <td style={{ color: 'white' }} className={styles.tabletd}>
                      <div className={styles.Icon}>
                        <Button
                          iconName="VIEW"
                          iconProps={{ size: 18, color: 'white' }}
                          onPress={() => openMemberDetails(obj)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {/* <div>
        <Button
          iconName="FORWARD"
          buttonColor="orange"
          onClick={() => setCurrentPage(currentPage + 1)}
          type="button"
          className={styles.buttonnext}
          disabled={endIndex >= Object.values(branchdata).length}
        />
        {currentPage && (
          <span
            className={styles.activePage}
            style={{
              gap: '10px', width: '15px', height: '15px', textAlign: 'center', padding: '0.8%',
            }}
          >
            {currentPage}
          </span>
        )}
         <Button
          iconName="BACK"
          buttonColor="orange"
          className={styles.buttonhover}
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        />
      </div> */}
    </div>
  )
}
const stylesheet = {
  tablecontainer: {
    fontSize: '13px',
    borderRadius: 12,
    padding: '5px',
  },
  expiredtable: {
    width: '100%',
    borderCollapse: 'collapse',
    borderRadius: 12,
    // padding: '2%',
  },
  tableheads: {
    // padding: '5rem',
    padding: '1rem',
    color: '#fc7300',
    fontSize: '16px',
    '&:hover': {
      backgroundColor: '#303030',
      borderRadius: '5px',
    },
  },
  // activePage: {
  //   backgroundColor: 'white',
  //   padding: '2%',
  //   border: '1px solid black',
  //   borderRadius: 12,
  //   fontWeight: 'bold',
  // },
  // buttonhover: {
  //   // backgroundColor: '#FC7300',
  //   border: '1px solid black',
  //   borderRadius: 8,
  //   '&:hover': {
  //     backgroundColor: '#8b4513',
  //   },
  // },
  maincontainerone: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  subtablecontainer: {
    backgroundColor: '#303030',
    border: '1px solid white',
    padding: '2%',
    borderRadius: '8px',
    width: '100%',
  },
  tableContainor1: {
    width: '100%',
  },
  thStyle: {
    // borderTop: '1px solid #dddd',
    textAlign: 'center',
    padding: SPACING.SPACE_8,
  },
  paymenttable: {
    backgroundColor: '#303030',
    padding: '2%',
    borderRadius: '8px',
    border: '1px solid white',
  },
  tableContainor: {
    width: '100%',
  },
  paymenthistory: {
    marginTop: '2%',
    width: '50%',
    marginLeft: '47%',
    marginBottom: '3rem',
  },
  thStyle1: {
    width: '30rem',
    textAlign: 'center',
  },
  headhover: {
    borderBottom: '1px solid #393939',
  },
  tabledatahover: {
    '&:hover': {
      backgroundColor: '#303030',
    },
  },
  tabletd: {
    fontSize: '15px',
    fontWeight: 'bold',
  },
  paymenttext: {
    backgroundColor: '#FC7124',
    width: '12rem',
    height: '2rem',
    borderRadius: '2rem',
    textAlign: 'center',
    position: 'relative',
    // top: '-2rem',
    marginTop: '-2rem',
    marginBottom: '1rem',
  },
  paymenttext1: {
    padding: '0.3rem',
  },
  closedbutton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-1%',
    marginRight: '2%',
  },
  tablecontainerview: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  labeldata: {
    display: 'flex',
    flexDirection: 'column',
    color: '#deb887',
    fontSize: '1rem',
    marginLeft: '5.8rem',
    marginBottom: '2rem',
    width: '7rem',
  },
  label: {
    marginTop: '0.5rem',
    color: 'white',
  },
}

export default (withTheme(stylesheet)(InvoiceTable))
