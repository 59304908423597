import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { lowerCase } from 'lodash'
import ListDropdown from '../../UI/Dropdown/ListDropdown'
import { withTheme } from '../../Theme/ThemeProvider'
import CustomInput from '../../UI/CustomInput'
import Button from '../../UI/Button'
import {
  SPACING,
} from '../../Theme'
import { defaultWorkout } from '../../containers/AssigneWorkouts/actions'
import { showSnackbar } from '../../UI/Snackbar'

function Planworkouts({
  planid, plans, styles, videos,
  assingWorkotsd, defaultWorkouts, isdefaultWorkoutsErrMsg, isdefaultWorkoutsErr, isdefaultWorkouts,
}) {
  const initialState = {
    workotop: {
      0: {
        label: '',
        value: '',
      },
    },
    schedule: {
      0: {
        Workout: '',
        Comments: '',
        WorkoutVideo: '',
      },
    },
    videoop: {
      0: {
        label: '',
        value: '',
      },
    },
  }

  const [workotop, setWorkoutop] = useState([])
  const [videoop, setvideoop] = useState([])
  const [isupdate, setUpdate] = useState(false)
  const [state, setState] = useState(initialState)

  useEffect(() => {
    setState(initialState)
  }, [])

  // const ida = '1a038f41f-1e54-4aec-8606-754d2161b841'
  const workoutd = Object.values(plans).filter(obj => planid.includes(obj.id))

  useEffect(() => {
    const Workoutop = []
    const { workouts } = workoutd[0]
    Object.keys(workouts).map((ind) => {
      const { WorkoutsSession, WorkoutsName } = workouts[ind]
      let workouts1 = {}
      if(WorkoutsSession > 0) {
        workouts1 = { label: `${WorkoutsName} - ${WorkoutsSession} session`, value: WorkoutsName }
        Workoutop[ind] = workouts1
      }
      return null
    })
    setWorkoutop(Workoutop)
    // setState({ workotop: Workoutop })
  }, [])
  useEffect(() => {
    const duration = workoutd[0].duration
    let schedules = []
    const videop = []
    const sch = workoutd[0].schedule
    let schLen = 0
    if(sch !== undefined) {
      schLen = Object.values(sch).length
    }
    if(workoutd[0].schedule === undefined) {
      for(let i = 0; i < duration; i++) {
        schedules[i] = {
          Workout: '',
          Comments: '',
          WorkoutVideo: '',
          // After adding the some logic in assing workouts for booking status & Schedled_for
          // remove this line
          BookingStatus: 'Not Booked',
          scheduled_for: '',
        }
        const videoOption = [{ label: 'Select workouts', value: '' }]
        videop[i] = videoOption
      }
      setvideoop(videop)
      setState({ schedule: schedules })
    } else if(schLen !== Number(duration)) {
      schedules = workoutd[0].schedule
      const videoOption = []
      if(schLen < Number(duration)) {
        for(let i = schLen - 1; i < Number(duration); i++) {
          schedules[i] = {
            Workout: '',
            Comments: '',
            WorkoutVideo: '',
            // After adding the some logic in assing workouts for booking status & Schedled_for
            // remove this line
            BookingStatus: 'Not Booked',
            scheduled_for: '',
          }
        }
      } else {
        schedules = Object.values(schedules).slice(0, Number(duration))
      }
      Object.values(schedules).map((objs, ind) => {
        const { Workout } = objs
        const workoutvideo = videos[lowerCase(Workout)]
        let flitervideo = []
        let i = 0
        if(workoutvideo !== undefined) {
          Object.values(workoutvideo).map((obj) => {
            const { name, id } = obj
            flitervideo[i] = { label: name, value: id }
            i++
            return obj
          })
        } else{
          flitervideo = [{ label: 'Select workouts', value: '' }]
        }
        videoOption[ind] = flitervideo
        return objs
      })
      setvideoop(videoOption)
      setState({ schedule: schedules })
    }else {
      schedules = workoutd[0].schedule
      const videoOption = []
      Object.values(schedules).map((objs, ind) => {
        const { Workout } = objs
        const workoutvideo = videos[lowerCase(Workout)]
        let flitervideo = []
        let i = 0
        if(workoutvideo !== undefined) {
          Object.values(workoutvideo).map((obj) => {
            const { name, id } = obj
            flitervideo[i] = { label: name, value: id }
            i++
            return obj
          })
        } else{
          flitervideo = [{ label: 'Select workouts', value: '' }]
        }
        videoOption[ind] = flitervideo
        return objs
      })
      setvideoop(videoOption)
      setState({ schedule: schedules })
    }
  }, [])

  useEffect(() => {
    if (!isdefaultWorkouts && isupdate) {
      if (isdefaultWorkoutsErr) {
        showSnackbar(isdefaultWorkoutsErrMsg, {}, 0)
        setUpdate(false)
      } else if (!defaultWorkouts) {
        showSnackbar('Plan Updated Successfully', {}, 1)
        setUpdate(false)
      }
    }
  }, [isdefaultWorkouts])
  const isDatafetched = workoutd.length > 0

  const onSelect = (value, obj, { key, ind }) => {
    const { schedule } = state
    const newplanWorkouts = { ...schedule }
    newplanWorkouts[ind][key] = value
    setState({ schedule: newplanWorkouts })
    if(key === 'Workout') {
      const workoutvideo = videos[lowerCase(value)]
      const flitervideo = []
      let i = 0
      Object.values(workoutvideo).map((objs) => {
        const { name, id } = objs
        flitervideo[i] = { label: name, value: id }
        i++
        return null
      })
      const videoOption = { ...videoop }
      videoOption[ind] = flitervideo
      setvideoop(videoOption)
    }
  }
  const onChangeText = (e, { key, ind }) => {
    const { schedule } = state
    const value = e.target.value
    const newDietPlan = { ...schedule }
    newDietPlan[ind][key] = value
    setState({ schedule: newDietPlan })
  }

  const assingWorkots = () => {
    const { schedule } = state
    const pid = planid
    const params = {
      schedule,
      pid,
    }
    assingWorkotsd(params)
    setUpdate(true)
  }
  const workoutable = () => {
    const { schedule } = state
    return(
      <div className={styles.totalContainor}>
        <table className={styles.Containor}>
          <thead className={styles.theadContainor}>
            <th style={{ color: 'white', textAlign: 'justify' }} className={styles.thContainor1}>day</th>
            <th style={{ color: '#ff6a00' }} className={styles.thContainor1}>Workouts</th>
            <th style={{ color: '#ff6a00' }} className={styles.thContainor1}>Comments</th>
            <th style={{ color: '#ff6a00' }} className={styles.thContainor1}>Videos</th>
          </thead>
          <tbody className={styles.tbContainor}>
            {Object.values(schedule).map((objs, ind) => {
              return(
                <tr>
                  <td className={styles.datecontainor} style={{ color: 'white', fontSize: '14px' }}>{ind + 1}</td>
                  <td className={styles.tddata}>
                    <ListDropdown
                      placeholder="Select"
                      options={workotop}
                      labelKey="label"
                      valueKey="value"
                      changeKey={{ key: 'Workout', ind }}
                      selected={schedule[ind].Workout}
                      onChangeOption={onSelect}
                      containerStyles={styles.dropDownContainer}
                      inputContainerStyles={styles.dropDownInput}
                      inputStyles={styles.filterInput}
                      inputBtnStyles={styles.filterInputBtn}
                    />
                  </td>
                  <td className={styles.tddata}>
                    <div className={styles.comment}>
                      <CustomInput
                        placeholder="Comments"
                        value={schedule[ind].Comments}
                        inputKey={{ key: 'Comments', ind }}
                        onChangeText={onChangeText}
                        inputStyles={styles.input}
                      />
                    </div>
                  </td>
                  <td className={styles.tddata}>
                    <ListDropdown
                      placeholder="Select"
                      options={videoop[ind]}
                      labelKey="label"
                      valueKey="value"
                      changeKey={{ key: 'WorkoutVideo', ind }}
                      selected={schedule[ind].WorkoutVideo}
                      onChangeOption={onSelect}
                      containerStyles={styles.dropDownContainer}
                      inputContainerStyles={styles.dropDownInput}
                      tyles={styles.filterInput}
                      inputBtnStyles={styles.filterInputBtn}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  return(
    <div>
      {isDatafetched ? (
        <div className={styles.bookSessionBtn}>
          {workoutable()}
          <br />
          <Button
            buttonColor="orange"
            text="Confirm"
            btnStyles={styles.bookSessionBtn}
            params="showNewSesssionModal"
            onPress={assingWorkots}
          />
        </div>
      ) : (<></>)}
    </div>
  )
}

const stylesheet = {
  Containor: {
    width: '100%',
    backgroundColor: '#494949',
    borderRadius: 12,
  },
  totalContainor: {
    backgroundColor: '#494949',
    border: '1px solid #ffcc80',
    borderRadius: 12,
    paddingBottom: '40px',
  },
  theadContainor: {
    colore: 'white',
    textAlign: 'center',
  },
  tddata: {
    padding: '1%',
  },
  dropDownContainer: {
    height: '35px',
  },
  filterInputBtn: {
    backgroundColor: '#494949',
    borderRadius: '10px',
  },
  input: {
    backgroundColor: '#494949',
    color: '#f5f5f5',
    '&:hover': {
      // color: 'red',
    },
  },
  datecontainor: {
  },
  '@media only screen and (min-width: 940px) ': {
    Containor: {
      padding: SPACING.SPACE_24,
    },
    totalContainor: {
      padding: SPACING.SPACE_14,
    },
  },
  '@media only screen and (max-width: 940px) ': {
    tddata: {
      paddingBottom: '10%',
      paddingRight: '4%',
    },
    thContainor1: {
      fontSize: '12px',
      padding: '8px 0px',
    },
    tbContainor: {
      padding: '2%',
    },
  },
}
const mapStateToProps = state => ({
  user_details: state.auth.user_details,
  defaultWorkouts: state.assignedWorkouts.defaultWorkouts,
  isdefaultWorkouts: state.assignedWorkouts.isdefaultWorkouts,
  isdefaultWorkoutsErr: state.assignedWorkouts.isdefaultWorkoutsErr,
  isdefaultWorkoutsErrMsg: state.assignedWorkouts.isdefaultWorkoutsErrMsg,
})
const mapDispatchToProps = dispatch => ({
  assingWorkotsd: params => dispatch(defaultWorkout(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(stylesheet)(Planworkouts))
