import {
  FETCH_ALL_PURCHASES, FETCH_ALL_PURCHASES_FAILURE, FETCH_ALL_PURCHASES_SUCCESS,
  CREATE_PLAN, CREATE_PLAN_FAILURE, CREATE_PLAN_SUCCESS,
  DELETE_PLAN, DELETE_PLAN_FAILURE, DELETE_PLAN_SUCCESS,
  UPDATE_PLAN, UPDATE_PLAN_FAILURE, UPDATE_PLAN_SUCCESS,
  CREATE_INVOICE, CREATE_INVOICE_FAILURE, CREATE_INVOICE_SUCCESS,
  UPDATE_INVOICE, UPDATE_INVOICE_FAILURE, UPDATE_INVOICE_SUCCESS,
  CREATE_PLANSUBSCRIPTION, CREATE_PLANSUBSCRIPTION_SUCCESS, CREATE_PLANSUBSCRIPTION_FAILURE,
  FETCH_ALL_PLANSUBSCRIPTION,
  FETCH_ALL_PLANSUBSCRIPTION_FAILURE,
  FETCH_ALL_PLANSUBSCRIPTION_SUCCESS,
} from './actionTypes'

const initialState = {
  isFetchingPurchases: false,
  fetchPurchasesErr: false,
  fetchPurchasesErrMsg: '',
  purchases: [],

  isCreatingPlan: false,
  createPlanErr: false,
  createPlanErrMsg: '',

  isDeletingPlan: false,
  deletePlanErr: false,
  deletePlanErrMsg: '',
  planDeleted: false,

  isUpdatingPlan: false,
  updatePlanErr: false,
  updatePlanErrMsg: '',
  planUpdated: false,

  isCreatingInvoice: false,
  createInvoiceErr: false,
  createInvoiceErrMsg: '',
  invoiceCreated: false,

  isUpdatingInvoice: false,
  updateInvoiceErr: false,
  updateInvoiceErrMsg: '',
  invoiceUpdated: false,

  isPlansubscriptions: false,
  PlansubscriptionsErr: false,
  PlansubscriptionsErrMsg: '',
  PlansubscriptionsSussed: false,

  isFetchingplansubscription: false,
  fetchplansubscriptionErr: false,
  fetchplansubscriptionErrMsg: '',
  plansubscription: [],
}

const payments = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_PURCHASES:
      return {
        ...state,
        isFetchingPurchases: true,
      }

    case FETCH_ALL_PURCHASES_SUCCESS:
      return {
        ...state,
        isFetchingPurchases: false,
        purchases: action.data,
      }

    case FETCH_ALL_PURCHASES_FAILURE:
      return {
        ...state,
        isFetchingPurchases: false,
        fetchPurchasesErr: true,
        fetchPurchasesErrMsg: action.error,
      }

    case CREATE_PLAN:
      return {
        ...state,
        isCreatingPlan: true,
      }

    case CREATE_PLAN_SUCCESS:
      return {
        ...state,
        isCreatingPlan: false,
      }

    case CREATE_PLAN_FAILURE:
      return {
        ...state,
        isCreatingPlan: false,
        createPlanErr: true,
        createPlanErrMsg: action.error,
      }

    case DELETE_PLAN:
      return {
        ...state,
        isDeletingPlan: true,
      }

    case DELETE_PLAN_SUCCESS:
      return {
        ...state,
        isDeletingPlan: false,
        planDeleted: true,
      }

    case DELETE_PLAN_FAILURE:
      return {
        ...state,
        isDeletingPlan: false,
        deletePlanErr: true,
        deletePlanErrMsg: action.error,
      }

    case UPDATE_PLAN:
      return {
        ...state,
        isUpdatingPlan: true,
      }

    case UPDATE_PLAN_SUCCESS:
      return {
        ...state,
        isUpdatingPlan: false,
        planUpdated: true,
      }

    case UPDATE_PLAN_FAILURE:
      return {
        ...state,
        isUpdatingPlan: false,
        updatePlanErr: true,
        updatePlanErrMsg: action.error,
      }

    case CREATE_INVOICE:
      return {
        ...state,
        isCreatingInvoice: true,
      }

    case CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        isCreatingInvoice: false,
        invoiceCreated: true,
      }

    case CREATE_INVOICE_FAILURE:
      return {
        ...state,
        isCreatingInvoice: false,
        createInvoiceErr: true,
        createInvoiceErrMsg: action.error,
      }

    case UPDATE_INVOICE:
      return {
        ...state,
        isUpdatingInvoice: true,
      }

    case UPDATE_INVOICE_SUCCESS:
      return {
        ...state,
        isUpdatingInvoice: false,
        invoiceUpdated: true,
      }

    case UPDATE_INVOICE_FAILURE:
      return {
        ...state,
        isUpdatingInvoice: false,
        updateInvoiceErr: true,
        updateInvoiceErrMsg: action.error,
      }

    case CREATE_PLANSUBSCRIPTION:
      return {
        ...state,
        isPlansubscriptions: true,
      }

    case CREATE_PLANSUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isPlansubscriptions: false,
        PlansubscriptionsSussed: true,
      }

    case CREATE_PLANSUBSCRIPTION_FAILURE:
      return {
        ...state,
        isPlansubscriptions: false,
        PlansubscriptionsErr: true,
        PlansubscriptionsErrMsg: action.error,
      }

    case FETCH_ALL_PLANSUBSCRIPTION:
      return {
        ...state,
        isFetchingplansubscription: true,
      }

    case FETCH_ALL_PLANSUBSCRIPTION_FAILURE:
      return {
        ...state,
        isFetchingplansubscription: false,
        plansubscription: action.data,
      }

    case FETCH_ALL_PLANSUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isFetchingplansubscription: false,
        fetchplansubscriptionErr: true,
        fetchplansubscriptionErrMsg: action.error,
      }

    default:
      return state
  }
}

export default payments
