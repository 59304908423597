import { takeLatest, put, call } from 'redux-saga/effects'
import {
  getDocs, query, collection, setDoc, doc,
} from 'firebase/firestore'
import { v4 as uuid } from 'uuid'

import {
  getBranchesFailure,
  getBranchesSuccess,
  addBranchFailure,
  addBranchSuccess,
} from './actions'
import {
  GET_BRANCHES,
  ADD_BRANCH,
} from './actionTypes'
import { db } from '../../utils/firebaseConfig'

import { error_msg } from '../../utils/common'

function* getBranches() {
  try {
    const q = query(collection(db, 'branches'))
    const data = yield call(getDocs, q)
    const branches = []
    data.forEach(res => branches.push(res.data()))

    if (branches.length) {
      yield put(getBranchesSuccess(branches))
    } else {
      yield put(getBranchesFailure(data.error))
    }
  } catch (err) {
    yield put(getBranchesFailure(err.message || error_msg))
  }
}

export function* getBranchesSaga() {
  yield takeLatest(GET_BRANCHES, getBranches)
}

function* addBranch({ params }) {
  try {
    const id = uuid()
    const data = {
      ...params,
      id,
    }
    yield call(setDoc, doc(db, 'branches', id), data)
    const branch = params
    yield put(addBranchSuccess(branch))
    yield put({ type: GET_BRANCHES })
  } catch (err) {
    yield put(addBranchFailure(err.message || error_msg))
  }
}

export function* addBranchSaga() {
  yield takeLatest(ADD_BRANCH, addBranch)
}
