import {
  UPDATE_SECHUDLE, UPDATE_SECHUDLE_SUCCESS, UPDATE_SECHUDLE_FAILURE,
} from './actionTypes'

export function updateschedule(params) {
  return {
    type: UPDATE_SECHUDLE,
    params,
  }
}

export function updatescheduleSuccess(data) {
  return {
    type: UPDATE_SECHUDLE_SUCCESS,
    data,
  }
}

export function updatescheduleFailure(error) {
  return {
    type: UPDATE_SECHUDLE_FAILURE,
    error,
  }
}
