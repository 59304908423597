import makeStyles from '@material-ui/styles/makeStyles'
import React, {
  useEffect, useRef, useState,
} from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import classnames from 'classnames'

import { ICONS, SPACING, theme } from '../Theme'
import { useTheme } from '../Theme/ThemeProvider'
import Icon from './Icon'

const ANIM_CLASS_MAP = {
  center: 'modal-jump',
  right: 'modal-slide',
  bottom: 'modal-slide',
}

const CustomModal = ({
  children,
  containerStyles,
  visible,
  onDismiss,
  dismissKey,
  position,
  //   animationType,
  transparent,
  backdropColor,
  disableBackDrop,
  fullScreen,
  hideScroll,
}) => {
  const styles = stylesheet()
  const modalRef = useRef(null)
  const [show, updateModal] = useState(false)
  const { isMobile, isDark } = useTheme()
  const modalClass = hideScroll ? 'hideScrollbar' : ''
  const modalPosition = isMobile && !fullScreen ? 'bottom' : position
  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.style.animationName = modalPosition !== 'center'
        ? `modalAnimSlide_${modalPosition}${visible ? '' : '_reverse'}`
        : `modalAnimJump${visible ? 'In' : 'Out'}`
    }
    if (visible && !show) {
      updateModal(true)
    } else if (!visible && show) {
      setTimeout(() => {
        updateModal(false)
      }, modalPosition !== 'center' ? 450 : 200)
    }
  }, [visible, modalPosition])
  useEffect(() => {
    return () => {
      if (show) updateModal(false)
    }
  }, [])
  if (!show && !visible) return null
  const backDropBg = transparent ? 'transparent' : backdropColor
  // this code keyboard capture. added by raathan/surya.
  const backDropHandler = (e) => {
    if (disableBackDrop) return
    e.preventDefault()
    onDismiss(dismissKey)
  }
  let modalStyles = transparent ? {} : { backgroundColor: theme.bgPrimary }
  if (isDark) {
    modalStyles = { ...modalStyles, boxShadow: 'none' }
  }
  return (
    ReactDOM.createPortal(
      <div className={styles.container}>
        <button type="button" onClick={backDropHandler} style={{ pointer: 'default' }}>
          <div className={styles.backDrop} style={{ backgroundColor: backDropBg }} />
        </button>
        <div
          className={classnames(fullScreen ? styles.modalFullScreen : styles.modal,
            styles[modalPosition], ANIM_CLASS_MAP[modalPosition], containerStyles, modalClass)}
          style={modalStyles}
          ref={modalRef}
        >
          {children}
          <button
            type="button"
            onClick={backDropHandler}
            className={styles.closeBtn}
          >
            <Icon
              name={ICONS.CLOSE}
              size={16}
              onPress={backDropHandler}
            />
          </button>
        </div>
      </div>, document.getElementById('main'),
    )
  )
}

const stylesheet = makeStyles({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 9999,
    display: 'flex',
  },
  modal: {
    width: '80%',
    maxWidth: 500,
    opacity: 0,
    margin: 'auto',
    borderRadius: 10,
    padding: `${SPACING.SPACE_24} ${SPACING.SPACE_20}`,
    boxShadow: '0px 14px 20px #162B7433',
    maxHeight: '90%',
    overflow: 'auto',
  },
  modalFullScreen: {
    overflow: 'auto',
    opacity: 0,
    boxShadow: '0px 14px 20px #162B7433',
  },
  right: {
    maxHeight: '100%',
    borderRadius: 0,
    width: '28%',
    minWidth: 400,
    position: 'fixed',
    right: 0,
  },
  bottom: {
    alignSelf: 'flex-end',
    margin: 0,
    width: '100% !important',
    borderRadius: 0,
    padding: `${SPACING.SPACE_10} ${SPACING.SPACE_20}`,
    maxWidth: 'unset !important',
    minWidth: 'unset',
    minHeight: '30%',
    maxHeight: '80%',
  },
  backDrop: {
    backgroundColor: theme.backdropBg,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  closeBtn: {
    position: 'absolute',
    top: SPACING.SPACE_20,
    right: SPACING.SPACE_20,
    cursor: 'pointer',
  },
  '@media only screen and (max-width: 786px)': {
    right: {
      width: '100%',
    },
    modalFullScreen: {
      margin: 0,
      width: '100% !important',
      opacity: 0,
      padding: 0,
      overflow: 'auto',
      minWidth: 'unset',
    },
  },
})

export default CustomModal

CustomModal.defaultProps = {
  dismissKey: '',
  position: 'center',
  transparent: false,
  backdropColor: theme.backdropBg,
  fullScreen: false,
}

CustomModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  // onDismiss: PropTypes.func.isRequired,
  onDismiss(props, propName) {
    const { disableBackDrop } = props
    // eslint-disable-next-line react/destructuring-assignment
    if ((!disableBackDrop && (props[propName] === undefined || typeof (props[propName]) !== 'function'))) {
      return new Error('Please provide a onDismiss function!')
    }
    return null
  },
  dismissKey: PropTypes.string,
  position: PropTypes.string,
  transparent: PropTypes.bool,
  backdropColor: PropTypes.string,
  fullScreen: PropTypes.bool,
  disableBackDrop: PropTypes.bool,
}
