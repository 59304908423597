import { makeStyles } from '@material-ui/styles'
import React, {
  useEffect, useCallback, useRef, useState,
} from 'react'
import classnames from 'classnames'
import throttle from 'lodash/throttle'
import Proptypes from 'prop-types'

import CustomText from './CustomText'
import PlaceHolderLoader from './PlaceHolderLoader'
import Icon from './Icon'

import {
  ICONS, SPACING, theme,
} from '../Theme'
import { useTheme } from '../Theme/ThemeProvider'

const SCROLL_HEIGHT = 20

const scrollToTop = () => {
  const main = document.getElementById('main')
  if (main) {
    main.scrollTo({ top: 0, behavior: 'smooth' })
  }
}

const ScreenHeader = React.memo(({
  backPressHandler,
  title,
  actionBarRenderer,
  titleSize,
  showBackBtn,
  containerStyles,
  titleStyles,
  titleRenderer,
  iconSize,
  hideOnScroll,
  loading,
  scrolledText,
  children,
  leftContainerStyles,
  backBtnStyle,
}) => {
  const styles = stylesheet()
  const [scrolled, updateScrollState] = useState(false)
  const { isMobile } = useTheme()
  const titleRef = useRef()
  const containerRef = useRef()
  const scrolledTextRef = useRef()
  let decreased = false
  const titleFontSize = titleSize || (isMobile && scrolled ? 'medium' : isMobile ? 'medium_1' : scrolled ? 'medium' : 'large_2')
  useEffect(() => {
    if (!isMobile) {
      const main = document.getElementById('main')
      main.addEventListener('scroll', onScrollRes)
      return () => {
        main.removeEventListener('scroll', onScrollRes)
      }
    }
    return () => {}
  }, [])
  const onScrollRes = useCallback(throttle((ev) => {
    const { scrollTop } = ev.target
    if (titleRef.current) {
      const tRef = titleRef.current
      if (scrolledTextRef.current) {
        tRef.style.transition = 'opacity 250ms var(--anim-func-ease), transform 200ms var(--anim-func-ease) '
        if (scrollTop > SCROLL_HEIGHT) {
          tRef.style.transform = 'translate(0, -20px)'
          tRef.style.opacity = 0
          scrolledTextRef.current.style.transition = 'opacity 250ms var(--anim-func-ease), transform 200ms var(--anim-func-ease),  '
          scrolledTextRef.current.style.transform = 'translate(0, 0px)'
          scrolledTextRef.current.style.left = `${tRef.offsetLeft}px`
          scrolledTextRef.current.style.opacity = 1
        } else if (scrollTop < (SCROLL_HEIGHT - 10)) {
          tRef.style.transform = 'translate(0, 0)'
          tRef.style.opacity = 1
          scrolledTextRef.current.style.transition = 'none'
          scrolledTextRef.current.style.transform = 'translate(0, 30px)'
          scrolledTextRef.current.style.opacity = 0
          scrolledTextRef.current.style.left = 0
        }
      } else if (hideOnScroll) {
        containerRef.current.style.position = 'relative'
        containerRef.current.style.top = 'unset'
        if (scrollTop > SCROLL_HEIGHT) {
          containerRef.current.style.animationName = 'hide-on-scroll'
        } else if (scrollTop < (SCROLL_HEIGHT - 10)) {
          containerRef.current.style.animationName = 'show-on-scroll'
        }
        // if (scrollTop < (SCROLL_HEIGHT - 10)) {
        //   containerRef.current.style.animationName = 'show-on-scroll'
        // }
        // containerRef.current.style.paddingTop = `${20 - (scrollTop * (20 / SCROLL_HEIGHT))}px`
        // containerRef.current.style.bottom = `${(scrollTop * (10 / SCROLL_HEIGHT))}px`
        // containerRef.current.style.opacity = 1 - (scrollTop * (1 / SCROLL_HEIGHT))
      }
      if (titleRef.current) {
        if (scrollTop >= 30 && !decreased) {
          decreased = true
          updateScrollState(true)
          // tRef.style.fontSize = FONTS.MEDIUM
        } else if (scrollTop < 20 && decreased) {
          decreased = false
          updateScrollState(false)
          // tRef.style.fontSize = FONTS[titleSize.toUpperCase()]
        }
      }
    }
  }, 50), [title])
  if (loading) {
    return (
      <>
        <div
          className={classnames(
            styles.container,
            containerStyles,
          )}
          ref={containerRef}
        >
          <PlaceHolderLoader className={styles.loader} />
        </div>
        {children ? children({ scrolled }) : null}
      </>
    )
  }
  return (
    <>
      <div
        className={classnames(
          styles.container,
          containerStyles,
        )}
        id="screen_header"
        ref={containerRef}
      >
        <div className={classnames(styles.leftContent, leftContainerStyles)}>
          {showBackBtn ? (
            <button
              type="button"
              onClick={backPressHandler}
              className={classnames(styles.backBtn, backBtnStyle)}
              style={{ paddingLeft: actionBarRenderer && !isMobile ? 0 : SPACING.SPACE_14 }}
            >
              <Icon name={ICONS.BACK} color={theme.text} size={iconSize} />
            </button>
          ) : <div className={styles.backBtnPlaceHolder} />}
          {titleRenderer ? titleRenderer({ scrolled }) : (
            <div style={{ display: 'flex', width: 'calc(100% - 41px)', alignItems: 'center' }}>
              <CustomText
                className={classnames(styles.title, titleStyles)}
                color="white"
                size={titleFontSize}
                weight="semi_bold"
                ellipsis
                ref={titleRef}
              >
                {title}
              </CustomText>
              {scrolledText && (
              <CustomText
                className={classnames(styles.title, styles.scrolledTitle, titleStyles)}
                size="medium"
                weight="semi_bold"
                ellipsis
                ref={scrolledTextRef}
                extraProps={{
                  onClick: scrollToTop,
                }}
              >
                {scrolledText}
              </CustomText>
              )}
            </div>
          )}
        </div>
        {actionBarRenderer ? actionBarRenderer({ scrolled }) : null}
        {/* {showBackBtn && <div style={{ width: SPACING.SPACE_20, height: '100%' }} />} */}
      </div>
      {children ? children({ scrolled }) : null}
    </>
  )
})

const stylesheet = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: SPACING.HEADER_HEIGHT,
    zIndex: '9998',
    top: 0, // SPACING.HEADER_HEIGHT,
    padding: `${SPACING.SPACE_20} 0 ${SPACING.SPACE_10}`,
    backgroundColor: '#393939',
    animationDuration: '600ms',
    animationTimingFunction: 'cubic-bezier(0, 0.62, 0.24, 1.04)',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    overflowY: 'hidden',
    // transition: 'all 200ms var(--anim-func-ease)',
  },
  loader: {
    height: 20,
    maxWidth: 200,
  },
  title: {
    flex: 1,
    marginRight: SPACING.SPACE_20,
  },
  scrolledTitle: {
    opacity: 0,
    transform: 'translate(0, 30px)',
    left: 0,
    position: 'absolute',
    zIndex: 9,
    cursor: 'pointer',
    // transition: 'all 200ms var(--anim-func-ease)',
    width: '85%',
  },
  backBtn: {
    paddingRight: SPACING.SPACE_12,
    paddingLeft: SPACING.SPACE_14,
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  liveTag: {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: '#FFE5E5',
    padding: '0px 10px',
  },
  leftContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    overflow: 'hidden',
  },
  iconWrapper: {
    paddingLeft: SPACING.SPACE_2,
    paddingRight: SPACING.SPACE_4,
    // paddingVertical: SPACING.SPACE_6,
    justifyContent: 'center',
    alignItems: 'center',
  },
  backBtnPlaceHolder: {},
  '@media only screen and (max-width: 786px)': {
    container: {
      top: 0,
      width: '100%', // `calc(100% + ${SPACING.SPACE_40})`,
      left: 0,
      height: SPACING.HEADER_HEIGHT_MOBILE,
      boxShadow: `0px 3px 20px ${theme.boxShadow}`,
      padding: `0 ${SPACING.SPACE_20} 0 0`,
    },
    backBtn: {
      paddingLeft: SPACING.SPACE_16,
      // padding: `0 ${SPACING.SPACE_12} 0 0`,
    },
  },
})

export default ScreenHeader

ScreenHeader.defaultProps = {
  titleSize: 'large_2',
  iconSize: 14,
  backPressHandler: () => null,
  showBackBtn: false,
  title: '',
  containerStyles: '',
  actionBarRenderer: () => null,
  titleStyles: '',
  hideOnScroll: false,
  scrolledText: '',
}

ScreenHeader.propTypes = {
  titleSize: Proptypes.string,
  backPressHandler: Proptypes.func,
  title: Proptypes.string,
  actionBarRenderer: Proptypes.func,
  showBackBtn: Proptypes.bool,
  containerStyles: Proptypes.string,
  titleStyles: Proptypes.string,
  iconSize: Proptypes.number,
  hideOnScroll: Proptypes.bool,
  scrolledText: Proptypes.string,
}
