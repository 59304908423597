import React, { Component } from 'react'
import { withMediaProps } from 'react-media-player'
import { ICONS } from '../../Theme'
import Icon from '../Icon'

class CustomPlayPause extends Component {
  shouldComponentUpdate({ media }) {
    const { media: propMedia } = this.props
    return propMedia.isPlaying !== media.isPlaying
  }

    handlePlayPause = () => {
      const { media } = this.props
      media.playPause()
    }

    render() {
      const { className, style, media } = this.props
      return (
        <button
          type="button"
          className={className}
          style={style}
          onClick={this.handlePlayPause}
        >
          <Icon name={media.isPlaying ? ICONS.MEDIA_PAUSE : ICONS.MEDIA_PLAY} size={28} />
        </button>
      )
    }
}

export default withMediaProps(CustomPlayPause)
