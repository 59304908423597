import {
  FETCH_ALL_PURCHASES, FETCH_ALL_PURCHASES_FAILURE, FETCH_ALL_PURCHASES_SUCCESS,
  CREATE_PLAN, CREATE_PLAN_FAILURE, CREATE_PLAN_SUCCESS,
  DELETE_PLAN, DELETE_PLAN_FAILURE, DELETE_PLAN_SUCCESS,
  UPDATE_PLAN, UPDATE_PLAN_FAILURE, UPDATE_PLAN_SUCCESS,
  CREATE_INVOICE, CREATE_INVOICE_FAILURE, CREATE_INVOICE_SUCCESS,
  UPDATE_INVOICE, UPDATE_INVOICE_FAILURE, UPDATE_INVOICE_SUCCESS,
  CREATE_PLANSUBSCRIPTION, CREATE_PLANSUBSCRIPTION_SUCCESS, CREATE_PLANSUBSCRIPTION_FAILURE,
  FETCH_ALL_PLANSUBSCRIPTION,
  FETCH_ALL_PLANSUBSCRIPTION_FAILURE,
  FETCH_ALL_PLANSUBSCRIPTION_SUCCESS,
} from './actionTypes'

export function fetchAllPurchases(params) {
  return {
    type: FETCH_ALL_PURCHASES,
    params,
  }
}

export function fetchAllPurchasesSuccess(data) {
  return {
    type: FETCH_ALL_PURCHASES_SUCCESS,
    data,
  }
}

export function fetchAllPurchasesFailure(error) {
  return {
    type: FETCH_ALL_PURCHASES_FAILURE,
    error,
  }
}

export function createPlan(params, update) {
  return {
    type: CREATE_PLAN,
    params,
    update,
  }
}

export function createPlanSuccess(data) {
  return {
    type: CREATE_PLAN_SUCCESS,
    data,
  }
}

export function createPlanFailure(error) {
  return {
    type: CREATE_PLAN_FAILURE,
    error,
  }
}

export function deletePlan(params) {
  return {
    type: DELETE_PLAN,
    params,
  }
}

export function deletePlanSuccess(data) {
  return {
    type: DELETE_PLAN_SUCCESS,
    data,
  }
}

export function deletePlanFailure(error) {
  return {
    type: DELETE_PLAN_FAILURE,
    error,
  }
}

export function updatePlan(params) {
  return {
    type: UPDATE_PLAN,
    params,
  }
}

export function updatePlanSuccess(data) {
  return {
    type: UPDATE_PLAN_SUCCESS,
    data,
  }
}

export function updatePlanFailure(error) {
  return {
    type: UPDATE_PLAN_FAILURE,
    error,
  }
}

export function createInvoice(params) {
  console.log(params)
  return {
    type: CREATE_INVOICE,
    params,
  }
}

export function createInvoiceSuccess(data) {
  return {
    type: CREATE_INVOICE_SUCCESS,
    data,
  }
}

export function createInvoiceFailure(error) {
  return {
    type: CREATE_INVOICE_FAILURE,
    error,
  }
}

export function updateInvoice(params) {
  return {
    type: UPDATE_INVOICE,
    params,
  }
}

export function updateInvoiceSuccess(data) {
  return {
    type: UPDATE_INVOICE_SUCCESS,
    data,
  }
}

export function updateInvoiceFailure(error) {
  return {
    type: UPDATE_INVOICE_FAILURE,
    error,
  }
}
export function Plansubscriptions(parms) {
  return {
    type: CREATE_PLANSUBSCRIPTION,
    parms,
  }
}

export function PlansubscriptionsSuccess(data) {
  return {
    type: CREATE_PLANSUBSCRIPTION_SUCCESS,
    data,
  }
}

export function PlansubscriptionsFailure(error) {
  return {
    type: CREATE_PLANSUBSCRIPTION_FAILURE,
    error,
  }
}
export function fetchAllplansubscription(params) {
  return {
    type: FETCH_ALL_PLANSUBSCRIPTION,
    params,
  }
}

export function fetchAllplansubscriptionSuccess(data) {
  return {
    type: FETCH_ALL_PLANSUBSCRIPTION_FAILURE,
    data,
  }
}

export function fetchAllplansubscriptionFailure(error) {
  return {
    type: FETCH_ALL_PLANSUBSCRIPTION_SUCCESS,
    error,
  }
}
