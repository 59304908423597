import React, { Component } from 'react'
import { withMediaProps } from 'react-media-player'
import { ICONS } from '../../Theme'
import Icon from '../Icon'

class CustomPlayPause extends Component {
  shouldComponentUpdate({ media }) {
    const { media: propMedia } = this.props
    return propMedia.isMuted !== media.isMuted
  }

    handleMuteUnmute = () => {
      const { media } = this.props
      media.muteUnmute()
    }

    render() {
      const { className, style, media } = this.props
      return (
        <button
          type="button"
          className={className}
          style={style}
          onClick={this.handleMuteUnmute}
        >
          <Icon name={media.isMuted ? ICONS.MEDIA_MUTE : ICONS.MEDIA_UNMUTE} size={18} />
        </button>
      )
    }
}

export default withMediaProps(CustomPlayPause)
