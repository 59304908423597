import {
  ASSIGNED_NEW_WORKOUTS, ASSIGNED_NEW_WORKOUTS_SUCCESS, ASSIGNED_NEW_WORKOUTS_FAILURE,
  GET_WORKOUTS, GET_WORKOUTS_SUCCESS, GET_WORKOUTS_FAILURE,
  UPDATE_WORKOUTS, UPDATE_WORKOUTS_SUCCESS, UPDATE_WORKOUTS_FAILURE,
  DEFAULT_WORKOUTS, DEFAULT_WORKOUTS_SUCCESS, DEFAULT_WORKOUTS_FAILURE,
} from './actionTypes'

export function assingNewWorkouts(params) {
  return {
    type: ASSIGNED_NEW_WORKOUTS,
    params,
  }
}

export function assingNewWorkoutsSuccess(data) {
  return {
    type: ASSIGNED_NEW_WORKOUTS_SUCCESS,
    data,
  }
}

export function assingNewWorkoutsFailure(error) {
  return {
    type: ASSIGNED_NEW_WORKOUTS_FAILURE,
    error,
  }
}

export function defaultWorkout(params) {
  return {
    type: DEFAULT_WORKOUTS,
    params,
  }
}

export function defaultWorkoutSuccess(data) {
  return {
    type: DEFAULT_WORKOUTS_SUCCESS,
    data,
  }
}

export function defaultWorkoutFailure(error) {
  return {
    type: DEFAULT_WORKOUTS_FAILURE,
    error,
  }
}

export function getWorkouts(params) {
  return {
    type: GET_WORKOUTS,
    params,
  }
}

export function getWorkoutsSuccess(data) {
  return {
    type: GET_WORKOUTS_SUCCESS,
    data,
  }
}

export function getWorkoutsFailure(error) {
  return {
    type: GET_WORKOUTS_FAILURE,
    error,
  }
}

export function updateWorkouts(params) {
  return {
    type: UPDATE_WORKOUTS,
    params,
  }
}

export function updateworkoutsSuccess(data) {
  return {
    type: UPDATE_WORKOUTS_SUCCESS,
    data,
  }
}

export function updateworkoutsFailure(error) {
  return {
    type: UPDATE_WORKOUTS_FAILURE,
    error,
  }
}
