export const NAVIGATIONS = {
  DASHBOARD: {
    name: 'stamin.dashboard',
    id: 'DASHBOARD',
  },
  LOGIN: {
    name: 'stamin.login',
    id: 'LOGIN',
  },
}

// This is a mapping of pathnames to the endpoints (in sync with streak world v3 repo)
export const pathname_mapping = {
  Dashboard: '/dashboard',
  Auth: '/auth',
  Plans: '/plans',
  Sessions: '/sessions',
  SessionDetails: '/sessions/:id',
  Payments: '/plan&subscription',
  DietPlan: '/diet-plan',
  YourTrainer: '/your-trainer',

  AssignedMembers: '/assigned-members',
  Chats: '/chats',

  Members: '/members',
  Trainers: '/trainers',
  BranchAdmin: '/branch-admin', // path created for branchadmin
  Videos: '/videos',
  PaymentsAndPlans: '/payments-and-plans',
  Report: '/report',
  PlansDashboard: '/plans-dashboard',
  Branches: '/branches',

  Billing: '/billing',
  Profile: '/profile',
  ProfileBMI: '/ProfileBMI',
  AssignWorkot: '/assignWorkout',
  AssignWorkots: '/assignWorkouts',
  AssignWorkoutPlan: '/assignedWorkout',
  EmailVerification: '/emailverification',
  Invoice: '/invoice',
  QRcode: '/qrcode',
  Plansubscription: '/plansubscription',
  Verificationpage: '/verification',
}
