export const ASSIGNED_NEW_WORKOUTS = 'ASSIGNED_NEW_WORKOUTS'
export const ASSIGNED_NEW_WORKOUTS_SUCCESS = 'ASSIGNED_NEW_WORKOUTS_SUCCESS'
export const ASSIGNED_NEW_WORKOUTS_FAILURE = 'ASSIGNED_NEW_WORKOUTS_FAILURE'

export const GET_WORKOUTS = 'GET_WORKOUTS'
export const GET_WORKOUTS_SUCCESS = 'GET_WORKOUTS_SUCCESS'
export const GET_WORKOUTS_FAILURE = 'GET_WORKOUTS_FAILURE'

export const UPDATE_WORKOUTS = 'UPDATE_WORKOUTS'
export const UPDATE_WORKOUTS_SUCCESS = 'UPDATE_WORKOUTS_SUCCESS'
export const UPDATE_WORKOUTS_FAILURE = 'UPDATE_WORKOUTS_FAILURE'

export const DEFAULT_WORKOUTS = 'DEFAULT_WORKOUTS'
export const DEFAULT_WORKOUTS_SUCCESS = 'DEFAULT_WORKOUTS_SUCCESS'
export const DEFAULT_WORKOUTS_FAILURE = 'DEFAULT_WORKOUTS_FAILURE'
