import React from 'react'
import { Spring, animated } from 'react-spring'

import DashboardSideBar from './DashboardSideBar'
import Icon from '../../UI/Icon'

import { ICONS, SPACING, theme } from '../../Theme'
import { pathname_mapping } from '../../utils/navigationConstant'

// set null if do not want to show sidebar
// tabKey is used to show sidebar component where there are more than one route
export const BAR_TYPE_MAP = {
  [pathname_mapping.Dashboard]: {
    Component: DashboardSideBar,
    show: true,
    id: 'dash_side_bar',
  },
  [pathname_mapping.Sessions]: {
    Component: DashboardSideBar,
    show: true,
    id: 'dash_side_bar',
  },
  [pathname_mapping.DietPlan]: {
    Component: DashboardSideBar,
    show: true,
    id: 'dash_side_bar',
  },
  [pathname_mapping.Payments]: {
    Component: DashboardSideBar,
    show: true,
    id: 'dash_side_bar',
  },
  [pathname_mapping.YourTrainer]: {
    Component: DashboardSideBar,
    show: true,
    id: 'dash_side_bar',
  },
  [pathname_mapping.SessionDetails]: {
    Component: DashboardSideBar,
    show: true,
    id: 'dash_side_bar',
  },
}

const defaultAnimProps = {
  // from: { opacity: 0, transform: 'translate3d(0,300px,-20px)' },
  // to: { opacity: 1, transform: 'translate3d(0,0,0)' },
}

const SideBar = ({
  activeSideBarObj,
  containerStyles,
  isDark,
  isFetchingUser,
  domainChange,
  toggleSideBar,
  closeBtnStyles,
  sideBarRef,
  updateGA,
  hideSideBar,
  isMobile,
}) => {
  // const queryParams = parseQuery(search)
  // DANGER need better approach
  // const path = !isEmpty(queryParams) ? `${pathname}?${Object.keys(queryParams)[0]}=` : pathname

  const styles = {
    backgroundColor: theme.bgSidebar,
    borderRadius: 8,
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    // padding: `${SPACING.SPACE_20} 0`,
  }
  const Renderer = activeSideBarObj.Component
    || BAR_TYPE_MAP[pathname_mapping.Dashboard].Component
  const animationProps = activeSideBarObj.animProps || defaultAnimProps
  const extraAttr = activeSideBarObj.id ? { id: activeSideBarObj.id } : {}
  return (
    <aside className={containerStyles} ref={sideBarRef} {...extraAttr}>
      {isMobile && (
      <button
        type="button"
        onClick={e => toggleSideBar(e, true)}
        className={closeBtnStyles}
      >
        <Icon name={ICONS.CLOSE} size={10} color={theme.text} />
      </button>
      )}
      {!hideSideBar && (
      <Spring
        {...animationProps}
        config={{
          mass: 5, tension: 2800, friction: 500,
        }}
      >
        {props => (
          <animated.div style={{
            ...styles,
            ...props,
            paddingTop: activeSideBarObj.showToggler ? SPACING.SPACE_32 : 0,
          }}
          >
            <Renderer
              isFetchingUser={isFetchingUser}
              isDark={!isDark}
              domainChange={domainChange}
              updateGA={updateGA}
            />
          </animated.div>
        )}
      </Spring>
      )}
    </aside>
  )
}

export default SideBar
