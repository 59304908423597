import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
// import { isEmpty } from 'lodash'
import { withTheme } from '../../Theme/ThemeProvider'
import ScreenHeader from '../../UI/ScreenHeader'
// import AutoComplete from '../AutoComplete'
// import CustomInput from '../../UI/CustomInput'
import CustomText from '../../UI/CustomText'
import { Plansubscriptions, fetchAllplansubscription } from '../../containers/Payments/actions'
import { showSnackbar } from '../../UI/Snackbar'
// import Icon from '../../UI/Icon'
import {
  SPACING,
} from '../../Theme'
import Subscriptiontable from './Subscriptiontable'
import Button from '../../UI/Button'
import PlansubscriptionInvoice from './PlansubscriptionInvoice'
import InvoiceTable from './InvoiceTable'
// import ModalDropdown from '../../UI/Dropdown/ModalDropdown'

function Plansubscription({
  styles, branches, user_details, Plansubscription1, isPlansubscriptions,
  PlansubscriptionsErr, PlansubscriptionsErrMsg, PlansubscriptionsSussed, fetchAllplansub,
  plansubscription,
}) {
  console.log(plansubscription)
  const location = useLocation()
  // const branch_name = location.state.name
  // if (location.state.name !== undefined) {

  // }
  console.log(location)
  const [tableData, setTableData] = useState([
    { id: 1, plan: '', amount: 0 },
  ])

  useEffect(() => {
    if (!isPlansubscriptions) {
      if (PlansubscriptionsErr) {
        showSnackbar(PlansubscriptionsErrMsg, {}, 0)
      } else if ((PlansubscriptionsSussed)) {
        showSnackbar('Plan subscriptions Sussed', {}, 1)
      }
    }
    fetchAllplansub()
  }, [isPlansubscriptions])
  const [nextId, setNextId] = useState(2)
  const [username, setUsername] = useState('')
  const [mail, setmail] = useState('')
  const [phone, setphone] = useState('')
  const [planname, setplanname] = useState('')
  const [amount, setamount] = useState(0)
  const [discount, setdiscount] = useState('0')
  const [transactionId, settransactionId] = useState('')
  const [date, setdate] = useState(0)
  const [branchid, setbranchid] = useState('')
  const [name, setname] = useState('')
  const [create, setcreate] = useState(false)
  const [member, setmember] = useState(false)

  const userData = {
    username,
    mail,
    phone,
    planname,
    amount,
    discount,
    transactionId,
    date,
    branchid,
    name,
    create,
    member,
  }

  const handleAddRow = () => {
    const newRow = {
      id: nextId,
      plan: '',
      amount: 0,
    }

    setTableData(prevData => [...prevData, newRow])
    setNextId(prevId => prevId + 1)
  }

  const onSelectUser = (value) => {
    const uid = value
    console.log(uid)
    const selectedUserDetails = userOption.filter(user => uid === user.value)
    setUsername(selectedUserDetails[0].label)
    setbranchid(uid)
    setmail(selectedUserDetails[0].mail)
    setphone(selectedUserDetails[0].phone)
    console.log(selectedUserDetails)
  }

  // if (branch_name !== undefined) {
  //   setUsername(branch_name)
  // }

  const onSelectPlan = (label, value) => {
    setamount(label)
    setplanname(label)
    console.log(label)
    setname(value.label)
    console.log(value.label)
    setdate(dayjs(new Date()).format('DD-MM-YYYY'))
  }
  const onCreateMembership = () => {
    const parms = ({
      username,
      mail,
      phone,
      planname,
      amount,
      cgsttamount,
      sgsttamount,
      STATUS: 'TXN_SUCCESS',
      TotalAmount,
      discount,
      totalgst,
      transactionId,
      date,
      branchid,
      name,
    })
    Plansubscription1(parms)
    setUsername('')
    settransactionId('')
    setphone('')
    setmail('')
    setdate('')
    setplanname('')
    setamount('')
    setdiscount('')
  }
  const onChangeTextid = (e) => {
    const value = e.target.value
    settransactionId(value)
  }
  const onChangeText = (e) => {
    const value = e.target.value
    setdiscount(value)
    console.log(value)
  }
  const totalamount = Number(amount) - Number(discount)
  const cgsttamount = (totalamount * 9) / 100
  const sgsttamount = (totalamount * 9) / 100
  const totalGstamount = cgsttamount + sgsttamount
  const totalcgst = Math.ceil(cgsttamount)
  const totalgst = Math.ceil(totalGstamount)
  const TotalAmount = totalgst + totalamount

  const onCreatesubscription = () => {
    setcreate(true)
  }

  const openMemberDetails = () => {
    setmember(true)
  }
  const branchdata = plansubscription
  console.log(member)

  const userOption = branches.map(item => ({
    label: `${item.name}`,
    value: item.id,
    mail: item.email,
    phone: item.phone,
  }))
  // console.log(userOption)

  // const isadmin = user_details?.type === 'admin'
  const isbranchadmin = user_details?.type === 'branch_admin'

  return (
    <div className={styles.tcontainer}>
      <ScreenHeader
        title="Plan Subscription"
      />
      <div>
        {isbranchadmin ? (
          <>
            <br />
            <Subscriptiontable />
          </>
        ) : (
          <div>
            <div className={styles.createbutton}>
              <Button
                text="CREAT INVOICE"
                buttonColor="orange"
                uppercase
                labelStyle={styles.createBtn}
                onPress={onCreatesubscription}
              />
            </div>
            {create ? (
              <PlansubscriptionInvoice
                data={userData}
                onSelectUser={onSelectUser}
                onSelectPlan={onSelectPlan}
                tableData={tableData}
                totalamount2={totalamount}
                handleAddRow={handleAddRow}
                onCreateMembership={onCreateMembership}
                onChangeTextid={onChangeTextid}
                onChangeText={onChangeText}
                totalcgst={totalcgst}
                branches={branches}
                TotalAmount={TotalAmount}
                totalgst={totalgst}
              />
            ) : (
              <div className={styles.containertable}>
                <div className={styles.paymenttext}>
                  <CustomText size="medium" className={styles.paymenttext1} color="white" weight="semi_bold">
                    Payment History
                  </CustomText>
                </div>
                <InvoiceTable
                  branchdata={branchdata}
                  openMemberDetails={openMemberDetails}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

const stylesheet = () => ({
  containertable: {
    backgroundColor: '#494949',
    // border: '1px solid #ffcc80',
    borderRadius: 12,
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_20}`,
    width: '100%',
  },
  tcontainer: {
    padding: `${SPACING.SPACE_20}`,
  },
  filterInputBtn: {
    backgroundColor: '#393939',
    color: 'white',
  },
  inputamount: {
    color: 'red',
  },
  gstcontainer: {
    display: 'flex',
    gap: '5%',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dropdown: {
    display: 'flex',
    gap: '5%',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: '#494949',
    borderRadius: 12,
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  subcontainer: {
    margin: '1rem',
    marginLeft: '2rem',
    display: 'flex',
    gap: '30px',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  containerinvoice: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    borderRadius: 12,
    border: '1px solid #ffcc80',
    backgroundColor: '#494949',
    padding: SPACING.SPACE_20,
    gap: SPACING.SPACE_30,
  },
  containerCreateInvoice: {
    display: 'flex',
    gap: '30px',
    justifyContent: 'space-evenly',
    margin: '30px',
    flexWrap: 'wrap',
  },
  modelContainer: {
    backgroundColor: '#494949',
    border: '1px solid #ffcc80',
    borderRadius: 12,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_20}`,
  },
  tableContainor1: {
    margin: `${SPACING.SPACE_10} 0`,
    width: '100%',
  },
  thStyle: {
    border: '1px solid #dddd',
    textAlign: 'center',
    padding: SPACING.SPACE_10,
  },
  tableStyle: {
    borderCollapse: 'collapse',
    border: '1px solid #dddd',
  },
  thStyleone: {
    borderCollapse: 'collapse',
    borderBottom: '1px solid #dddd',
    textAlign: 'right',
    borderLeft: '1px solid #dddd',
    marginLeft: '20%',
  },
  droupdown: {
    border: 'none',
    borderRadius: '5px',
    width: '12.6rem',
    height: '2.1rem',
  },
  '@media only screen and (max-width: 940px) ': {
    dropdown: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    containerinvoice: {
      padding: SPACING.SPACE_10,
    },
  },
  createbutton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '2%',
    marginRight: '2%',
    marginTop: '-2.5%',
  },
  '@media only screen and (min-width: 940px) ': {
    tableContainor1: {
      padding: SPACING.SPACE_10,
    },
  },
  tablecontainer: {
    fontSize: '13px',
    borderRadius: 12,
    padding: '5px',
  },
  expiredtable: {
    width: '100%',
    borderCollapse: 'collapse',
    borderRadius: 12,
    // padding: '2%',
  },
  headhover: {
    borderBottom: '1px solid #393939',
  },
  tableheads: {
    // padding: '5rem',
    padding: '1rem',
    color: '#fc7300',
    fontSize: '13px',
    '&:hover': {
      backgroundColor: '#303030',
      borderRadius: '5px',
    },
  },
  paymenttext: {
    backgroundColor: '#FC7124',
    width: '12rem',
    height: '2rem',
    borderRadius: '2rem',
    textAlign: 'center',
    // position: 'relative',
    // top: '-2rem',
    marginTop: '-2rem',
    marginBottom: '1rem',
  },
  paymenttext1: {
    padding: '0.3rem',
  },
  tabledatahover: {
    '&:hover': {
      backgroundColor: '#303030',
    },
  },
})
const mapStateToProps = state => ({
  user_details: state.auth.user_details,
  adminUsers: state.dashboard.adminUsers,
  branches: state.branch.branchesData,
  adminTrainers: state.dashboard.adminTrainers,
  branchAdmins: state.dashboard.branchAdmins,
  PlansubscriptionsSussed: state.payments.PlansubscriptionsSussed,
  isPlansubscriptions: state.payments.isPlansubscriptions,
  PlansubscriptionsErr: state.payments.PlansubscriptionsErr,
  PlansubscriptionsErrMsg: state.payments.PlansubscriptionsErrMsg,
  plansubscription: state.payments.plansubscription,
})
const mapDispatchToProps = (dispatch) => {
  return {
    Plansubscription1: parms => dispatch(Plansubscriptions(parms)),
    fetchAllplansub: () => dispatch(fetchAllplansubscription()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(stylesheet)(Plansubscription))
