import AWS from 'aws-sdk'

export const S3_BUCKET = 'stamin-bucket'
export const REGION = 'ap-south-1'

AWS.config.update({
  accessKeyId: 'AKIARJ6YBKV4N347J42W',
  secretAccessKey: '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',
})

export const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
})
