export const prodEndpoints = {
  login: 'login',
  signUp: 'signUp',

}

// when working on staging environment IN_PRODUCTION is false
export const IN_PRODUCTION = true // process.env.NODE_ENV !== 'development'
export const domain = IN_PRODUCTION ? 'in' : 'dev'

export const baseUrl = `https://api.stamin.${domain}/`

// website url links
export const webBaseUrl = IN_PRODUCTION ? 'https://stamin.in/' : 'https://stamin.dev/'
