export const ADD_VIDEO = 'ADD_VIDEO'
export const ADD_VIDEO_SUCCESS = 'ADD_VIDEO_SUCCESS'
export const ADD_VIDEO_FAILURE = 'ADD_VIDEO_FAILURE'

export const EDIT_VIDEO = 'EDIT_VIDEO'
export const EDIT_VIDEO_SUCCESS = 'EDIT_VIDEO_SUCCESS'
export const EDIT_VIDEO_FAILURE = 'EDIT_VIDEO_FAILURE'

export const DELETE_VIDEO = 'DELETE_VIDEO'
export const DELETE_VIDEO_SUCCESS = 'DELETE_VIDEO_SUCCESS'
export const DELETE_VIDEO_FAILURE = 'DELETE_VIDEO_FAILURE'

export const GET_VIDEOS = 'GET_VIDEOS'
export const GET_VIDEOS_SUCCESS = 'GET_VIDEOS_SUCCESS'
export const GET_VIDEOS_FAILURE = 'GET_VIDEOS_FAILURE'
