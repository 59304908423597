import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { updatePassword, updateNumber, updatepic } from '../../containers/Auth/actions'
import {
  ICONS, SPACING, theme, COLORS,
} from '../../Theme'
import { withTheme } from '../../Theme/ThemeProvider'
import Button from '../../UI/Button'
import CustomInput from '../../UI/CustomInput'
import CustomModal from '../../UI/CustomModal'
import CustomText from '../../UI/CustomText'
import ScreenHeader from '../../UI/ScreenHeader'
import { showSnackbar } from '../../UI/Snackbar'
import Icon from '../../UI/Icon'
import { myBucket, S3_BUCKET } from '../../utils/awsConfig'
// import Icon from '../../UI/Icon'

const renderCell = (label, value = 'N/A', styles) => {
  return (
    <div className={styles.cardCell}>
      <CustomText weight="semi_bold" size="medium" color="orange">{label}</CustomText>
      <CustomText weight="medium" size="medium" color="white">{value}</CustomText>
    </div>
  )
}

function Profile({
  styles, user_details, branches, changePassword,
  isUpdatingPassword, updatePasswordError, updatePasswordErrorMsg, updatePasswordResp, changeNumber,
  isUpdatingNumber, updateNumberError, updateNumberErrorMsg, updateNumberResp, listType = '',
  isUploadPhot, isUploadPhotError, isUploadPhotErrorMsg, uploadPhotoSuc, ProfilePic,
}) {
  const [showChangePasswordModal, setShowChangePasswordModal] = React.useState(false)
  const [showChangePicModel, setrenderChangePic] = React.useState(false)
  const [oldPassword, setOldPassword] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [showChangePhonenumberModal, setShowChangePhonenumberModal] = React.useState(false)
  const [newPhone, setPhone] = React.useState('')
  const [images, setImages] = React.useState([])
  useEffect(() => {
    if (!isUploadPhot) {
      if (isUploadPhotError) {
        showSnackbar(isUploadPhotErrorMsg, {}, 0)
      } else if (!isEmpty(uploadPhotoSuc)) {
        showSnackbar('Profile Picture Updated', {}, 1)
        setrenderChangePic(false)
      }
    }
  }, [isUploadPhot])

  useEffect(() => {
    if (!isUpdatingPassword) {
      if (updatePasswordError) {
        showSnackbar(updatePasswordErrorMsg, {}, 0)
      } else if (!isEmpty(updatePasswordResp)) {
        showSnackbar('Password updated successfully', {}, 1)
        setShowChangePasswordModal(false)
      }
    }
  }, [isUpdatingPassword])

  useEffect(() => {
    if (!isUpdatingNumber) {
      if (updateNumberError) {
        showSnackbar(updateNumberErrorMsg, {}, 0)
      } else if (!isEmpty(updateNumberResp)) {
        showSnackbar('Phone number updated successfully', {}, 1)
        setShowChangePhonenumberModal(false)
      }
    }
  }, [isUpdatingNumber])

  const {
    last_diet_plan,
    name,
    plan_instance = {},
    // last_diet_plan_date,
    last_session_started,
    branch_id,
    phone,
    email,
    trainer,
    uid,
    profile_img,
  } = user_details
  const plan = plan_instance?.plan_type || 'N/A'
  let dataImg = null
  const trainerName = trainer?.name || 'N/A'
  const lastWorkoutDate = last_session_started ? dayjs(last_session_started * 1000).format('DD MMM YYYY') : 'N/A'
  // const lastDietPlanDate = last_diet_plan_date
  // ? new Date(last_diet_plan_date).toLocaleDateString() : 'N/A'

  const branch = branches.find((b) => {
    return b.value === branch_id
  })

  const branchName = branch ? branch.label.replace('(Nearest)', '') : 'N/A'
  const inputRef = React.createRef()
  const onPressChangeprofile = () => {
    uploadFile()
    if (isUploadPhotError) {
      showSnackbar('select profile pic')
    }
  }
  const onPressChangePassword = () => {
    if (password !== confirmPassword) {
      showSnackbar('Passwords do not match', {}, 0)
    } else {
      changePassword({ old: oldPassword, new: password })
    }
  }
  const onPressChangeNumber = () => {
    if (newPhone.length !== 10) {
      showSnackbar('Please enter a valid phone number', {}, 0)
    } else {
      changeNumber({ new: newPhone, uid })
    }
  }
  const onClickSelectFile = () => {
    inputRef.current.click()
  }
  const imgfunction = (e) => {
    const file = e.target.files[0]
    const fileReader = new FileReader()
    if (file.type.match('image')) {
      fileReader.onload = function test() {
        const img = document.createElement('img')
        img.src = e.target.result
        document.getElementById('profile').appendChild(img)
      }
      fileReader.readAsDataURL(file)
    }
  }

  const handleFileInput = (e) => {
    setImages([e.target.files[0]])
    dataImg = e.target.files[0]
    imgfunction(e)
    console.log(dataImg)
    /* let datafile = ''
    const reader = new FileReader()
    reader.onload = (event) => {
      console.log(event.target.result)
      datafile = event.target.result.toString()
    }
    console.log(datafile)
    reader.readAsDataURL(e.target.files[0])
    const file = e.target.files[0]
    const fileReader = new FileReader()
    if (file.type.match('image')) {
      console.log('file type matched')
      fileReader.onload = function* () {
        const img = document.createElement('img')
        img.src = e.target.result
        console.log(img.src)
        document.getElementById('profile').appendChild(img)
        yield
      }
      fileReader.readAsDataURL(file)
      console.log(images)
    } */
  }
  useEffect(() => {
    console.clear()
  }, [images])

  const uploadFile = () => {
    // this.thumbnail_image = document.getElementById('thumimg2').src
    // get thumnail image from the genrator
    if (!images) {
      if (!images) showSnackbar('Upload a image')
      return
    }
    const fileName = email + images[0].name
    const params = {
      ACL: 'public-read',
      Body: images[0],
      Bucket: S3_BUCKET,
      Key: fileName,
    }

    myBucket.putObject(params).on('httpUploadProgress')
      .send((err) => {
        if (err) { showSnackbar('Error uploading video') }
      })
    const imagurl = `https://stamin-bucket.s3.ap-south-1.amazonaws.com/${fileName}`
    ProfilePic({ uid, profile_img: imagurl })
    // window.location.reload()
  }

  const renderChangePasswordModal = () => {
    return (
      <CustomModal
        visible={showChangePasswordModal}
        onDismiss={() => setShowChangePasswordModal(false)}
      >
        <div className={styles.modalContainer}>
          <CustomText size="medium_1" weight="semi_bold">Change Password</CustomText>
          <div className={styles.modalContent}>
            <CustomInput
              value={oldPassword}
              onChangeText={e => setOldPassword(e.target.value)}
              label="Old Password"
              txt2="*"
              inputProps={{
                type: 'password',
              }}
              wrapperStyles={styles.inputWrapper}
              placeholder="Enter your old password"
            />
            <CustomInput
              value={password}
              onChangeText={e => setPassword(e.target.value)}
              label="New Password"
              //  change by vicky (lable Mandatory txt2="*")
              txt2="*"
              inputProps={{
                type: 'password',
              }}
              wrapperStyles={styles.inputWrapper}
              placeholder="Enter your new password"
            />
            <CustomInput
              value={confirmPassword}
              onChangeText={e => setConfirmPassword(e.target.value)}
              label="Confirm Password"
              txt2="*"
              inputProps={{
                type: 'password',
              }}
              wrapperStyles={styles.inputWrapper}
              placeholder="Confirm your new password"
            />
          </div>
          <Button text="Confirm" buttonColor="orange" onPress={onPressChangePassword} />
        </div>
      </CustomModal>
    )
  }
  const renderChangePic = () => {
    return (
      <CustomModal
        visible={showChangePicModel}
        onDismiss={() => setrenderChangePic(false)}
      >
        <div className={styles.modalContainer}>
          <CustomText size="medium_1" weight="semi_bold">Change Profile Pic</CustomText>
          <div>
            <button
              type="button"
              className={styles.uploadFileBtn}
              onClick={onClickSelectFile}
            >
              <Icon name={ICONS.UPLOAD} size={14} color={COLORS.GREEN} />
              <CustomText size="small" weight="semi_bold" color="green">Select File</CustomText>
            </button>
            {images && <CustomText size="small" weight="semi_bold" color="green">{images.name}</CustomText>}
            <input
              type="file"
              id="file"
              ref={inputRef}
              onChange={handleFileInput}
              accept="image/jpeg"
              style={{ display: 'none' }}
            />
          </div>
          <div id="profile" />
          <Button text="Confirm" buttonColor="orange" onPress={onPressChangeprofile} />
        </div>
      </CustomModal>
    )
  }
  const isAdmin = user_details?.type === 'admin'
  const viewmodel = listType
  const isMemberView = viewmodel === 'member'
  const renderChangeNumberModal = () => {
    return (
      <CustomModal
        visible={showChangePhonenumberModal}
        onDismiss={() => setShowChangePhonenumberModal(false)}
      >
        <div className={styles.modalContainer}>
          <CustomText size="medium_1" weight="semi_bold">Change Number</CustomText>
          <div className={styles.modalContent}>

            <CustomText weight="medium" size="medium">{phone}</CustomText>
            <CustomInput
              value={newPhone}
              onChangeText={e => setPhone(e.target.value)}
              label="New Number"
              txt2="*"
              inputRegx={/^[0-9]*$/}
              inputProps={{
                maxLength: 10,
              }}
              wrapperStyles={styles.inputWrapper}
              placeholder="Enter your new Number"
            />
          </div>
          <Button
            text="Change Number"
            buttonColor="orange"
            onPress={onPressChangeNumber}
            loading={isUpdatingNumber}
            disabled={isUpdatingNumber}
          />
        </div>
      </CustomModal>
    )
  }
  // const [selectedIcon, setSelectedIcon] = useState(null)
  // const [setIsImageClicked] = useState(false)

  /* const handleIconSelect = (event) => {
    const file = event.target.files[0]
    setSelectedIcon(URL.createObjectURL(file))
  }
   const handleClick = () => {
    setIsImageClicked(true)
  } */
  // console.log(profile_img)
  const isimg = profile_img !== undefined
  return (
    <div className={styles.container}>
      <ScreenHeader
        title="Profile"
      />
      {/* <input type="file" accept="image/*" onChange={handleIconSelect} /> */}
      <div className={styles.detailsContainer}>
        <div className={styles.details}>
          <div className={styles.detailsColumn} style={{ width: '18%' }}>
            <div className={styles.leftContainer}>
              <div>
                <button type="button" onClick={() => setrenderChangePic(true)}>
                  {!isimg ? (<Icon name={ICONS.PROFILE} size={60} className={styles.detailsicon} />)
                    : (<img src={profile_img} alt="Profile" className={styles.detailsimage} />)}
                </button>
              </div>
              <Button
                text="Change Password"
                mode="text"
                buttonColor="transparent"
                labelColor="orange"
                onPress={() => setShowChangePasswordModal(true)}
              />
              <Button
                text="Change Phone Number"
                mode="text"
                buttonColor="transparent"
                labelColor="orange"
                onPress={() => setShowChangePhonenumberModal(true)}
              />
            </div>

          </div>
          <div className={styles.detailsColumn}>
            {renderCell('Name', name, styles)}
            {renderCell('Branch', branchName, styles)}
            {isMemberView && renderCell('Trainer', trainerName, styles, isAdmin)}
            {isMemberView && renderCell('Last Workout', lastWorkoutDate, styles, isAdmin)}
          </div>
          <div className={styles.detailsColumn}>
            {renderCell('Phone', phone, styles)}
            {renderCell('Email', email, styles)}
            {isMemberView && renderCell('Plan', plan, styles, isAdmin)}
            {isMemberView && renderCell('Diet Plan', last_diet_plan, styles, isAdmin)}
          </div>

        </div>
      </div>
      {renderChangePasswordModal()}
      {renderChangeNumberModal()}
      {renderChangePic()}
    </div>
  )
}

const stylesheet = () => ({
  container: {
    padding: SPACING.SPACE_20,
  },
  detailsContainer: {
    backgroundColor: '#494949',
    border: '1px solid #ffcc80',
    borderRadius: 12,
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_20}`,
    width: '100%',
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: SPACING.SPACE_20,
  },
  details: {
    margin: `${SPACING.SPACE_20} 0`,
  },
  detailsColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: SPACING.SPACE_20,
    width: '40%',
  },
  cardProfile: {
    width: 100,
    height: 100,
    borderRadius: 12,
  },
  cardCell: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING.SPACE_8,
    margin: `${SPACING.SPACE_10} 0`,
  },
  uploadFileBtn: {
    display: 'flex',
    alignItems: 'center',
    gap: SPACING.SPACE_8,
    border: `1px solid ${theme.green}`,
    borderRadius: 8,
    padding: SPACING.SPACE_8,
    cursor: 'pointer',
    justifyContent: 'center',
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: SPACING.SPACE_20,
    padding: SPACING.SPACE_20,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: SPACING.SPACE_20,
    width: '100%',
  },
  inputWrapper: {
    width: '100%',
  },
  detailsimage: {
    borderRadius: '50%',
    border: 'clamp(3px, 0.4vw, 3px) solid whitesmoke',
    cursor: 'pointer',
    width: '140px',
    marginLeft: '10%',
    height: '140px',
  },
  detailsicon: {
    border: 'clamp(2px, 0.4vw, 4px) solid wheat',
    borderRadius: '50%',
    width: '110px',
    height: '110px',
    padding: '10%',
    marginLeft: '10%',
  },
  '@media only screen and (min-width: 780px) ': {
    details: {
      display: 'flex',
    },
  },
  '@media only screen and (max-width: 780px) ': {
    modalContent: {
      flexWrap: 'wrap',
    },
    detailsicon: {
      marginLeft: '50%',
    },
    detailsimage: {
      marginLeft: '40%',
    },
  },
})

const mapStateToProps = state => ({
  user_details: state.auth.user_details,
  branches: state.branch.branches,
  isUpdatingPassword: state.auth.isUpdatingPassword,
  updatePasswordError: state.auth.updatePasswordError,
  updatePasswordErrorMsg: state.auth.updatePasswordErrorMsg,
  updatePasswordResp: state.auth.updatePasswordResp,

  isUpdatingNumber: state.auth.isUpdatingNumber,
  updateNumberError: state.auth.updateNumberError,
  updateNumberErrorMsg: state.auth.updateNumberErrorMsg,
  updateNumberResp: state.auth.updateNumberResp,

  isUploadPhot: state.auth.isUploadPhot,
  isUploadPhotError: state.auth.isUploadPhotError,
  isUploadPhotErrorMsg: state.auth.isUploadPhotErrorMsg,
  uploadPhotoSuc: state.auth.uploadPhotoSuc,

})

const mapDispatchToProps = dispatch => ({
  changePassword: payload => dispatch(updatePassword(payload)),
  changeNumber: payload => dispatch(updateNumber(payload)),
  ProfilePic: payload => dispatch(updatepic(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(stylesheet)(Profile))
