import React from 'react'
import classNames from 'classnames'
import moment from 'moment'
import {
  SPACING,
  COLORS, ICONS, theme,
} from '../../Theme'
import Button from '../../UI/Button'
import AutoComplete from '../AutoComplete/AutoComplete'
import { withTheme } from '../../Theme/ThemeProvider'
// import CustomText from '../../UI/CustomText'
import Icon from '../../UI/Icon'
import CustomInput from '../../UI/CustomInput'
import DateTimePicker from '../../UI/DateTimePicker'

const notificationOptionsyear = [
  {
    label: 'month 416 ',
    value: ' 416',
    optionSize: 'medimum',
  },
  {
    label: 'year 4999 ',
    value: '4999',
    optionSize: 'medimum',
  },
]

function PlansubscriptionInvoice({
  styles,
  data,
  onSelectPlan,
  tableData,
  TotalAmount,
  handleAddRow,
  onCreateMembership,
  onChangeTextid,
  onChangeText,
  totalcgst,
  branches,
  totalamount2,
  onSelectUser,
  totalgst,
}) {
  const userOption = branches.map(item => ({
    label: `${item.name}`,
    value: item.id,
    mail: item.email,
    phone: item.phone,
  }))

  const planoptions = notificationOptionsyear.map(item => ({
    label: `${item.label}`,
    value: item.value,
  }))

  const renderDateInputone = (dateProps) => {
    return (
      <div
        className={classNames(styles.fieldInputContainer, styles.dateInputContainer)}
      >
        <input {...dateProps} className={classNames(styles.fieldInput, styles.dateInput)} />
        <Icon
          name={ICONS.CALENDAR}
          color={theme.textSecondary}
          size={13}
          className={styles.inputIcon}
        />
      </div>
    )
  }
  const yesterday = moment().subtract(1, 'day')
  const disablePastDt = (current) => {
    return current.isAfter(yesterday)
  }

  return(
    <div>
      <div className={styles.modelContainer}>
        <div className={styles.container}>
          <div className={styles.containerCreateInvoice}>
            <AutoComplete
              defaultLabel="Select User"
              selected={data.username}
              placeholder="Search User.."
              label="Select a user"
              changeKey="invoiceUser"
              options={userOption}
              onSelect={onSelectUser}
              inputStyles={styles.filterInput}
              inputBtnStyles={styles.filterInputBtn}
              containerStyles={styles.dropDownContainer}
              inputContainerStyles={styles.dropDownInput}
            />
            <CustomInput
              label="Enter Transaction Id"
              txt2="*"
              labelColor="white"
              value={data.transactionId}
              inputKey="transactionId"
              onChangeText={onChangeTextid}
              className={styles.input}
            />
            <CustomInput
              label="Phone No"
              labelColor="white"
              txt2="*"
              value={data.phone}
              disabled="true"
              className={styles.input}
            />
            <CustomInput
              label="Mail Id"
              txt2="*"
              labelColor="white"
              value={data.mail}
              disabled="true"
              className={styles.input}
            />
            <CustomInput
              label="Date"
              labelColor="white"
              txt2="*"
              value={data.date}
              disabled="true"
              className={styles.input}
            />
          </div>
        </div>
      </div>
      <br />
      <div className={styles.containerinvoice}>
        <table className={styles.tableContainor1}>
          <thead className={styles.tableStyle}>
            <tr>
              <th className={styles.thStyle} style={{ color: 'white', width: '10%' }}>S.No</th>
              <th className={styles.thStyle} style={{ color: 'white', width: '25%' }}>plan</th>
              <th className={styles.thStyle} style={{ color: 'white', width: '20%' }}>Validity From</th>
              <th className={styles.thStyle} style={{ color: 'white', width: '20%' }}>Validity To</th>
              <th className={styles.thStyle} style={{ color: 'white', width: '20%' }}>Amount</th>
              <th className={styles.thStyle} style={{ color: 'white', width: '10%' }}>Add</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map(row => (
              <tr key={row.id}>
                <td className={styles.thStyle} style={{ color: 'white' }}>{row.id}</td>
                <td className={styles.thStyle} style={{ color: 'white' }}>
                  <div className={styles.dropdown}>
                    <AutoComplete
                      defaultLabel="Select User"
                      selected={data.planname}
                      placeholder="Search User plan.."
                      options={planoptions}
                      onSelect={onSelectPlan}
                      inputStyles={styles.filterInput}
                      inputBtnStyles={styles.filterInputBtn}
                      containerStyles={styles.dropDownContainer}
                      inputContainerStyles={styles.dropDownInput}
                    />
                  </div>
                </td>
                <td className={styles.thStyle} style={{ color: 'white' }}>
                  <div className={styles.sessionTime}>
                    <DateTimePicker
                      props={{
                        timeFormat: false,
                        closeOnSelect: true,
                        dateFormat: 'DD MMM YYYY',
                        isValidDate: disablePastDt,
                        //  value: planstartdate,
                        renderInput: dateProps => renderDateInputone(dateProps),
                        // onChange: date => this.onSelectDate(date, null, 'planstartdate'),
                      }}
                    />
                  </div>
                </td>
                <td className={styles.thStyle} style={{ color: 'white' }}>
                  <div className={styles.sessionTime}>
                    <CustomInput
                      // value={end_date}
                      disabled="true"
                    />
                  </div>
                </td>
                <td className={styles.thStyle} style={{ color: 'white' }}>{data.amount}</td>
                <td className={styles.thStyle} style={{ color: 'white' }}>
                  <Button iconName="ADD_ROUND_OUTLINED" iconProps={{ color: COLORS.WHITE, size: 20 }} onPress={handleAddRow} />
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th className={styles.thStyleone} colSpan="4" style={{ color: 'white' }}>Discount Amount&nbsp;&nbsp;&nbsp;</th>
              <td className={styles.thStyle} style={{ color: 'white' }} colSpan="2">
                <CustomInput
                  labelColor="white"
                  value={data.discount}
                  onChangeText={onChangeText}
                  className={styles.inputamount}
                />
              </td>
            </tr>
            <tr>
              <th className={styles.thStyleone} colSpan="4" style={{ color: 'white' }}>Balance Payment&nbsp;&nbsp;&nbsp;</th>
              <td className={styles.thStyle} style={{ color: 'white' }} colSpan="2">
                <CustomInput
                  labelColor="white"
                  value={totalamount2}
                  disabled="true"
                />
              </td>
            </tr>
            <tr>
              <th className={styles.thStyleone} colSpan="4" style={{ color: 'white' }}>GST %&nbsp;&nbsp;&nbsp;</th>
              <td className={styles.thStyle} style={{ color: 'white' }} colSpan="2">
                <div className={styles.gstcontainer}>
                  <CustomInput
                    labelColor="white"
                    placeholder="CGST(9%)"
                    value={totalcgst}
                    disabled="true"
                  />
                  <CustomInput
                    labelColor="white"
                    placeholder="SGST(9%)"
                    value={totalcgst}
                    disabled="true"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th className={styles.thStyleone} colSpan="4" style={{ color: 'white' }}>GST Amount&nbsp;&nbsp;&nbsp;</th>
              <td className={styles.thStyle} style={{ color: 'white' }} colSpan="2">{totalgst}</td>
            </tr>
            <tr>
              <th className={styles.thStyleone} colSpan="4" style={{ color: 'white' }}>total Amount&nbsp;&nbsp;&nbsp;</th>
              <td className={styles.thStyle} style={{ color: 'white' }} colSpan="3">{TotalAmount}</td>
            </tr>
          </tfoot>
        </table>
        <div className={styles.modalFooter}>
          <Button
            text="Plan Added"
            buttonColor="orange"
            uppercase
            labelStyle={styles.createBtn}
            onPress={onCreateMembership}
          />
        </div>
      </div>
    </div>
  )
}
const stylesheet = () => ({
  containertable: {
    backgroundColor: '#494949',
    border: '1px solid #ffcc80',
    borderRadius: 12,
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_20}`,
    width: '100%',
  },
  tcontainer: {
    padding: `${SPACING.SPACE_20}`,
  },
  sessionTime: {
    display: 'flex',
    gap: SPACING.SPACE_20,
    flexDirection: 'column',
  },
  dateInputContainer: {
    // position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: 'white',
    borderRadius: 4,
    width: '100%',
  },
  dateInput: {
    border: `1px solid ${theme.inputBorder}`,
    // color: COLORS.ORANGE,
    // borderColor: theme.orangeLightBg,
    borderRadius: 4,
    backgroundColor: 'transparent',
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_10}`,
    boxSizing: 'border-box',
    zIndex: '9',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: theme.textSecondary,
      fontWeight: 300,
    },
  },
  inputIcon: {
    position: 'absolute',
    right: 10,
    zIndex: 1,
  },
  filterInputBtn: {
    backgroundColor: '#393939',
    color: 'white',
  },
  inputamount: {
    color: 'red',
  },
  gstcontainer: {
    display: 'flex',
    gap: '5%',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dropdown: {
    display: 'flex',
    gap: '5%',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: '#494949',
    borderRadius: 12,
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  subcontainer: {
    margin: '1rem',
    marginLeft: '2rem',
    display: 'flex',
    gap: '30px',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  containerinvoice: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    borderRadius: 12,
    border: '1px solid #ffcc80',
    backgroundColor: '#494949',
    padding: SPACING.SPACE_20,
    gap: SPACING.SPACE_30,
  },
  containerCreateInvoice: {
    display: 'flex',
    gap: '30px',
    justifyContent: 'space-evenly',
    margin: '30px',
    flexWrap: 'wrap',
  },
  modelContainer: {
    backgroundColor: '#494949',
    border: '1px solid #ffcc80',
    borderRadius: 12,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_20}`,
  },
  tableContainor1: {
    margin: `${SPACING.SPACE_10} 0`,
    width: '100%',
  },
  thStyle: {
    border: '1px solid #dddd',
    textAlign: 'center',
    padding: SPACING.SPACE_10,
  },
  tableStyle: {
    borderCollapse: 'collapse',
    border: '1px solid #dddd',
  },
  thStyleone: {
    borderCollapse: 'collapse',
    borderBottom: '1px solid #dddd',
    textAlign: 'right',
    borderLeft: '1px solid #dddd',
    marginLeft: '20%',
  },
  droupdown: {
    border: 'none',
    borderRadius: '5px',
    width: '12.6rem',
    height: '2.1rem',
  },
  '@media only screen and (max-width: 940px) ': {
    dropdown: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    containerinvoice: {
      padding: SPACING.SPACE_10,
    },
  },
  createbutton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '2%',
    marginRight: '2%',
    marginTop: '-2.5%',
  },
  '@media only screen and (min-width: 940px) ': {
    tableContainor1: {
      padding: SPACING.SPACE_10,
    },
  },
  tablecontainer: {
    fontSize: '13px',
    borderRadius: 12,
    padding: '5px',
  },
  expiredtable: {
    width: '100%',
    borderCollapse: 'collapse',
    borderRadius: 12,
    // padding: '2%',
  },
  headhover: {
    borderBottom: '1px solid #393939',
  },
  tableheads: {
    // padding: '5rem',
    padding: '1rem',
    color: '#fc7300',
    fontSize: '13px',
    '&:hover': {
      backgroundColor: '#303030',
      borderRadius: '5px',
    },
  },
  paymenttext: {
    backgroundColor: '#FC7124',
    width: '12rem',
    height: '2rem',
    borderRadius: '2rem',
    textAlign: 'center',
    // position: 'relative',
    // top: '-2rem',
    marginTop: '-2rem',
    marginBottom: '1rem',
  },
  paymenttext1: {
    padding: '0.3rem',
  },
  tabledatahover: {
    '&:hover': {
      backgroundColor: '#303030',
    },
  },
})

export default (withTheme(stylesheet)(PlansubscriptionInvoice))
