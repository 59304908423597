import { AUTH_STATES } from '../../utils/consts'
import {
  LOGIN_USER, LOGIN_USER_FAILURE, LOGIN_USER_SUCCESS,
  SIGN_UP, SIGN_UP_SUCCESS, SIGN_UP_FAILURE,
  FORGOT_PASSWORD, FORGOT_PASSWORD_FAILURE, FORGOT_PASSWORD_SUCCESS,
  AUTHENTICATE_TOKEN, AUTHENTICATE_TOKEN_FAILURE, AUTHENTICATE_TOKEN_SUCCESS,
  UPDATE_PASSWORD, UPDATE_PASSWORD_FAILURE, UPDATE_PASSWORD_SUCCESS,
  UPDATE_NUMBER, UPDATE_NUMBER_SUCCESS, UPDATE_NUMBER_FAILURE,
  UPDATE_PROFILE_IMG, UPDATE_PROFILE_IMG_SUCCESS, UPDATE_PROFILE_IMG_FAILURE,
  UPDATE_EMAIL_VERIFICATION, UPDATE_EMAIL_VERIFICATION_SUCCESS, UPDATE_EMAIL_VERIFICATION_FAILURE,
} from './actionTypes'

const initialState = {
  user_details: {},

  isAuthenticating: false,
  authenticateError: false,
  authenticateErrorMsg: '',
  authenticateResp: {},
  authState: '',

  isResettingPassword: false,
  resetPasswordError: false,
  resetPasswordErrorMsg: '',
  resetPasswordResp: {},

  isAuthenticatingToken: false,
  authenticateTokenError: false,
  authenticateTokenErrorMsg: '',
  tokenAuthenticated: false,

  isUpdatingPassword: false,
  updatePasswordError: false,
  updatePasswordErrorMsg: '',
  updatePasswordResp: {},

  isUpdatingEmailVerifiction: false,
  updatingEmailVerifiction: false,
  isUpdatingEmailVerifictionError: '',
  isUpdatingEmailVerifictionErrorMsg: {},
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        isAuthenticating: true,
        authenticateResp: {},
        authState: AUTH_STATES.LOGIN,
      }

    case LOGIN_USER_SUCCESS: {
      const { token, data } = action

      return {
        ...state,
        isAuthenticating: false,
        user_details: data,
        authenticateResp: { accessToken: token },
      }
    }

    case LOGIN_USER_FAILURE:
      return {
        ...state,
        isAuthenticating: false,
        authenticateError: true,
        authenticateErrorMsg: action.error,
      }

    case SIGN_UP:
      return {
        ...state,
        isAuthenticating: true,
        authenticateResp: {},
        authState: AUTH_STATES.SIGNUP,
      }

    case SIGN_UP_SUCCESS: {
      const { token, data } = action
      return {
        ...state,
        isAuthenticating: false,
        user_details: data,
        authenticateResp: { accessToken: token },
      }
    }

    case SIGN_UP_FAILURE:
      return {
        ...state,
        isAuthenticating: false,
        authenticateError: true,
        authenticateErrorMsg: action.error,
      }

    case FORGOT_PASSWORD:
      return {
        ...state,
        isResettingPassword: true,
      }

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isResettingPassword: false,
        resetPasswordResp: action.data,
      }

    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        isResettingPassword: false,
        resetPasswordError: true,
        resetPasswordErrorMsg: action.error,
      }

    case AUTHENTICATE_TOKEN:
      return {
        ...state,
        isAuthenticatingToken: true,
      }

    case AUTHENTICATE_TOKEN_SUCCESS: {
      const { data, token } = action
      return {
        ...state,
        isAuthenticatingToken: false,
        tokenAuthenticated: true,
        user_details: data,
        authenticateResp: { accessToken: token },
      }
    }

    case AUTHENTICATE_TOKEN_FAILURE:
      return {
        ...state,
        isAuthenticatingToken: false,
        authenticateTokenError: true,
        authenticateTokenErrorMsg: action.error,
      }

    case UPDATE_PASSWORD:
      return {
        ...state,
        isUpdatingPassword: true,
      }

    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isUpdatingPassword: false,
        updatePasswordResp: action.data,
      }

    case UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        isUpdatingPassword: false,
        updatePasswordError: true,
        updatePasswordErrorMsg: action.error,
      }
    case UPDATE_NUMBER:
      return {
        ...state,
        isUpdatingNumber: true,
      }

    case UPDATE_NUMBER_SUCCESS:
      return {
        ...state,
        isUpdatingNumber: false,
        updateNumberResp: action.data,
        user_details: {
          ...state.user_details,
          ...action.data,
        },
      }

    case UPDATE_NUMBER_FAILURE:
      return {
        ...state,
        isUpdatingNumber: false,
        updateNumberError: true,
        updateNumberErrorMsg: action.error,
      }
    case UPDATE_PROFILE_IMG:
      return {
        ...state,
        isUploadPhot: true,
      }

    case UPDATE_PROFILE_IMG_SUCCESS:
      return {
        ...state,
        isUploadPhot: false,
        uploadPhotoSuc: action.data,
        user_details: {
          ...state.user_details,
          ...action.data,
        },
      }

    case UPDATE_PROFILE_IMG_FAILURE:
      return {
        ...state,
        isUploadPhot: false,
        isUploadPhotError: true,
        isUploadPhotErrorMsg: action.error,
      }
    case UPDATE_EMAIL_VERIFICATION:
      return {
        ...state,
        isUpdatingEmailVerifiction: true,
      }

    case UPDATE_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        isUpdatingEmailVerifiction: false,
        updatingEmailVerifiction: action.data,
        user_details: {
          ...state.user_details,
          ...action.data,
        },
      }

    case UPDATE_EMAIL_VERIFICATION_FAILURE:
      return {
        ...state,
        isUpdatingEmailVerifiction: false,
        isUpdatingEmailVerifictionError: true,
        isUpdatingEmailVerifictionErrorMsg: action.error,
      }
    default:
      return state
  }
}

export default auth
