import {
  ADD_USER, ADD_USER_FAILURE, ADD_USER_SUCCESS,
  GET_UNAUTH_USERS, GET_UNAUTH_USERS_SUCCESS, GET_UNAUTH_USERS_FAILURE,
  ASSIGN_TRAINER, ASSIGN_TRAINER_SUCCESS, ASSIGN_TRAINER_FAILURE,
  DELETE_USER, DELETE_USER_SUCCESS, DELETE_USER_FAILURE,
  EDIT_USER, EDIT_USER_SUCCESS, EDIT_USER_FAILURE,
  ASSIGN_PLANS, ASSIGN_PLANS_SUCCESS, ASSIGN_PLANS_FAILURE,
} from './actionTypes'

const initialState = {
  unauthTrainers: [],
  unauthMembers: [],
  unauthbranchadmin: [],
  unauthalluser: [],

  isAddingUser: false,
  addUserErr: false,
  addUserErrMsg: '',
  userAdded: false,

  isGettingUnauthTrainers: false,
  getUnauthTrainersErr: false,
  getUnauthTrainersErrMsg: '',

  isDeletingUser: false,
  deleteUserErr: false,
  deleteUserErrMsg: '',
  userDeleted: false,

  isAssigningTrainer: false,
  assignTrainerErr: false,
  assignTrainerErrMsg: '',
  trainerAssigned: false,

  isAssigningPlan: false,
  assignPlanErr: false,
  assignPlanErrMsg: '',
  planAssigned: false,

  isEditingUser: false,
  editUserErr: false,
  editUserErrMsg: '',
  userEdited: false,
}

export default function trainers(state = initialState, action) {
  switch (action.type) {
    case ADD_USER: {
      return {
        ...state,
        isAddingUser: true,
      }
    }

    case ADD_USER_SUCCESS: {
      return {
        ...state,
        isAddingUser: false,
        userAdded: true,
      }
    }

    case ADD_USER_FAILURE: {
      return {
        ...state,
        isAddingUser: false,
        addUserErr: true,
        addUserErrMsg: action.error,
      }
    }

    case GET_UNAUTH_USERS: {
      return {
        ...state,
        isGettingUnauthTrainers: true,
      }
    }

    case GET_UNAUTH_USERS_SUCCESS: {
      const { data } = action
      const dataWithUid = data.map((user) => {
        const uid = user.email.split('@')[0]
        return {
          ...user,
          uid,
          unauth: true,
        }
      })
      const unauthall = dataWithUid
      const unauthMembers = dataWithUid.filter(user => user.type === 'member')
      const unauthTrainers = dataWithUid.filter(user => user.type === 'trainer')
      const unauth_branchadmin = dataWithUid.filter(user => user.type === 'branch_admin') // getting the unauthu branch admin
      return {
        ...state,
        isGettingUnauthTrainers: false,
        unauthTrainers,
        unauthMembers,
        unauth_branchadmin,
        unauthalluser: unauthall,
      }
    }

    case GET_UNAUTH_USERS_FAILURE: {
      return {
        ...state,
        isGettingUnauthTrainers: false,
        getUnauthTrainersErr: true,
        getUnauthTrainersErrMsg: action.error,
      }
    }

    case DELETE_USER:
      return {
        ...state,
        isDeletingUser: true,
        userDeleted: false,
      }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        isDeletingUser: false,
        userDeleted: true,
      }
    case DELETE_USER_FAILURE:
      return {
        ...state,
        isDeletingUser: false,
        deleteUserErr: true,
        deleteUserErrMsg: action.error,
      }

    case ASSIGN_TRAINER:
      return {
        ...state,
        isAssigningTrainer: true,
        trainerAssigned: false,
      }

    case ASSIGN_TRAINER_SUCCESS:
      return {
        ...state,
        isAssigningTrainer: false,
        trainerAssigned: true,
      }

    case ASSIGN_TRAINER_FAILURE:
      return {
        ...state,
        isAssigningTrainer: false,
        assignTrainerErr: true,
        assignTrainerErrMsg: action.error,
      }

    case ASSIGN_PLANS:
      return {
        ...state,
        isAssigningPlan: true,
        planAssigned: false,
      }

    case ASSIGN_PLANS_SUCCESS:
      return {
        ...state,
        isAssigningPlan: false,
        planAssigned: true,
      }

    case ASSIGN_PLANS_FAILURE:
      return {
        ...state,
        isAssigningPlan: false,
        assignPlanErr: true,
        assignPlanErrMsg: action.error,
      }

    case EDIT_USER:
      return {
        ...state,
        isEditingUser: true,
        userEdited: false,
      }

    case EDIT_USER_SUCCESS:
      return {
        ...state,
        isEditingUser: false,
        userEdited: true,
      }

    case EDIT_USER_FAILURE:
      return {
        ...state,
        isEditingUser: false,
        editUserErr: true,
        editUserErrMsg: action.error,
      }

    default:
      return state
  }
}
