import React, { useEffect } from 'react'
import { connect } from 'react-redux'
// import JsPdf from 'jspdf'
import { withTheme } from '../../Theme/ThemeProvider'
import CustomText from '../../UI/CustomText'
import {
  SPACING,
} from '../../Theme'
import { fetchAllplansubscription } from '../../containers/Payments/actions'
import InvoiceTable from './InvoiceTable'

function Subscriptiontable({
  styles, plansubscription, fetchAllplansub, user_details,
}) {
  useEffect(() => {
    fetchAllplansub()
  }, [])

  const branchdata = plansubscription.filter(user => user.branchid === user_details.branch_id)

  const openMemberDetails = (user) => {
    console.log(user)
  }

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.paymenttext}>
          <CustomText size="medium" className={styles.paymenttext1} color="white" weight="semi_bold">
            Payment History
          </CustomText>
        </div>
        <InvoiceTable
          branchdata={branchdata}
          openMemberDetails={openMemberDetails}
        />
      </div>
    </div>
  )
}
const stylesheet = {

  container: {
    backgroundColor: '#494949',
    // border: '1px solid #ffcc80',
    borderRadius: 12,
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_20}`,
    width: '100%',
    height: '100%',
  },
  paymenttext: {
    backgroundColor: '#FC7124',
    width: '12rem',
    height: '2rem',
    borderRadius: '2rem',
    textAlign: 'center',
    position: 'relative',
    // top: '-2rem',
    marginTop: '-2rem',
    marginBottom: '1rem',
  },
  paymenttext1: {
    padding: '0.3rem',
  },

  expiredtable: {
    width: '100%',
    borderCollapse: 'collapse',
    borderRadius: 12,
    // padding: '2%',
  },
  tableheads: {
    // padding: '5rem',
    padding: '1rem',
    color: '#fc7300',
    fontSize: '13px',
    '&:hover': {
      backgroundColor: '#303030',
      borderRadius: '5px',
    },
  },
  headhover: {
    borderBottom: '1px solid #393939',
  },
  tabledatahover: {
    '&:hover': {
      backgroundColor: '#303030',
    },
  },
  tabletd: {
    padding: '1.2%',
  },
  closedbutton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-1%',
    marginRight: '2%',
  },
}
const mapStateToProps = state => ({
  user_details: state.auth.user_details,
  plansubscription: state.payments.plansubscription,
})
const mapDispatchToProps = dispatch => ({
  fetchAllplansub: () => dispatch(fetchAllplansubscription()),
})

export default
connect(mapStateToProps, mapDispatchToProps)(withTheme(stylesheet)(Subscriptiontable))
