import {
  GET_ASSIGNED_MEMBERS, GET_ASSIGNED_MEMBERS_FAILURE, GET_ASSIGNED_MEMBERS_SUCCESS,
  CREATE_DIET_PLAN, CREATE_DIET_PLAN_FAILURE, CREATE_DIET_PLAN_SUCCESS,
} from './actionTypes'

export function getAssignedMembers(params) {
  return {
    type: GET_ASSIGNED_MEMBERS,
    params,
  }
}

export function getAssignedMembersSuccess(members) {
  return {
    type: GET_ASSIGNED_MEMBERS_SUCCESS,
    data: members,
  }
}

export function getAssignedMembersFailure(error) {
  return {
    type: GET_ASSIGNED_MEMBERS_FAILURE,
    error,
  }
}

export function createDietPlan(params) {
  return {
    type: CREATE_DIET_PLAN,
    params,
  }
}

export function createDietPlanSuccess() {
  return {
    type: CREATE_DIET_PLAN_SUCCESS,
  }
}

export function createDietPlanFailure(error) {
  return {
    type: CREATE_DIET_PLAN_FAILURE,
    error,
  }
}
