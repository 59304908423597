import {
  doc, setDoc, getDocs, collection, getDoc, deleteDoc, updateDoc,
} from 'firebase/firestore'
import { call, put, takeLatest } from 'redux-saga/effects'
import { error_msg, userProfile } from '../../utils/consts'
import { db } from '../../utils/firebaseConfig'
import { GET_ALL_USERS } from '../Dashboard/actionTypes'
import {
  addUserFailure, addUserSuccess,
  getUnauthUsersFailure, getUnauthUsersSuccess,
  deleteUserFailure, deleteUserSuccess,
  assignTrainerFailure, assignTrainerSuccess,
  editUserFailure, editUserSuccess,
  assingPlanFailure, assingPlanSuccess,
} from './actions'
import {
  ADD_USER, DELETE_USER, GET_UNAUTH_USERS, ASSIGN_TRAINER, EDIT_USER, ASSIGN_PLANS,
} from './actionTypes'

function* addUser(action) {
  try {
    const {
      params: {
        email, name, phone, branch_id, type = 'member',
      },
    } = action

    const userData = {
      ...userProfile,
      email,
      name,
      phone,
      branch_id,
      type,
      plan_instance: [],
      diet: [],
    }

    const userid = email.split('@')[0].toLowerCase()

    const data = yield call(setDoc, doc(db, 'unauthUsers', userid), userData)
    if (data && data.error) {
      yield put(addUserFailure(data))
    } else {
      yield put(addUserSuccess(userData))
      yield put({ type: GET_UNAUTH_USERS })
    }
  } catch (err) {
    yield put(addUserFailure(err.message || error_msg))
  }
}

export function* addUserSaga() {
  yield takeLatest(ADD_USER, addUser)
}

function* getUnauthUsers() {
  try {
    const data = yield call(getDocs, collection(db, 'unauthUsers'))
    if (data && data.error) {
      yield put(getUnauthUsersFailure(data))
    } else {
      const trainers = []
      data.forEach((d) => {
        trainers.push(d.data())
      })
      yield put(getUnauthUsersSuccess(trainers))
    }
  } catch (err) {
    yield put(getUnauthUsersFailure(err.message || error_msg))
  }
}

export function* getUnauthUsersSaga() {
  yield takeLatest(GET_UNAUTH_USERS, getUnauthUsers)
}

function* deleteUser({ params }) {
  try {
    const { uid, unauth } = params
    console.log(uid, unauth)
    let q = doc(db, 'users', uid)
    if(unauth) {
      q = doc(db, 'unauthUsers', uid)
    }
    const data = yield call(deleteDoc, q)
    if (data && data.error) {
      yield put(deleteUserFailure(data.error))
    } else {
      yield put({ type: GET_ALL_USERS })
      yield put({ type: GET_UNAUTH_USERS })
      yield put(deleteUserSuccess(data))
    }
  } catch (err) {
    yield put(deleteUserFailure(err.message || error_msg))
  }
}

export function* deleteUserSaga() {
  yield takeLatest(DELETE_USER, deleteUser)
}

function* assignTrainer({ params }) {
  try {
    const { uid, trainer } = params

    const q = doc(db, 'users', uid)
    const data = yield call(updateDoc, q, { trainer })

    if (data && data.error) {
      yield put(assignTrainerFailure(data.error))
    } else {
      yield put(assignTrainerSuccess(data))
    }
  } catch (err) {
    yield put(assignTrainerFailure(err.message || error_msg))
  }
}

export function* assignTrainerSaga() {
  yield takeLatest(ASSIGN_TRAINER, assignTrainer)
}

function* editUser({ params }) {
  try {
    const { uid, unauth = false } = params
    const q = unauth ? doc(db, 'unauthUsers', uid) : doc(db, 'users', uid)
    delete params.unauth
    const res = yield call(updateDoc, q, params)

    if (res && res.error) {
      yield put(editUserFailure(res.error))
    } else {
      yield put({ type: GET_ALL_USERS })
      yield put({ type: GET_UNAUTH_USERS })
      yield put(editUserSuccess(res))
    }
  } catch (err) {
    yield put(editUserFailure(err.message || error_msg))
  }
}

export function* editUserSaga() {
  yield takeLatest(EDIT_USER, editUser)
}

function* assingplan({ params }) {
  try {
    const { uid, plan_instnce } = params
    const user = yield call(getDoc, doc(db, 'users', uid))

    if (!user.exists()) {
      yield put(assingPlanFailure('Auth error'))
      return
    }

    const { plan_instance: old_instance = [] } = user.data()
    const newPI = [...old_instance, ...plan_instnce]
    const data = yield call(updateDoc, doc(db, 'users', uid), { plan_instance: newPI })
    if (!data?.error) {
      yield put({ type: GET_ALL_USERS })
      yield put(assingPlanSuccess(data))
    } else {
      yield put(assingPlanFailure(data))
    }
  } catch (err) {
    yield put(assingPlanFailure(err.message || error_msg))
  }
}

export function* assingplanSaga() {
  yield takeLatest(ASSIGN_PLANS, assingplan)
}
