import React, { useState, useCallback } from 'react'
import debounce from 'lodash/debounce'

import AutoComplete from './AutoComplete'

const AutoCompleteBase = ({
  onSelect,
  options,
  selected = '',
  defaultLabel = '',
  placeholder = '',
  inputTextStyles,
  inputStyles,
  itemBtnStyles = '',
  label = '',
  inputBtnStyles,
  containerStyles,
  inputContainerStyles,
  changeKey,
  labelcolor,
}) => {
  const [value, updateValue] = useState('')
  const [opts, updateOptions] = useState(options)
  const debouncedHandler = useCallback(debounce(v => updateOpts(v), 150), [])
  const updateOpts = (query) => {
    if (query) {
      const filteredOptions = options.filter((branch) => {
        const { label: name } = branch
        return name.toLowerCase().includes(query.toLowerCase())
      })
      updateOptions(filteredOptions)
      return
    }
    updateOptions(options)
  }

  const onSearch = (e) => {
    const { value: query } = e.target
    updateValue(query)
    debouncedHandler(query)
  }
  return (
    <AutoComplete
      onSearch={onSearch}
      searchValue={value}
      options={opts}
      onSelect={onSelect}
      changeKey={changeKey}
      selected={selected}
      defaultLabel={defaultLabel}
      placeholder={placeholder}
      inputTextStyles={inputTextStyles}
      inputStyles={inputStyles}
      itemBtnStyles={itemBtnStyles}
      label={label}
      labelcolor={labelcolor}
      inputBtnStyles={inputBtnStyles}
      containerStyles={containerStyles}
      inputContainerStyles={inputContainerStyles}
    />
  )
}

export default AutoCompleteBase
