import {
  DIMENSIONS, ICONS,
} from '../Theme'
import { pathname_mapping } from './navigationConstant'

const { HEIGHT, WIDTH } = DIMENSIONS

export const vw = WIDTH / 375
export const vh = HEIGHT / 812
export const tabViewHeight = 288 * vh

export const CURRENT_APP_VERSION = '0.0.1'

export const CURRENT_APP_VERSION_NUMBER = 1

export const CURRENCY_SYMBOL = '₹'

export const stateMap = [
  'Andaman & Nicobar Islands',
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chandigarh',
  'Chhattisgarh',
  'Dadra & Nagar Haveli',
  'Daman & Diu',
  'Delhi',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu and Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Lakshadweep',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Puducherry',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal',
]

export const lightBoxProps = {
  style: {
    tapBackgroundToDismiss: true,
    backgroundColor: 'rgba(0,0,0,.4)',
  },
}

export const getValueFromKey = (object, value) => {
  return Object.keys(object).find(key => object[key] === value)
}

export const platformKeys = {
  android: 0,
  ios: 1,
  websocket: 2,
  chrome: 3,
  firefox: 5,
}

export const error_msg = 'Error getting response'

export const WELCOME_VIEWS = {
  LOGIN: 'login',
  SIGNUP: 'signup',
  TWO_FA: '2fa',
  FORGOT_PASSWORD: 'forgot_password',
  RESET_PASSWORD: 'reset_password',
  LOADING: 'loading',
  VALIDATE_EMAIL: 'validate_email',
  NO_INTERNET: 'no_internet',
}

export const decimalRegex = /^\d*\.?\d*$/
export const decimalRegexAll = /^-?\d*\.?\d*$/

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const phoneNumberRegex = /^[0-9]{10}$/

export const DISPLAY_DEVICE = {
  MOBILE: {
    value: 0, valueKey: 'mobile',
  },
  TAB: {
    value: 1, valueKey: 'tab',
  },
  DESKTOP: {
    value: 2, valueKey: 'desktop',
  },
  FHD: {
    value: 3, valueKey: '2k',
  },
  UHD: {
    value: 4, valueKey: '4k',
  },
}

export const PREFERENCES = {
  SHOW_ANIMATION: 'animations',
  THEME: 'theme',
  KEY: 'preferences',
}

export const THEME_TYPE_MAP = {
  DARK: {
    label: 'Dark', value: 'DARK', icon: ICONS.MOON_CRESCENT_FILLED, iconSize: 13, iconColor: 'TEXT', optionSize: 'small',
  },
  LIGHT: {
    label: 'Light', value: 'LIGHT', icon: ICONS.SUN, iconSize: 13, iconColor: 'TEXT', optionSize: 'small',
  },
  AUTO: {
    label: 'Auto', value: 'AUTO', icon: ICONS.MOON_CRESCENT_OUTLINED_FILLED, iconSize: 13, iconColor: 'TEXT', optionSize: 'small',
  },
}

export const SESSION_STATUS_MAP = {
  '-1': {
    label: 'Cancelled', value: -1, color: 'red',
  },
  0: {
    label: 'Pending', value: 0, color: '#ffff00', // orange
  },
  1: {
    label: 'Complete', value: 1, color: '#00cc00', // green
  },
  2: {
    label: 'Upcoming', value: 2, color: '#ffff00', // blue
  },
}

export const PAYMENT_STATUS = {
  TXN_FAILURE: { label: 'Failed', value: 0, color: 'red' },
  TXN_SUCCESS: { label: 'Paid', value: 1, color: 'green' },
  PENDING: { label: 'Pending', value: 2, color: 'orange' },
}

export const USER_MENU = [
  {
    title: 'Home', page: pathname_mapping.Dashboard,
  },
  {
    title: 'Your Sessions', page: pathname_mapping.Sessions,
  },
  {
    title: 'Plans & Orders ', page: pathname_mapping.Payments,
  },
  {
    title: 'Your Trainer', page: pathname_mapping.YourTrainer,
  },
  {
    title: 'Your Diet Plan', page: pathname_mapping.DietPlan,
  },
  /* {
   title: 'Plans', page: pathname_mapping.Plans,
  }, */
  {
    title: 'Your Workouts', page: pathname_mapping.AssignWorkoutPlan,
  },
]

export const TRAINER_MENU = [
  {
    title: 'Home', page: pathname_mapping.Dashboard,
  },
  {
    title: 'Sessions', page: pathname_mapping.Sessions,
  },
  {
    title: 'Assigned Members', page: pathname_mapping.AssignedMembers,
  },
  {
    title: 'Chats', page: pathname_mapping.Chats,
  },
  {
    title: 'Videos', page: pathname_mapping.Videos,
  },
]

export const ADMIN_MENU = [
  {
    title: 'Home', page: pathname_mapping.Dashboard,
  },
  {
    title: 'Sessions', page: pathname_mapping.Sessions,
  },
  {
    title: 'Members', page: pathname_mapping.Members,
  },
  {
    title: 'Branch Admin', page: pathname_mapping.BranchAdmin, // Menu created for branchadmin
  },
  {
    title: 'Trainers', page: pathname_mapping.Trainers,
  },
  {
    title: 'Videos', page: pathname_mapping.Videos,
  },
  {
    title: 'Payments', page: pathname_mapping.Payments,
  },
  {
    title: 'Plans', page: pathname_mapping.PlansDashboard,
  },
  {
    title: 'Branches', page: pathname_mapping.Branches,
  },
  {
    title: 'Report', page: pathname_mapping.Report,
  },
  {
    title: 'QRCODE', page: pathname_mapping.QRcode,
  },
  {
    title: 'Plan subscription', page: pathname_mapping.Plansubscription,
  },
]

export const BRANCH_ADMIN_MENU = [
  {
    title: 'Home', page: pathname_mapping.Dashboard,
  },
  {
    title: 'Sessions', page: pathname_mapping.Sessions,
  },
  {
    title: 'Members', page: pathname_mapping.Members,
  },
  {
    title: 'Trainers', page: pathname_mapping.Trainers,
  },
  {
    title: 'Payments', page: pathname_mapping.Payments,
  },
  {
    title: 'Videos', page: pathname_mapping.Videos,
  },
  {
    title: 'Plans', page: pathname_mapping.PlansDashboard,
  },
  {
    title: 'Plan subscription', page: pathname_mapping.Plansubscription,
  },
  {
    title: 'Report', page: pathname_mapping.Report,
  },
]

export const WORKOUTS = [{
  label: 'HIIT',
  value: 'hiit',
},
{
  label: 'Yoga',
  value: 'yoga',
},
{
  label: 'Cardio',
  value: 'cardio',
},
{
  label: 'Weight Training',
  value: 'weight_training',
},
{
  label: 'Pilates',
  value: 'pilates',
},
{
  label: 'Zumba',
  value: 'zumba',
},
{
  label: 'Cardio',
  value: 'cardio',
},
]

export const WORKOUTS_MAP = WORKOUTS.reduce((acc, curr) => {
  acc[curr.value] = curr.label
  return acc
},
{})

export const AUTH_STATES = {
  LOGIN: 'login',
  SIGNUP: 'signup',
  FORGOT_PASSWORD: 'forgotPassword',
}

export const userProfile = {
  email: '',
  name: '',
  uid: '',
  phone: '',
  branch_id: '',
  created_at: '',
  type: '',
  trainer: {},
  plan_instance: [],
}

export const PAYMENT_METHODS = [
  {
    label: 'Paytm',
    value: 'PAYTM',
  },
  {
    label: 'Google Pay',
    value: 'GOOGLE_PAY',
  },
  {
    label: 'PhonePe',
    value: 'PHONEPE',
  },
  {
    label: 'Other UPI',
    value: 'OTHER_UPI',
  },
  {
    label: 'Debit/Credit Card',
    value: 'DEBIT_CREDIT_CARD',
  },
  {
    label: 'Net Banking',
    value: 'NET_BANKING',
  },
  {
    label: 'Cash',
    value: 'CASH',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
]
