import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
// import classNames from 'classnames'
import { withTheme } from '../../Theme/ThemeProvider'
import { UpdateEmailverifaction } from '../../containers/Auth/actions'
import Button from '../../UI/Button'
import { pathname_mapping } from '../../utils/navigationConstant'
import { showSnackbar } from '../../UI/Snackbar'

// Import your CSS file for styling

function Verificationpage({
  styles, user_details, emailVerificationStatus,
  isUpdatingEmailVerifiction, updatingEmailVerifiction,
  isUpdatingEmailVerifictionError, isUpdatingEmailVerifictionErrorMsg,
}) {
  const history = useHistory()
  const [sotp, setsOtp] = useState('')
  const [cannavigate, setcanNavigate] = useState(false)
  const [showresentotp, setShowResentText] = useState(false)
  const {
    location: {
      state,
    },
  } = history
  const isOnboarding = state.isOnBoarding
  const remail = state.email
  console.log(isUpdatingEmailVerifiction, isUpdatingEmailVerifictionError, updatingEmailVerifiction)
  useEffect(() => {
    console.log(isUpdatingEmailVerifiction)
    if(!isUpdatingEmailVerifiction && cannavigate) {
      if(isUpdatingEmailVerifictionError) {
        showSnackbar(isUpdatingEmailVerifictionErrorMsg, {}, 0)
      } else if(!isEmpty(updatingEmailVerifiction)) {
        showSnackbar('Email Verified Sucessfully', {}, 1)
        if(isOnboarding) {
          history.push({
            pathname: pathname_mapping.ProfileBMI,
            state: { isOnboarding },
          })
        } else{
          history.push({ pathname: pathname_mapping.Dashboard })
        }
      }
    }
  }, [isUpdatingEmailVerifiction])
  console.log(state)

  const sendmail = (data) => {
    return fetch('https://us-central1-stamin-98728.cloudfunctions.net/app/api/emailVerificationOTP', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Allow-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .catch(err => console.log(err))
  }

  const ResentOTP = () => {
    const { email } = user_details
    const otp = Math.floor(100000 + Math.random() * 900000)
    const data = {
      email,
      otp,
    }
    sendmail(data).then((response) => {
      console.log(response)
      showSnackbar('OTP Resent to your Mail Succesfully', {}, 0)
      history.push({
        pathname: pathname_mapping.Verificationpage,
        state: { otp, isOnboarding, email },
      })
    }).catch((err) => {
      showSnackbar(err, {}, 1)
    })
  }
  const VerifyOTP = () => {
    const { email } = user_details
    console.log('sotp', sotp)
    console.log('State OTP', Number(state.otp))
    console.log('remail', remail)
    console.log('email', Number(sotp) === Number(state.otp))
    if(Number(sotp) === Number(state.otp) & remail === email) {
      const params = {
        email,
        email_verification: true,
      }
      emailVerificationStatus(params)
      setcanNavigate(true)
    } else {
      showSnackbar('OTP Expried')
      setShowResentText(true)
    }
  }
  const handleChange = (e) => {
    const value = e.target.value
    if (value.length <= 6) {
      setsOtp(value)
    }
  }

  return(
    <div className={styles.otpcontainer}>
      {showresentotp && (
        <h2>OTP Expired a new OTP sent To your mail</h2>
      )}
      <h2>Enter 6-digit OTP</h2>
      <input
        type="number"
        value={sotp}
        onChange={handleChange}
        maxLength="6"
        className={styles.otpinput}
      />
      <br />
      <Button
        text="Verify"
        buttonColor="orange"
        btnStyles={styles.bookSessionBtn}
        onPress={VerifyOTP}
      />
      <br />
      <Button
        text="Resent OTP"
        buttonColor="orange"
        btnStyles={styles.bookSessionBtn}
        onPress={ResentOTP}
      />
    </div>
  )
}
const stylesheet = {
  container: {
    width: '100vh',
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    Justifycontent: 'center',
  },
  otpcontainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '20px',
  },
  otpinput: {
    width: 180,
    height: 40,
    fontSize: 18,
    textAlign: 'center',
    marginTop: 10,
    borderWidth: 1,
    borderColor: '#CED4DA',
    borderRadius: 5,
    outline: 'none',
  },
}
const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
    isUpdatingEmailVerifiction: state.auth.isUpdatingEmailVerifiction,
    updatingEmailVerifiction: state.auth.updatingEmailVerifiction,
    isUpdatingEmailVerifictionError: state.auth.isUpdatingEmailVerifictionError,
    isUpdatingEmailVerifictionErrorMsg: state.auth.isUpdatingEmailVerifictionErrorMsg,
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
    emailVerificationStatus: parms => dispatch(UpdateEmailverifaction(parms)),
  }
}

export default withTheme(stylesheet)(
  connect(mapStateToProps, mapDispatchToProps)(Verificationpage),
)
