import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'

import {
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom'
import 'react-datetime/css/react-datetime.css'
import moment from 'moment'

// import ScreenHeader from './components/ScreenHeader'
import { isEmpty } from 'lodash'
// import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import Common from './containers/Common/Common-redux'
import PageLoader from './UI/PageLoader'
import SnackBar, { showSnackbar } from './UI/Snackbar'
import SideBar from './components/SideBar'
import AuthenticatedRoutes, { AuthRoutes } from './AuthRoutes'
import UnAuthenticatedRoutes, { UnauthRoutes } from './UnauthenticatedRoutes'

import { withTheme } from './Theme/ThemeProvider'
import { pathname_mapping } from './utils/navigationConstant'
import {
  AUTH_STATES,
  DISPLAY_DEVICE,
  PREFERENCES,
} from './utils/consts'
import Button from './UI/Button'
import {
  deleteCookies,
  // getCookies,
  getLocalStorage, parseQuery,
} from './utils/common'
import {
  SPACING, theme, COLORS, ASSETS, ICONS,
} from './Theme'
import { BAR_TYPE_MAP } from './components/SideBar/SideBar'
import ProfileImage from './UI/ProfileImage'
import { authenticateToken } from './containers/Auth/actions'
import ModalDropdown from './UI/Dropdown/ModalDropdown'
import CustomText from './UI/CustomText'
import { auth } from './utils/firebaseConfig'
import Icon from './UI/Icon'
// import SideBarMobile from './components/SideBar/SideBarMobile'
const { crown } = ASSETS

const profileOptions = [
  {
    label: 'View Profile', name: 'profile', icon: ICONS.PROFILE, iconSize: 12, iconColor: 'BLUE', optionSize: 'small',
  },
  {
    label: 'Logout', name: 'logout', icon: ICONS.LOGOUT, iconSize: 12, iconColor: 'ORANGE', optionSize: 'small',
  },
]

const notificationOptions = [
  {
    label: 'Hello', name: 'Viewdetails', optionSize: 'medimum',
  },
  {
    label: 'remind me', name: 'Viewnotification', optionSize: 'medimum',
  },
]

const dummy = () => { }

const ROUTES = {
  APP: 'app',
  AUTH: 'auth',
  LOADING: 'loading',
}

let sideBarWidth = 244

class App extends Component {
  constructor(props) {
    super(props)
    this.unAuthorized = false
    this.initialMount = false
    // isSidebarVisible: false,

    this.queryParams = {}
    this.scrollRef = React.createRef()
    this.state = {
      loggedIn: true,
      password: '',
      hideSideBar: true,
      activeSideBarObj: {},
      showUpgradeModal: false,
      authScreens: AuthRoutes.some(route => window.location.pathname.includes(route.path)),
      unAuthScreens: UnauthRoutes.some(route => window.location.pathname.includes(route.path)),
      referralModal: false,
      activeRoute: ROUTES.LOADING,
      showPageLoader: false,
      isWebView: false,
      authenticated: true,
      clicked: false,
    }
    this.sideBarRef = React.createRef()
    this.upgradeModalProps = {}
  }

  handelelick = () => {
    this.setState(prevState => ({
      clicked: !prevState.clicked,
    }))
  }

  async componentDidMount() {
    // remove the coment line before hosting ctr + alt + a
    /* document.addEventListener('contextmenu', (e) => {
      e.preventDefault()
    }) */
    this.updatePreferences()
    this.getSideBarState()

    const { activeRoute } = this.state

    const { authenticateTokenUser } = this.props

    // const { accessToken } = getCookies()
    if (activeRoute !== ROUTES.APP) {
      authenticateTokenUser()
    }
    // const { user_details } = this.props
    // if (user_details && user_details.uid) {
    //   this.setState({ activeRoute: ROUTES.APP })
    // }
  }

  componentDidUpdate(prevProps) {
    const {
      location,
      toggleTheme,
      themeMode,

      isAuthenticating,
      authenticateResp,
      authenticateError,
      authenticateErrorMsg,

      isAuthenticatingToken,
      tokenAuthenticated,
      authState,
      // history,
      // authenticateTokenError,
      // authenticateTokenErrorMsg,
    } = this.props
    if (!isEqual(location, prevProps.location)) {
      if (this.scrollRef.current && location.pathname !== pathname_mapping.Dashboard) {
        this.windowScrollTimer = setTimeout(() => {
          this.scrollRef.current.scrollTo(0, 0)
        }, 150)
      }
      const authScreens = AuthRoutes.some(route => window.location.pathname.includes(route.path))
      const unAuthScreens = UnauthRoutes.some(route => location.pathname.includes(route.path))

      this.setState({
        authScreens,
        unAuthScreens,
      })
      const defaultMode = getLocalStorage(PREFERENCES.THEME)
      if (defaultMode && defaultMode !== themeMode) {
        toggleTheme(defaultMode)
      }

      if (location.pathname === pathname_mapping.Dashboard
        && !isEqual(prevProps.location.pathname, location.pathname)) {
        this.setState({
          activeRoute: ROUTES.APP,
        }, () => {
          this.getSideBarState()
        })
      }
    }

    if ((isAuthenticating !== prevProps.isAuthenticating && !isAuthenticating)
      || (!isEmpty(authenticateResp) && !isEqual(authenticateResp, prevProps.authenticateResp))) {
      if (!isEmpty(authenticateResp) && authenticateResp.accessToken) {
        this.setState({
          activeRoute: ROUTES.APP,
        }, () => {
          if (authState === AUTH_STATES.SIGNUP) {
            this.SendVerifyMail()
          }
          this.getSideBarState()
        })
      } else if (authenticateError) {
        showSnackbar(authenticateErrorMsg, {}, 0)
      }
    }

    if (!isAuthenticatingToken
      && !isEqual(isAuthenticatingToken, prevProps.isAuthenticatingToken)) {
      if (tokenAuthenticated) {
        this.setState({
          activeRoute: ROUTES.APP,
        }, () => {
          this.getSideBarState()
        })
      } else {
        this.setState({
          activeRoute: ROUTES.AUTH,
        })
      }
    }
  }

  componentWillUnmount = () => {
    if (this.requestDemoTimer) {
      clearTimeout(this.requestDemoTimer)
    }
    if (this.windowScrollTimer) clearTimeout(this.windowScrollTimer)
    // window.removeEventListener('focus', this.windowFocusHandler)
    // window.removeEventListener('blur', this.toggleWindowActive)
  }

  sendmail = (data) => {
    return fetch('https://us-central1-stamin-98728.cloudfunctions.net/app/api/emailVerificationOTP', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Allow-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .catch(err => console.log(err))
  }

  SendVerifyMail = () => {
    const{ user_details, history } = this.props
    const { email } = user_details
    const otp = Math.floor(100000 + Math.random() * 900000)
    const data = {
      email,
      otp,
    }
    history.push({
      pathname: pathname_mapping.Verificationpage,
      state: { otp, isOnBoarding: true, email },
    })
    this.sendmail(data).then((response) => {
      console.log(response)
      showSnackbar('OTP Sent to your Mail Succesfully', {}, 0)
    }).catch((err) => {
      showSnackbar(err, {}, 1)
    })
    console.log('OTP sent to your mail')
  }

  updatePreferences = () => {
    const { updatePreferences } = this.props
    const preferences = getLocalStorage(PREFERENCES.KEY)
    if (preferences) {
      updatePreferences(JSON.parse(preferences))
    }
  }

  fallback = () => {
    const { styles } = this.props
    return (
      <div className={styles.fallback}>
        <PageLoader fullScreen />
      </div>
    )
  }

  getSideBarState = () => {
    const { location, websiteConfig = {}, isMobile } = this.props
    const { activeRoute } = this.state
    const { pathname, search } = location
    const { sideBarState } = websiteConfig
    let activeSideBarObj = BAR_TYPE_MAP[pathname_mapping.Dashboard] // BAR_TYPE_MAP[pathname] || {}
    let hideSideBar = !(activeRoute === ROUTES.APP)
    if (isMobile) {
      this.setState({ hideSideBar, activeSideBarObj })
      return
    }
    // Boolean(
    //! BAR_TYPE_MAP[pathname]
    // || (BAR_TYPE_MAP[pathname] && !BAR_TYPE_MAP[pathname].show)
    // || (sideBarState && sideBarState[pathname] === '0'))
    if (search && BAR_TYPE_MAP[pathname] && BAR_TYPE_MAP[pathname].subComponent) {
      const queryMap = Object.values(parseQuery(search))
      BAR_TYPE_MAP[pathname].subComponent.some((subComp) => {
        if (queryMap.includes(subComp.tabKey)) {
          activeSideBarObj = subComp
          hideSideBar = (sideBarState && sideBarState[`${pathname}?${subComp.tabKey}`] === '0')
            || Boolean(!subComp.Component)
          return true
        }
        return false
      })
    }

    if (this.sideBarRef.current) this.sideBarRef.current.style.position = hideSideBar ? 'absolute' : 'sticky'
    this.setState({ hideSideBar, activeSideBarObj })
  }

  toggleSideBar = () => {
    const {
      websiteConfig = {}, location, isMobile, updateSiteConfig,
    } = this.props
    if (!isMobile) return
    const { activeSideBarObj, hideSideBar } = this.state

    if (this.sideBarRef.current) {
      if (hideSideBar) {
        this.sideBarRef.current.style.position = 'absolute'
        if (this.sideBarStyleTimer) clearTimeout(this.sideBarStyleTimer)
        this.sideBarStyleTimer = setTimeout(() => {
          this.sideBarRef.current.style.position = 'sticky'
        }, 450)
      } else {
        this.sideBarRef.current.style.position = 'absolute'
      }
    }
    let modSideBarState = websiteConfig.sideBarState || {}
    const pathname = activeSideBarObj.tabKey ? `${location.pathname}?${activeSideBarObj.tabKey}`
      : location.pathname
    modSideBarState = {
      ...modSideBarState,
      [pathname]: hideSideBar ? '1' : '0',
    }
    this.setState({ hideSideBar: !hideSideBar })
    updateSiteConfig('sideBarState', modSideBarState)
  }

  onChangeOption = (option) => {
    const { history } = this.props
    if (option === 'logout') {
      // const { logoutUser } = this.props
      // logoutUser()
      this.setState({ activeRoute: ROUTES.AUTH }, () => {
        auth.signOut()
        deleteCookies(['accessToken'])
        this.getSideBarState()
      })
    } else if (option === 'profile') {
      history.push(pathname_mapping.Profile)
    }
  }

  renderScreens = ({ activeRoute }) => {
    if (activeRoute === ROUTES.LOADING) {
      return (
        <PageLoader fullScreen />
      )
    }

    if (activeRoute === ROUTES.AUTH) {
      return (
        <Switch>
          {/* {HomeRoutes({ domainChange, toggleLoginModal: toggleAuthModal })}
          {CommonRoutes({ domainChange, toggleLoginModal: toggleAuthModal })} */}
          {UnAuthenticatedRoutes()}
          <Redirect to={pathname_mapping.Auth} />
        </Switch>
      )
    }

    if (activeRoute === ROUTES.APP) {
      return (
        <Switch>
          {AuthenticatedRoutes()}
          <Redirect to={pathname_mapping.Dashboard} />
        </Switch>
      )
    }
    return <PageLoader fullScreen />
  }

  headerModalRenderer = () => {
    const { styles, user_details = {} } = this.props
    const { name = '' } = user_details
    return (
      <ProfileImage
        imgContainerStyles={styles.imgContainer}
        showUpload={false}
        containerStyles={styles.profileImgContainer}
      >
        <CustomText weight="medium" color="white" style={{ textTransform: 'capitalize' }}>{name}</CustomText>
      </ProfileImage>
    )
  }

  headernotificationModalRenderer = () => {
    const { styles } = this.props
    return (
      <div className={styles.notificationbtn}>
        <Icon style={{ marginTop: '6px' }} size={27} name={ICONS.NOTIF_DASHBOARD} color={COLORS.ORANGE} />
      </div>
    )
  }

  // for plan expired calculate and show plan expired message
  planexpired = () => {
    const { styles, user_details, history } = this.props
    const { plan_instance } = user_details
    const currentDate = new Date().getTime()
    const earliestPlan = plan_instance.reduce((earliest, obj) => {
      const { plan_end_date } = obj
      const plan_end = new Date(plan_end_date).getTime()
      const remainingTimeEnd = plan_end - currentDate
      if (remainingTimeEnd < earliest.remainingTimeEnd) {
        return { plan: obj, remainingTimeEnd }
      }
      return earliest
    }, { plan: null, remainingTimeEnd: Infinity }).plan
    const ViewPlan = () => {
      history.push({
        pathname: pathname_mapping.Plans,
      })
    }
    if (earliestPlan === null) {
      return (
        <div className={styles.expiredcontainer}>
          <div>
            <span className={styles.expiredmessage}>You do not have any active plan.</span>
            <span className={styles.expiredmsg}>Make a payment to Subscription a plan.</span>
          </div>
          <div className={styles.viewplanbtn}>
            <Button
              buttonColor="orange"
              text="Explore Plan"
              uppercase
              onPress={ViewPlan}
            />
          </div>
        </div>
      )
    }
    const { plan_end_date } = earliestPlan
    const plan_end = moment(plan_end_date)
    const remainingTimeEnd = plan_end - currentDate
    const remainingDaysEnd = Math.ceil(remainingTimeEnd / (1000 * 60 * 60 * 24))
    if (remainingDaysEnd <= 0) {
      return (
        <div className={styles.expiredcontainer}>
          <div>
            <span className={styles.expiredmessage}>Your plan has expired.</span>
            <span className={styles.expiredmsg}>Make a payment to renew.</span>
          </div>
          <div className={styles.viewplanbtn}>
            <Button
              buttonColor="orange"
              text="ViewPlan"
              uppercase
              onPress={ViewPlan}
            />
          </div>
        </div>
      )
    }
    if (remainingDaysEnd <= 5) {
      return (
        <div className={styles.expiredcontainer}>
          <div className={styles.expiredcont}>
            <span className={styles.expiredmessage}>Your plan will expire soon&nbsp;</span>
            <span className={styles.expiredmsg}>{`in ${remainingDaysEnd} days`}</span>
            <span className={styles.expiredmsg}>&nbsp;Make a payment to renew.</span>
          </div>
          <div className={styles.viewplanbtn}>
            <Button
              buttonColor="orange"
              text="ViewPlan"
              uppercase
              onPress={ViewPlan}
            />
          </div>
        </div>
      )
    }
    if (remainingDaysEnd === undefined) {
      return null
    }

    return null
  }

  // This planpremium is who taken premium plan that profile side show crown img.
  planpremium = () => {
    const { user_details, styles } = this.props
    const { plan_instance } = user_details
    const premiumPlans = Object.values(plan_instance).map((obj) => {
      if (obj.name === 'Premium Plan') {
        return (
          <div className={styles.crownimage}>
            <img src={crown} alt="premium" width={40} />
          </div>
        )
      }
      return null
    })
    return premiumPlans
  }

  render() {
    const {
      styles, location, displayDevice, isFetchingUser, user_details,
      updatePreferences, preferences, startTour, // isLoggedIn,
      isMobile, history,
    } = this.props
    const {
      location: {
        pathname,
      },
    } = history
    const hidbar = pathname === '/verification' // || '/ProfileBMI'
    console.log(pathname)
    const {
      hideSideBar, activeRoute, showPageLoader, isWebView,
      activeSideBarObj, isDark, clicked,
    } = this.state
    const authenticated = true
    let loggedIn = authenticated && activeRoute === ROUTES.APP
    if(hidbar) {
      loggedIn = false
      sideBarWidth = 0
    }
    console.log(sideBarWidth)
    const ismember = user_details?.type === 'member'
    const stylesProps = { id: 'authScreens', className: styles.authScreens }

    return (
      <main className={`${styles.main}`} data-tour={startTour ? 'tour' : ''} id="main" ref={this.scrollRef}>
        {showPageLoader && <PageLoader isLoading={showPageLoader} fullScreen />}
        <Common
          activeRoute={activeRoute}
          updatePreferences={updatePreferences}
          preferences={preferences}
          location={location}
          isWebView={isWebView}
        />
        <Suspense fallback={this.fallback()}>
          <div className={styles.screens}>
            <div {...stylesProps}>
              <div id="portalWindow" />
              {displayDevice >= DISPLAY_DEVICE.DESKTOP.value && loggedIn
                ? (
                  <SideBar
                    activeSideBarObj={activeSideBarObj}
                    containerStyles={`${styles.rightContainer} ${clicked ? styles.rightContainerHidden : ''}`}
                    isDark={isDark}
                    hideSideBar={hideSideBar}
                    toggleSideBar={this.toggleSideBar}
                    isFetchingUser={isFetchingUser}
                    closeBtnStyles={styles.rightContainerCloseBtn}
                    sideBarRef={this.sideBarRef}
                  />
                ) : (
                  <div>
                    {clicked ? (
                      <div>
                        <SideBar
                          activeSideBarObj={activeSideBarObj}
                          containerStyles={`${styles.rightContainerone} ${!clicked ? styles.rightContainerHiddenone : ''}`}
                          isDark={isDark}
                          hideSideBar={!clicked}
                          toggleSideBar={this.toggleSideBar}
                          isFetchingUser={isFetchingUser}
                          closeBtnStyles={styles.rightContainerCloseBtn}
                          sideBarRef={this.sideBarRef}
                          isMobile={isMobile}
                        />
                      </div>
                    ) : (null)}
                  </div>
                )}
              <div className={`${styles.screensContent} ${hideSideBar
                ? styles.container : loggedIn ? styles.leftContainer : styles.container}`}
              >
                {!hideSideBar && (
                  <div className={styles.authHeader}>
                    {isMobile && (
                      <div className={styles.sidebar}>
                        <button type="button" onClick={this.handelelick}>
                          <Icon name={clicked ? ICONS.CLOSE : ICONS.SIDE_MENU} />
                        </button>
                      </div>
                    )}
                    {ismember && this.planpremium()}
                    <ModalDropdown
                      inputRenderer={this.headerModalRenderer}
                      options={profileOptions}
                      labelKey="label"
                      valueKey="name"
                      containerStyles={styles.profileDropdownContainer}
                      onChangeOption={this.onChangeOption}
                    />
                    {/* Notification Menu */}
                    <div style={{ marginLeft: '1rem' }}>
                      <ModalDropdown
                        inputRenderer={this.headernotificationModalRenderer}
                        options={notificationOptions}
                        labelKey="label"
                        valueKey="name"
                        containerStyles={styles.notificationDropdownContainer}
                        onChangeOption={this.onChangeOption}
                      />
                    </div>
                  </div>
                )}
                <div>
                  {ismember && loggedIn && this.planexpired()}
                </div>
                {this.renderScreens({ activeRoute })}
              </div>
            </div>
          </div>
        </Suspense>
        {SnackBar}
      </main>
    )
  }
}

const stylesheet = {
  main: {
    height: '100vh',
    // do not change this other this are dependent on scrollBehavior
    // search for getElementById('main')
    overflow: 'auto',
    overflowX: 'hidden',
    backgroundColor: '#303030',
    // pos = relative used for tour
    position: 'relative',
  },
  // crownimage: {
  //   marginTop: '-0.5rem',
  // },
  rightContainerone: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    width: '200px',
    height: '100vh',
    background: '#2a3239',
    boxShadow: '0 40px 60px rgba (0, 0, 0, 0.1)',
    transition: '2s ease-in-out',
  },
  expiredcontainer: {
    backgroundColor: 'orange',
    padding: '0.5rem',
    textAlign: 'center',
    display: 'flex',
    flexWarp: 'warp',
    justifyContent: 'center',
  },
  authScreens: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  referralContainer: {
    width: 325,
  },
  screensContent: {
    // display: 'inline-block',
    transition: 'all 0.35s var(--anim-func-ease)',
  },
  screens: {
    minHeight: `calc(100% - ${SPACING.HEADER_HEIGHT}px)`,
  },
  screensBg: {
    backgroundImage: `url(${ASSETS.LOGIN_BG})`,
    backgroundPosition: 'center',
  },
  leftContainer: {
    // flex: 0.77,
    width: `calc(100% - ${sideBarWidth}px)`, // '72%',
    // marginRight: 40,
    backgroundColor: '#393939',
  },
  container: {
    width: '100%',
  },
  rightContainer: {
    // flex: 0.23,
    width: sideBarWidth,
    top: 0,
    height: '100%',
    verticalAlign: 'top',
    display: 'inline-block',
    opacity: 1,
    transition: 'all 350ms var(--anim-func-ease)',
    transform: 'translate(0px, 0px)',
  },
  rightContainerHidden: {
    // transform: 'translate(294px, 0px)',
    opacity: 1,
    top: 0,
    height: '100%',
    visibility: 'hidden',
    position: 'fixed',
    transform: 'translate(0px, 0px)',
  },
  fallback: {
    zIndex: '99999',
    top: '0',
    height: '100%',
    position: 'fixed',
    width: '100%',
    background: '#f9f9f99e',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sideBarToggler: {
    position: 'fixed',
    top: '100px',
    right: '60px',
    transition: 'transform 450ms var(--anim-func-ease)',
    opacity: 1,
    transform: 'translate(0px)',
  },
  sideBarTogglerBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: theme.bgSecondary,
    borderRadius: 6,
    border: `1px solid ${COLORS.BLUE}`,
    height: '40px',
    width: '52px',
    backgroundColor: COLORS.BLUE,
    // fill: theme.white,
    '&:hover': {
      '& > svg': {
      },
    },
  },
  sideBarTogglerHidden: {
    transform: 'translate(100px, 0)',
    opacity: 0,
  },
  rightContainerCloseBtn: {
    zIndex: 99,
    display: 'none',
    color: 'white',
    position: 'absolute',
    right: 30,
    top: 10,
  },
  upgradeModal: {
    maxWidth: 740,
    padding: 20,
  },
  authHeader: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)',
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_30}`,
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: COLORS.BLACK,
    width: '100%',
  },
  authHeader1: {
    fontWeight: 'bold',
    display: 'flex',
    marginRight: '10px',
    backgroundColor: theme.screenBg,
  },
  notificationbtn: {
    borderRadius: '50%',
    height: '2.5rem',
    width: '2.5rem',
  },
  profileImgContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    gap: SPACING.SPACE_10,
    padding: SPACING.SPACE_6,
    borderRadius: 20,
    border: `1px solid ${theme.borderColor}`,
  },
  imgContainer: {
    width: '30px',
    height: '30px',
  },
  profileDropdownContainer: {
    minWidth: 75,
  },
  notificationDropdownContainer: {
    minWidth: 100,
    display: 'inline-block',
    verticalAlign: 'text-top',
    marginTop: SPACING.SPACE_4,
  },
  sidebar: {
    display: 'none',
    position: 'absolute',
    top: '5px',
    left: '20px',
    padding: `${SPACING.SPACE_8} ${SPACING.SPACE_20}`,
    border: '1px solid black',
    borderRadius: '10px',
    backgroundColor: '#393939',
    zIndex: 2,
  },
  expiredmessage: {
    color: COLORS.RED,
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  expiredmsg: {
    fontWeight: 'bold',
    color: COLORS.WHITE,
    fontSize: '1.2rem',
  },
  viewplanbtn: {
    marginLeft: '2rem',
  },
  messagecontainer: {
    marginTop: '1rem',
  },
  expiredcont: {
  },
  // '@media only screen and (min-width: 1366px) and (max-width: 1440px)': {
  // },
  '@media only screen and (min-width: 1025px) and (max-width: 1366px)': {
    leftContainer: {
      // marginRight: SPACING.SPACE_24,
      width: 'calc(75%)',
    },
    rightContainer: {
      width: '25%',
    },
  },
  '@media only screen and (min-width: 786px) and (max-width: 1024px)': {
    leftContainer: {
      marginRight: 0,
      width: '100%',
    },
  },
  '@media only screen and (max-width: 786px)': {
    leftContainer: {
      marginRight: 0,
      width: '100%',
    },
    screens: {
    },
  },
  '@media only screen and (max-width: 1025px)': {
    sidebar: {
      display: 'block',
    },
  },
  '@media only screen and (max-width: 500px)': {
    sidebar: {
      // padding: `${SPACING.SPACE_12}`,
      border: '1px solid black',
      borderRadius: '10px',
      justifyContent: 'space-between',
      backgroundColor: '#767687',
    },
  },
}

const mapStateToProps = (state) => {
  return {
    isAuthenticating: state.auth.isAuthenticating,
    authenticateResp: state.auth.authenticateResp,
    authenticateError: state.auth.authenticateError,
    authenticateErrorMsg: state.auth.authenticateErrorMsg,
    authState: state.auth.authState,
    user_details: state.auth.user_details,
    isAuthenticatingToken: state.auth.isAuthenticatingToken,
    authenticateTokenError: state.auth.authenticateTokenError,
    authenticateTokenErrorMsg: state.auth.authenticateTokenErrorMsg,
    tokenAuthenticated: state.auth.tokenAuthenticated,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSummary: params => dispatch(dummy(params)),
    authenticateTokenUser: params => dispatch(authenticateToken(params)),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    withTheme(stylesheet)(App),
  ),
)
