import { takeLatest, put, call } from 'redux-saga/effects'
import {
  createUserWithEmailAndPassword, signInWithEmailAndPassword,
  updateProfile, sendPasswordResetEmail,
  sendEmailVerification, setPersistence, browserLocalPersistence,
  getIdToken, updatePassword, reauthenticateWithCredential, EmailAuthProvider,
} from 'firebase/auth'
import {
  doc, setDoc, getDoc, deleteDoc, updateDoc, collection, query, where, getDocs,
} from 'firebase/firestore'
import {
  authenticateTokenSuccess,
  loginUserFailure, loginUserSuccess,
  signUpFailure, signUpSuccess,
  forgotPasswordSuccess, forgotPasswordFailure, authenticateTokenFailure,
  updatePasswordFailure, updatePasswordSuccess, updateNumberFailure, updateNumberSuccess,
  updatepicFailure, updatepicSuccess, UpdateEmailverifactionSuccess, UpdateEmailverifactionFailure,
} from './actions'
import { error_msg, onAuthStateChanged } from '../../utils/common'
import {
  AUTHENTICATE_TOKEN, LOGIN_USER, SIGN_UP, FORGOT_PASSWORD, UPDATE_PASSWORD, UPDATE_NUMBER,
  UPDATE_PROFILE_IMG, UPDATE_EMAIL_VERIFICATION,
} from './actionTypes'
import { auth, db } from '../../utils/firebaseConfig'
import { userProfile } from '../../utils/consts'
// function* loginUser(action) {
//   try {
//     const { headers } = action
//     const url = `${baseUrl}${prodEndpoints.login}`
//     const data = yield call(postRequest, url, {}, headers)
//     if (!data.error && data.status === 'success') {
//       yield put(loginUserSuccess(data))
//     } else {
//       yield put(loginUserSuccess(data))
//       // yield put(loginUserFailure(data.error))
//     }
//   } catch (err) {
//     yield put(loginUserFailure(err.message || error_msg))
//   }
// }

// export function* loginUserSaga() {
//   yield takeLatest(LOGIN_USER, loginUser)
// }

// function* signUp(action) {
//   try {
//     const { headers } = action
//     const url = `${baseUrl}${prodEndpoints.signUp}`
//     const data = yield call(postRequest, url, {}, headers)
//     if (!data.error && data.status === 'success') {
//       yield put(signUpSuccess(data))
//     } else {
//       yield put(signUpSuccess(data))
//       // yield put(signUpFailure(data.error))
//     }
//   } catch (err) {
//     yield put(signUpFailure(err.message || error_msg))
//   }
// }

// export function* signUpSaga() {
//   yield takeLatest(SIGN_UP, signUp)
// }

function* signUp(action) {
  try {
    const {
      params: {
        email, password, name, phone, branch_id, type = 'member', removeFromUnauth = false,
      },
    } = action
    // getDoc, doc(db, 'dietplans', params.uid)
    const q = query(collection(db, 'unauthUsers'), where('email', '==', email))
    let data = ''
    if(removeFromUnauth) {
      data = yield call(createUserWithEmailAndPassword, auth, email, password)
      const username = email.split('@')[0]
      yield call(deleteDoc, doc(db, 'unauthUsers', username))
      if (data.user) {
        yield call(updateProfile, auth.currentUser, { displayName: name })
        const { currentUser } = auth
        const {
          email: userEmail, displayName, uid, metadata: { createdAt = '' } = {},
        } = currentUser
        const userData = {
          ...userProfile,
          email: userEmail,
          name: displayName,
          uid,
          phone,
          branch_id,
          created_at: createdAt,
          type,
          isPremium: false,
          isActive: true,
          email_verification: false,
        }
        if (type === 'trainer') {
          delete userData.trainer
        }
        /* const actionCodeSettings = {
          url: 'http://localhost:3000/emailVerification',
          handleCodeInApp: true,
        } */
        yield call(setDoc, doc(db, 'users', currentUser.uid), userData)
        sendEmailVerification(currentUser, {
          url: `https://staminclub.web.app/emailverification?email=${currentUser.email}&Verification_Status=Success`,
          handleCodeInApp: true,
        }).then(() => {
          localStorage.setItem('email', currentUser.email)
        }).catch((err) => {
          console.log(err)
        })
        yield put(signUpSuccess(userData, currentUser.accessToken))
      } else {
        yield put(signUpFailure(data))
      }
      return
    }
    const unauth = yield call(getDocs, q)
    let members = []
    unauth.forEach(res => members.push(res.data()))
    if(members.length > 0) {
      data = 'User email Allready Exist Back to login page'
      yield put(signUpFailure(data))
    } else {
      const qs = query(collection(db, 'users'), where('phone', '==', phone))
      const userget = yield call(getDocs, qs)
      members = []
      userget.forEach(res => members.push(res.data()))
      if(members.length > 0) {
        data = 'User Number Allready Exist'
        yield put(signUpFailure(data))
      } else {
        if (removeFromUnauth) {
          const username = email.split('@')[0]
          yield call(deleteDoc, doc(db, 'unauthUsers', username))
          yield call(createUserWithEmailAndPassword, auth, email, password)
        }
        data = yield call(createUserWithEmailAndPassword, auth, email, password)
        if (data.user) {
          yield call(updateProfile, auth.currentUser, { displayName: name })
          const { currentUser } = auth
          const {
            email: userEmail, displayName, uid, metadata: { createdAt = '' } = {},
          } = currentUser
          const userData = {
            ...userProfile,
            email: userEmail,
            name: displayName,
            uid,
            phone,
            branch_id,
            created_at: createdAt,
            type,
            diet: [],
            trainer: [],
            isPremium: false,
            isActive: true,
            email_verification: false,
          }
          if (type === 'trainer') {
            delete userData.trainer
          }
          yield call(setDoc, doc(db, 'users', currentUser.uid), userData)
          sendEmailVerification(currentUser, {
            url: `https://staminclub.web.app/emailverification?email=${currentUser.email}&Verification_Status=Success`,
            handleCodeInApp: false,
          }).then(() => {
            localStorage.setItem('email', currentUser.email)
          }).catch((err) => {
            console.log(err)
          })
          yield put(signUpSuccess(userData, currentUser.accessToken))
        } else {
          yield put(signUpFailure(data))
        }
      }
    }
  } catch (err) {
    yield put(signUpFailure(err.code || error_msg))
  }
}

export function* signUpSaga() {
  yield takeLatest(SIGN_UP, signUp)
}

function* loginUser(action) {
  try {
    const {
      params: {
        email, password,
      },
    } = action
    const username = email.split('@')[0]
    const existingUser = yield call(getDoc, doc(db, 'unauthUsers', username))
    if (existingUser.exists()) {
      yield put({
        type: SIGN_UP,
        params: {
          ...existingUser.data(),
          branch_id: existingUser.data().branch_id || '',
          email,
          password,
          removeFromUnauth: true,
        },
      })
      return
    }
    yield call(setPersistence, auth, browserLocalPersistence)
    const data = yield call(signInWithEmailAndPassword, auth, email, password)
    const { currentUser } = auth
    if (!data.error) {
      const user = yield call(getDoc, doc(db, 'users', currentUser.uid))
      if (user.exists()) {
        const userData = user.data()
        yield put(loginUserSuccess(userData, currentUser.accessToken))
      } else {
        yield put(loginUserFailure(user.error))
      }
    } else {
      yield put(loginUserFailure(data.error))
    }
  } catch (err) {
    yield put(loginUserFailure(err.message || error_msg))
  }
}

export function* loginUserSaga() {
  yield takeLatest(LOGIN_USER, loginUser)
}

function* authenticateToken() {
  try {
    const data = yield call(onAuthStateChanged)
    if (data && data.error) {
      yield put(authenticateTokenFailure(data.error))
    } else {
      const authUser = data?.toJSON()
      if (authUser) {
        const user = yield call(getDoc, doc(db, 'users', authUser.uid))
        if (user.exists()) {
          const userData = user.data()
          const accessToken = yield call(getIdToken, data)
          yield put(authenticateTokenSuccess(userData, accessToken))
        }
      } else {
        yield put(authenticateTokenFailure(data.error))
      }
    }
  } catch (err) {
    yield put(authenticateTokenFailure(err?.message || error_msg))
  }
}

export function* authenticateTokenSaga() {
  yield takeLatest(AUTHENTICATE_TOKEN, authenticateToken)
}

function* forgotPassword(action) {
  try {
    const {
      params: {
        email,
      },
    } = action
    const data = yield call(sendPasswordResetEmail, auth, email)
    if (data && data.error) {
      yield put(forgotPasswordFailure(data.error))
    } else {
      yield put(forgotPasswordSuccess(data))
    }
  } catch (err) {
    yield put(forgotPasswordFailure(err.message || error_msg))
  }
}

export function* forgotPasswordSaga() {
  yield takeLatest(FORGOT_PASSWORD, forgotPassword)
}

function* updateUserPassword(action) {
  try {
    const {
      params: {
        new: newPass,
        old: oldPass,
      },
    } = action
    const { currentUser } = auth
    const data = yield call(reauthenticateWithCredential,
      currentUser, EmailAuthProvider.credential(currentUser.email, oldPass))

    if (data && data.error) {
      yield put(updatePasswordFailure(data.error))
    } else {
      yield call(updatePassword, currentUser, newPass)
      yield put(updatePasswordSuccess(currentUser.email))
    }
  } catch (err) {
    yield put(updatePasswordFailure(err.message || error_msg))
  }
}
export function* updatePasswordSaga() {
  yield takeLatest(UPDATE_PASSWORD, updateUserPassword)
}

function* updateUserNumber(action) {
  try {
    const {
      params: {
        new: newNumber,
        uid,
      },
    } = action
    const docRef = doc(db, 'users', uid)
    const data = yield call(updateDoc, docRef, { phone: newNumber })

    if (data && data.error) {
      yield put(updateNumberFailure(data.error))
    } else {
      yield put(updateNumberSuccess({ phone: newNumber }))
    }
  } catch (err) {
    yield put(updateNumberFailure(err.message || error_msg))
  }
}
export function* updateNumberSaga() {
  yield takeLatest(UPDATE_NUMBER, updateUserNumber)
}
function* updateUserImg(action) {
  try {
    const {
      params: {
        profile_img: imagurl,
        uid,
      },
    } = action
    const docRef = doc(db, 'users', uid)
    const data = yield call(updateDoc, docRef, { profile_img: imagurl })

    if (data && data.error) {
      yield put(updatepicFailure(data.error))
    } else {
      yield put(updatepicSuccess({ profile_img: imagurl }))
    }
  } catch (err) {
    yield put(updatepicFailure(err.message || error_msg))
  }
}
export function* updateUserImgSaga() {
  yield takeLatest(UPDATE_PROFILE_IMG, updateUserImg)
}

export function* emailVerificationStatus(action) {
  try{
    const {
      params: {
        email,
        email_verification,
      },
    } = action
    const qs = query(collection(db, 'users'), where('email', '==', email))
    const userget = yield call(getDocs, qs)
    const members = []
    userget.forEach(res => members.push(res.data()))
    if(members.length === 1) {
      const { uid } = members[0]
      const docRef = doc(db, 'users', uid)
      const data = yield call(updateDoc, docRef, { email_verification })
      if (data && data.error) {
        yield put(UpdateEmailverifactionFailure(data.error))
      } else {
        yield put(UpdateEmailverifactionSuccess({ email_verification }))
      }
    }
  } catch (err) {
    console.log(err)
  }
}
export function* emailVerificationStatusSaga() {
  yield takeLatest(UPDATE_EMAIL_VERIFICATION, emailVerificationStatus)
}
