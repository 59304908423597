import {
  GET_BRANCHES, GET_BRANCHES_FAILURE, GET_BRANCHES_SUCCESS,
  ADD_BRANCH, ADD_BRANCH_FAILURE, ADD_BRANCH_SUCCESS,
} from './actionTypes'

export function getBranches() {
  return {
    type: GET_BRANCHES,
  }
}

export function getBranchesSuccess(data) {
  return {
    type: GET_BRANCHES_SUCCESS,
    data,
  }
}

export function getBranchesFailure(error) {
  return {
    type: GET_BRANCHES_FAILURE,
    error,
  }
}

export function addBranch(params) {
  return {
    type: ADD_BRANCH,
    params,
  }
}

export function addBranchSuccess(data) {
  return {
    type: ADD_BRANCH_SUCCESS,
    data,
  }
}

export function addBranchFailure(error) {
  return {
    type: ADD_BRANCH_FAILURE,
    error,
  }
}
