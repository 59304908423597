import {
  ADD_VIDEO, ADD_VIDEO_FAILURE, ADD_VIDEO_SUCCESS,
  GET_VIDEOS, GET_VIDEOS_FAILURE, GET_VIDEOS_SUCCESS,
  EDIT_VIDEO, EDIT_VIDEO_FAILURE, EDIT_VIDEO_SUCCESS,
  DELETE_VIDEO, DELETE_VIDEO_SUCCESS, DELETE_VIDEO_FAILURE,
} from './actionTypes'

export function addVideo(params) {
  return {
    type: ADD_VIDEO,
    params,
  }
}

export function addVideoSuccess(data) {
  return {
    type: ADD_VIDEO_SUCCESS,
    data,
  }
}

export function addVideoFailure(error) {
  return {
    type: ADD_VIDEO_FAILURE,
    error,
  }
}

export function editVideo(params) {
  return {
    type: EDIT_VIDEO,
    params,
  }
}

export function editVideoSuccess(data) {
  return {
    type: EDIT_VIDEO_SUCCESS,
    data,
  }
}

export function editVideoFailure(error) {
  return {
    type: EDIT_VIDEO_FAILURE,
    error,
  }
}
export function deleteVideo(params) {
  return {
    type: DELETE_VIDEO,
    params,
  }
}

export function deleteVideoSuccess(data) {
  return {
    type: DELETE_VIDEO_SUCCESS,
    data,
  }
}

export function deleteVideoFailure(error) {
  return {
    type: DELETE_VIDEO_FAILURE,
    error,
  }
}

export function getVideos(params) {
  return {
    type: GET_VIDEOS,
    params,
  }
}

export function getVideosSuccess(data) {
  return {
    type: GET_VIDEOS_SUCCESS,
    data,
  }
}

export function getVideosFailure(error) {
  return {
    type: GET_VIDEOS_FAILURE,
    error,
  }
}
