import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/styles/makeStyles'

import CustomText from './CustomText'
import Icon from './Icon'

import {
  DIMENSIONS, FONTS, ICONS, SPACING, theme, COLORS,
} from '../Theme'
import PlaceHolderLoader from './PlaceHolderLoader'

const ViewAllCard = ({
  children,
  onPress,
  title,
  renderTitle,
  contentStyles,
  cardStyles = '',
  actionBtnRederer,
  customActionBtnRenderer,
  headerStyles = '',
  showViewAll = true,
  loading,
  titleParams = {},
  viewAllText = 'View All',
  style = {},
//   isDark
}) => {
  const styles = stylesheet()
  const onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onPress(e)
  }
  const renderHeaderTitle = () => {
    return (
      renderTitle ? renderTitle(titleParams) : (
        <CustomText style={{ textTransform: 'capitalize' }} color="white" weight="semi_bold" size="medium">{title}</CustomText>
      )
    )
  }

  const isNone = viewAllText === 'none'
  return (
    <section className={classnames(styles.card, 'view-all-card', cardStyles)} style={style}>
      <div className={classnames(styles.header, headerStyles)}>
        {loading ? <PlaceHolderLoader className={styles.loader} /> : (
          renderHeaderTitle()
        )}
        {customActionBtnRenderer
          ? customActionBtnRenderer(titleParams)
          : showViewAll && !loading && (
          <button
            type="button"
            className={classnames(styles.viewAllBtn, 'view-all-button')}
            onClick={onClick}
          >
            {actionBtnRederer ? actionBtnRederer()
              : (!isNone ? (
                <>
                  {viewAllText}
                  &nbsp;
                  <Icon name={ICONS.FORWARD} color={COLORS.WHITE} size={9} />
                </>
              ) : (null)
              )}
          </button>
          )}
      </div>
      <div className={contentStyles}>
        {children}
      </div>
    </section>
  )
}

const stylesheet = makeStyles({
  card: {
    borderRadius: 8,
    padding: `${SPACING.SPACE_24} 0`,
    // backgroundColor: theme.bgPrimary,
    backgroundColor: '#494949',
    boxShadow: `0px 3px 20px ${theme.boxShadow}`,
    '&:hover': {
      '& $viewAllBtn': {
        color: theme.orange,
        '& > svg': {
          fill: theme.orange,
        },
      },
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `0 ${DIMENSIONS.SPACE_HORIZONTAL}`,
    marginBottom: SPACING.SPACE_14,
  },
  viewAllBtn: {
    // color: theme.textSecondary,
    color: 'white',
    fontSize: FONTS.SMALL,
  },
  loader: {
    width: '100%',
    maxWidth: '200px',
    height: '20px',
  },
})

CustomText.defaultProps = {
  contentStyles: '',
  cardStyles: '',
  showViewAll: true,
}

CustomText.propTypes = {
  title: PropTypes.string,
  contentStyles: PropTypes.string,
  cardStyles: PropTypes.string,
  showViewAll: PropTypes.bool,
}

export default ViewAllCard
