import {
  BOOK_SESSION, BOOK_SESSION_FAILURE, BOOK_SESSION_SUCCESS,
  GET_SESSIONS, GET_SESSIONS_SUCCESS, GET_SESSIONS_FAILURE,
  DELETE_SESSION, DELETE_SESSION_FAILURE, DELETE_SESSION_SUCCESS,
  GET_SESSION_DETAILS, GET_SESSION_DETAILS_SUCCESS, GET_SESSION_DETAILS_FAILURE,
  ARCHIVE_SESSION, ARCHIVE_SESSION_FAILURE, ARCHIVE_SESSION_SUCCESS,
  START_SESSION, START_SESSION_FAILURE, START_SESSION_SUCCESS,
} from './actionTypes'

export function bookSession(params, userType, creatsessionkey, updateKey) {
  return {
    type: BOOK_SESSION,
    params,
    userType,
    updateKey,
    creatsessionkey,
  }
}

export function bookSessionSuccess(data) {
  return {
    type: BOOK_SESSION_SUCCESS,
    data,
  }
}

export function bookSessionFailure(error) {
  return {
    type: BOOK_SESSION_FAILURE,
    error,
  }
}

export function getSessions(params, type) {
  return {
    type: GET_SESSIONS,
    params,
    userType: type,
  }
}

export function getSessionsSuccess(data, userType) {
  return {
    type: GET_SESSIONS_SUCCESS,
    data,
    userType,
  }
}

export function getSessionsFailure(error) {
  return {
    type: GET_SESSIONS_FAILURE,
    error,
  }
}

export function deleteSession(params) {
  return {
    type: DELETE_SESSION,
    params,
  }
}

export function deleteSessionSuccess(data) {
  return {
    type: DELETE_SESSION_SUCCESS,
    data,
  }
}

export function deleteSessionFailure(error) {
  return {
    type: DELETE_SESSION_FAILURE,
    error,
  }
}

export function getSessionDetails(params) {
  return {
    type: GET_SESSION_DETAILS,
    params,
  }
}

export function getSessionDetailsSuccess(data) {
  return {
    type: GET_SESSION_DETAILS_SUCCESS,
    data,
  }
}

export function getSessionDetailsFailure(error) {
  return {
    type: GET_SESSION_DETAILS_FAILURE,
    error,
  }
}

export function archiveSession(params) {
  return {
    type: ARCHIVE_SESSION,
    params,
  }
}

export function archiveSessionSuccess(data) {
  return {
    type: ARCHIVE_SESSION_SUCCESS,
    data,
  }
}

export function archiveSessionFailure(error) {
  return {
    type: ARCHIVE_SESSION_FAILURE,
    error,
  }
}

export function startSession(params) {
  return {
    type: START_SESSION,
    params,
  }
}

export function startSessionSuccess(data) {
  return {
    type: START_SESSION_SUCCESS,
    data,
  }
}

export function startSessionFailure(error) {
  return {
    type: START_SESSION_FAILURE,
    error,
  }
}
