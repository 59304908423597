import {
  GET_PLANS, GET_PLANS_FAILURE, GET_PLANS_SUCCESS,
} from './actionTypes'

export function getPlans() {
  return {
    type: GET_PLANS,
  }
}

export function getPlansSuccess(data) {
  return {
    type: GET_PLANS_SUCCESS,
    data,
  }
}

export function getPlansFailure(error) {
  return {
    type: GET_PLANS_FAILURE,
    error,
  }
}
