import {
  GET_ASSIGNED_MEMBERS, GET_ASSIGNED_MEMBERS_FAILURE, GET_ASSIGNED_MEMBERS_SUCCESS,
  CREATE_DIET_PLAN, CREATE_DIET_PLAN_FAILURE, CREATE_DIET_PLAN_SUCCESS,
} from './actionTypes'

const initialState = {
  assignedMembersData: [],
  isFetchingAssignedMembers: false,
  fetchAssignedMembersErr: false,
  fetchAssignedMembersErrMsg: '',

  isCreatingDietPlan: false,
  createDietPlanErr: false,
  createDietPlanErrMsg: '',
  dietPlanCreated: false,
}

const assignedMembers = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSIGNED_MEMBERS:
      return {
        ...state,
        isFetchingAssignedMembers: true,
      }

    case GET_ASSIGNED_MEMBERS_SUCCESS: {
      const { data } = action
      const assignedMembersData = data

      return {
        ...state,
        isFetchingAssignedMembers: false,
        assignedMembersData,
      }
    }

    case GET_ASSIGNED_MEMBERS_FAILURE:
      return {
        ...state,
        isFetchingAssignedMembers: false,
        fetchAssignedMembersErr: true,
        fetchAssignedMembersErrMsg: action.error,
      }

    case CREATE_DIET_PLAN:
      return {
        ...state,
        isCreatingDietPlan: true,
      }

    case CREATE_DIET_PLAN_SUCCESS:
      return {
        ...state,
        isCreatingDietPlan: false,
        dietPlanCreated: true,
      }

    case CREATE_DIET_PLAN_FAILURE:
      return {
        ...state,
        isCreatingDietPlan: false,
        createDietPlanErr: true,
        createDietPlanErrMsg: action.error,
      }

    default:
      return state
  }
}

export default assignedMembers
