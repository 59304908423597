import { takeLatest, put, call } from 'redux-saga/effects'
import {
  getDocs, query, collection,
} from 'firebase/firestore'
import {
  getPlansSuccess,
  getPlansFailure,
} from './actions'
import {
  GET_PLANS,
} from './actionTypes'
import { db } from '../../utils/firebaseConfig'

import { error_msg } from '../../utils/common'

function* getPlans() {
  try {
    const q = query(collection(db, 'plans'))
    const data = yield call(getDocs, q)
    const plans = []
    data.forEach(res => plans.push(res.data()))

    if (plans.length) {
      yield put(getPlansSuccess(plans))
    } else {
      yield put(getPlansFailure(data.error))
    }
  } catch(err) {
    yield put(getPlansFailure(err.message || error_msg))
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* getPlansSaga() {
  yield takeLatest(GET_PLANS, getPlans)
}
