import {
  UPDATE_SECHUDLE, UPDATE_SECHUDLE_SUCCESS, UPDATE_SECHUDLE_FAILURE,
} from './actionTypes'

const initialState = {
  isupadte: false,
  isupdateErr: false,
  isupdateErrMsg: '',
  updated: [],
}

const qrcode = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SECHUDLE:
      return {
        ...state,
        isupadte: true,
      }

    case UPDATE_SECHUDLE_SUCCESS:
      return {
        ...state,
        isupadte: false,
      }

    case UPDATE_SECHUDLE_FAILURE:
      return {
        ...state,
        isupadte: false,
        isupdateErr: true,
        isupdateErrMsg: action.error,
      }
    default:
      return state
  }
}

export default qrcode
