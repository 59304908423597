import { FETCH_TRAINER_DETAILS, FETCH_TRAINER_DETAILS_FAILURE, FETCH_TRAINER_DETAILS_SUCCESS } from './actionTypes'

export function fetchTrainerDetails(params) {
  return {
    type: FETCH_TRAINER_DETAILS,
    params,
  }
}

export function fetchTrainerDetailsSuccess(data) {
  return {
    type: FETCH_TRAINER_DETAILS_SUCCESS,
    data,
  }
}

export function fetchTrainerDetailsFailure(error) {
  return {
    type: FETCH_TRAINER_DETAILS_FAILURE,
    error,
  }
}
