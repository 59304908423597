// DANGER remove cycle dep
/* eslint-disable import/no-cycle */
import React, { lazy } from 'react'
import {
  Route,
} from 'react-router-dom'

import { pathname_mapping } from './utils/navigationConstant'
import EmailVerification from './components/AssignedMember/EmailVerification'

const Auth = lazy(() => import('./containers/Auth/Auth-redux'))

export const UnauthRoutes = [
  { Component: Auth, path: pathname_mapping.Auth, exact: true },
  { Component: EmailVerification, path: pathname_mapping.EmailVerification, exact: true },
]

const UnAuthenticatedRoutes = () => {
  return (
    UnauthRoutes.map((item) => {
      const { Component, path, exact } = item
      return (
        <Route
          key={path}
          exact={exact}
          path={path}
          component={Component}
        />
      )
    })
  )
}

export default UnAuthenticatedRoutes
