import React, { PureComponent } from 'react'
import isEqual from 'lodash/isEqual'
import { connect } from 'react-redux'
// import classnames from 'classnames'
import CustomText from './CustomText'

// import Icon from '../UI/Icon'
import PlaceHolderLoader from './PlaceHolderLoader'

import { showSnackbar } from './Snackbar'
import {
  ASSETS, SPACING,
} from '../Theme'
import { withTheme } from '../Theme/ThemeProvider'
import Button from './Button'

const { USER_IMG } = ASSETS

const dummy = () => {}

class ProfileImage extends PureComponent {
  componentDidMount() {
    const { user_details, getProfilePic = () => {} } = this.props
    const user_uuid = user_details && user_details.user_uuid
    if (user_uuid) {
      getProfilePic({ user_uuid })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      uploadError,
      uploadSuccess,
      uploadErrorMsg,
      toggleUpdateProfileModal,
      // fetchFileError,
      // fetchFileErrorMsg,
      showUpload,
    } = this.props
    // if (!isEqual(prevProps.user_details, user_details) && user_uuid) {
    //   getProfilePic({ user_uuid })
    // }
    if (showUpload) {
      if (!isEqual(uploadError, prevProps.uploadError) && uploadError) {
        showSnackbar(uploadErrorMsg, {}, 0)
      } else if (!isEqual(uploadSuccess, prevProps.uploadSuccess) && uploadSuccess) {
        showSnackbar('Image uploaded successfully', {}, 1)
        toggleUpdateProfileModal(false)
      }
    }
    // if (!isEqual(fetchFileError, prevProps.fetchFileError) && fetchFileError) {
    //   showSnackbar(fetchFileErrorMsg, {}, 0)
    // }
  }

  componentWillUnmount() {

  }

  render() {
    const {
      profileImg = '',
      // showLoadingProfile = false,
      showUpload = true,
      containerStyles = '',
      imgStyles = '',
      isFetchingFile,
      styles,
      user_details,
      imgContainerStyles = '',
      toggleUpdateProfileModal,
      children,
      childStyles,
      // isDark,
    } = this.props
    // let profileImage = StreakLogo
    // if (profileImg && profileImg.length) {
    //   profileImage = { uri: profileImg }
    //   resizeMode = 'cover'
    // }
    const { user_name, email } = user_details
    const name = !user_name && email ? email.split('@')[0] : ''
    return (
      <div className={`${styles.container} ${containerStyles}`}>
        <div className={`${styles.imgContainer} ${imgContainerStyles}`}>
          {isFetchingFile ? <PlaceHolderLoader className={styles.loader} /> : (
            <div
              style={{
                backgroundImage: `url(${profileImg || USER_IMG})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover', // contain
                backgroundPosition: 'center',
              }}
              className={`${styles.img} ${imgStyles}`}
            />
          )}
        </div>
        <div className={childStyles}>
          {children}
          {showUpload ? (
            <div className={styles.info}>
              <CustomText size="large" style={{ textTransform: 'capitalize' }} weight="medium">{name}</CustomText>
              <Button text="Change Profile Photo" onPress={() => toggleUpdateProfileModal(true)} mode="text" transparent labelColor="linkColor" />
            </div>
          ) : null}
        </div>
        {/* <img src={profileImg || Images().ProfileImg}
         alt="user_img" className={styles.userImg} /> */}
      </div>
    )
  }
}

const stylesheet = () => ({
  container: {
    overflow: 'hidden',
    display: 'flex',
  },
  imgContainer: {
    // flex: '0.2',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  img: {
    borderRadius: '50%',
    height: '100%',
    maxWidth: 55,
    width: '100%',
  },
  info: {
    marginLeft: SPACING.SPACE_20,
    flex: 1,
  },
  imgInputContainer: {},
  imgInputLabel: {},
  uploadText: {
    display: 'inline',
    cursor: 'pointer',
  },
  imgInput: {
    opacity: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: '0',
    display: 'none',
  },
  loader: {
    width: '100%',
    height: '100%',
    maxWidth: '55px',
    minHeight: '55px',
    borderRadius: '50%',
    overflow: 'hidden',
  },
})

const mapStateToProps = (state) => {
  return {
    user_details: state.auth.user_details,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dummy: payload => dispatch(dummy(payload)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(stylesheet)(ProfileImage))
