import { takeLatest, put, call } from 'redux-saga/effects'
import {
  getDocs, query, collection, where, setDoc, doc, deleteDoc,
  updateDoc, getDoc,
} from 'firebase/firestore'
import { v4 as uuid } from 'uuid'
import {
  fetchAllPurchasesSuccess,
  fetchAllPurchasesFailure,
  createPlanSuccess,
  createPlanFailure,
  deletePlanFailure,
  deletePlanSuccess,
  updatePlanFailure,
  updatePlanSuccess,
  createInvoiceFailure,
  createInvoiceSuccess,
  updateInvoiceFailure,
  updateInvoiceSuccess,
  PlansubscriptionsFailure,
  PlansubscriptionsSuccess,
  fetchAllplansubscriptionSuccess,
  fetchAllplansubscriptionFailure,
} from './actions'
import {
  FETCH_ALL_PURCHASES,
  CREATE_PLAN,
  DELETE_PLAN,
  UPDATE_PLAN,
  CREATE_INVOICE,
  UPDATE_INVOICE,
  CREATE_PLANSUBSCRIPTION,
  FETCH_ALL_PLANSUBSCRIPTION,
} from './actionTypes'
import { db } from '../../utils/firebaseConfig'

import { error_msg } from '../../utils/common'
import { GET_PLANS } from '../Common/actionTypes'

function* fetchAllPurchases({ params }) {
  try {
    const { uid = '', type, branch_id = '' } = params
    let q = query(collection(db, 'purchases'), where('UID', '==', uid))
    if (type === 'admin') {
      q = query(collection(db, 'purchases'))
    } else if (type === 'branch_admin') {
      q = query(collection(db, 'purchases'), where('BRANCH_ID', '==', branch_id))
    } else if (type === 'member') {
      q = query(collection(db, 'purchases'), where('UID', '==', uid))
    }
    const data = yield call(getDocs, q)
    const purchases = []
    data.forEach(res => purchases.push(res.data()))

    if (purchases.length) {
      yield put(fetchAllPurchasesSuccess(purchases))
    } else {
      yield put(fetchAllPurchasesFailure(data.error))
    }
  } catch (err) {
    yield put(fetchAllPurchasesFailure(err.message || error_msg))
  }
}

export function* fetchAllPurchasesSaga() {
  yield takeLatest(FETCH_ALL_PURCHASES, fetchAllPurchases)
}

function* createPlan(action) {
  try {
    const {
      params: {
        name, amount, workouts, description, branch_id, packageAmount, gst, duration, id,
      },
    } = action
    const plan_id = name.trim().replace(' ', '_').toLowerCase()
    const payload = {
      id,
      plan_id,
      name,
      amount,
      workouts,
      description,
      gst,
      packageAmount,
      branch_id,
      duration,
    }
    let data = null

    data = yield call(setDoc, doc(db, 'plans', id), payload)

    if (!data?.error) {
      yield put({ type: GET_PLANS })
      yield put(createPlanSuccess(data))
    } else {
      yield put(createPlanFailure(data))
    }
  } catch (err) {
    yield put(createPlanFailure(err.message || error_msg))
  }
}

export function* createPlanSaga() {
  yield takeLatest(CREATE_PLAN, createPlan)
}

function* deletePlan(action) {
  try {
    const {
      params: { id },
    } = action
    const data = yield call(deleteDoc, doc(db, 'plans', id))

    if (!data?.error) {
      yield put({ type: GET_PLANS })
      yield put(deletePlanSuccess(data))
    } else {
      yield put(deletePlanFailure(data))
    }
  } catch (err) {
    yield put(deletePlanFailure(err.message || error_msg))
  }
}

export function* deletePlanSaga() {
  yield takeLatest(DELETE_PLAN, deletePlan)
}

function* updatePlan(action) {
  try {
    const {
      params: {
        id, name, amount, workouts, description,
        packageAmount, duration, gst,
      },
    } = action

    const plan_id = name.trim().replace(' ', '_').toLowerCase()

    const payload = {
      id,
      plan_id,
      name,
      amount,
      workouts,
      description,
      packageAmount,
      duration,
      gst,
    }
    const data = yield call(updateDoc, doc(db, 'plans', id), payload)

    if (!data?.error) {
      yield put({ type: GET_PLANS })
      yield put(updatePlanSuccess(data))
    } else {
      yield put(updatePlanFailure(data))
    }
  } catch (err) {
    yield put(updatePlanFailure(err.message || error_msg))
  }
}

export function* updatePlanSaga() {
  yield takeLatest(UPDATE_PLAN, updatePlan)
}

function* createInvoice({ params }) {
  console.log(params)
  try {
    const {
      UID,
      BRANCH_ID,
      type,
      plandetails,
      PLANDETAILS,
      PLANS,
    } = params
    const id = uuid()
    const payload = {
      ORDERID: id,
      ...params,
    }
    let data = null
    const user = yield call(getDoc, doc(db, 'users', UID))

    if (!user.exists()) {
      yield put(updateInvoiceFailure('Auth error'))
      return
    }
    const { plan_instance: old_instance = [] } = user.data()
    const newPI = [...old_instance, ...plandetails]
    data = yield call(updateDoc, doc(db, 'users', UID), { plan_instance: newPI })
    for(let i = 0; i < Object.values(PLANS).length; i++) {
      const planid = PLANS[i]
      const {
        schedule, duration, name, workouts,
      } = plandetails[i]
      const { planstart, planend } = PLANDETAILS[i]
      const plan1 = {
        name,
        duration,
        plan_start_date: planstart,
        plan_end_date: planend,
        id: planid,
      }
      const sid = uuid()
      const parm = {
        Schedule: schedule,
        plan1,
        workouts,
        uid: UID,
        sid,
      }
      data = yield call(setDoc, doc(db, 'assignworkouts', sid), parm)
    }
    delete payload.plandetails
    data = yield call(setDoc, doc(db, 'purchases', id), payload)
    if (!data?.error) {
      yield put({ type: FETCH_ALL_PURCHASES, params: { type, BRANCH_ID } })
      yield put(createInvoiceSuccess(data))
    } else {
      yield put(createInvoiceFailure(data))
    }
  } catch (err) {
    yield put(createInvoiceFailure(err.message || error_msg))
  }
}

export function* createInvoiceSaga() {
  yield takeLatest(CREATE_INVOICE, createInvoice)
}

function* updateInvoice({ params }) {
  try {
    const {
      UID, order_id, plan, plandetails, STATUS, BRANCH_ID, PLANS, PLANDETAILS, type,
    } = params
    console.log(params)
    const order = yield call(getDoc, doc(db, 'purchases', order_id))
    console.log(order.data()?.UID)
    console.log(!order.data()?.UID)
    if (!order.exists()) {
      yield put(updateInvoiceFailure('Order not found'))
      return
    } if (order.exists() && order.data()?.UID) {
      yield put(updateInvoiceFailure('Invalid order status'))
      return
    }

    const payload = {
      // UID: uid,
      PLAN: plan,
      BRANCH_ID,
    }
    console.log(payload)
    const user = yield call(getDoc, doc(db, 'users', UID))
    if (!user.exists()) {
      yield put(updateInvoiceFailure('Auth error'))
      return
    }
    const { plan_instance: old_instance = [] } = user.data()
    const newPI = [...old_instance, ...plandetails]
    console.log(STATUS)
    delete params.plandetails
    const data = yield call(updateDoc, doc(db, 'purchases', order_id), params)
    if (STATUS === 'TXN_SUCCESS') {
      yield call(updateDoc, doc(db, 'users', UID), { plan_instance: newPI })
      console.log('plan Added')
      for(let i = 0; i < Object.values(PLANS).length; i++) {
        const planid = PLANS[i]
        const {
          schedule, duration, name, workouts,
        } = plandetails[i]
        const { planstart, planend } = PLANDETAILS[i]
        const plan1 = {
          name,
          duration,
          plan_start_date: planstart,
          plan_end_date: planend,
          id: planid,
        }
        const sid = uuid()
        const parm = {
          Schedule: schedule,
          plan1,
          workouts,
          uid: UID,
          sid,
        }
        console.log(parm)
        const dd = yield call(setDoc, doc(db, 'assignworkouts', sid), parm)

        console.log('Schedule Added', dd)
      }
    }
    if (!data?.error) {
      yield put({ type: FETCH_ALL_PURCHASES, params: { type, BRANCH_ID } })
      yield put(updateInvoiceSuccess(data))
    } else {
      yield put(updateInvoiceFailure(data))
    }
  } catch (err) {
    console.log(error_msg)
    yield put(updateInvoiceFailure(err.message || error_msg))
  }
}

export function* updateInvoiceSaga() {
  yield takeLatest(UPDATE_INVOICE, updateInvoice)
}

function* createSubInvoice({ parms }) {
  try {
    console.log(parms)
    const orderId = uuid()
    const payload = {
      ...parms,
      orderId,
    }
    const data = yield call(setDoc, doc(db, 'subscription', orderId), payload)
    if(data?.error) {
      console.log('failer', data)
      yield put(PlansubscriptionsFailure(data))
    } else {
      console.log('suss')
      yield put(PlansubscriptionsSuccess(data))
    }
  } catch (err) {
    console.log('failer 2', err)
    yield put(PlansubscriptionsFailure(err.message || error_msg))
  }
}

export function* createSubInvoiceSaga() {
  yield takeLatest(CREATE_PLANSUBSCRIPTION, createSubInvoice)
}

function* fetchAllplansubscription() {
  try {
    const q = query(collection(db, 'subscription'))
    const data = yield call(getDocs, q)
    const subscription = []
    data.forEach(res => subscription.push(res.data()))

    if (subscription.length) {
      console.log(subscription)
      yield put(fetchAllplansubscriptionSuccess(subscription))
    } else {
      yield put(fetchAllplansubscriptionFailure(data.error))
    }
  } catch (err) {
    yield put(fetchAllPurchasesFailure(err.message || error_msg))
  }
}

export function* fetchAllplansubscriptionsSaga() {
  yield takeLatest(FETCH_ALL_PLANSUBSCRIPTION, fetchAllplansubscription)
}
