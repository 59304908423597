import { GET_ALL_USERS, GET_ALL_USERS_FAILURE, GET_ALL_USERS_SUCCESS } from './actionTypes'

const initialState = {
  isFetchingUsers: false,
  allUser: [],
  adminUsers: [],
  adminTrainers: [],
  branchAdmins: [],
  fetchUsersErr: false,
  fetchUsersErrMsg: '',

}

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USERS:
      return {
        ...state,
        isFetchingUsers: true,
      }

    case GET_ALL_USERS_SUCCESS: {
      const { data } = action
      const allUser = data
      const adminUsers = data.filter(user => user.type === 'member')
      const adminTrainers = data.filter(user => user.type === 'trainer')
      const branchAdmins = data.filter(user => user.type === 'branch_admin')
      return {
        ...state,
        isFetchingUsers: false,
        allUser,
        adminUsers,
        branchAdmins,
        adminTrainers,
      }
    }

    case GET_ALL_USERS_FAILURE:
      return {
        ...state,
        isFetchingUsers: false,
        fetchUsersErr: true,
        fetchUsersErrMsg: action.error,
      }

    default:
      return state
  }
}

export default dashboard
