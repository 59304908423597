import { takeLatest, put, call } from 'redux-saga/effects'
import {
  doc, getDoc,
  updateDoc,
} from 'firebase/firestore'
// import { v4 as uuid } from 'uuid'
import { db } from '../../utils/firebaseConfig'
import { error_msg } from '../../utils/common'
import { updatescheduleSuccess, updatescheduleFailure } from './actions'
import { UPDATE_SECHUDLE } from './actionTypes'

function* updateschedule(actions) {
  try{
    const { params } = actions
    const { id } = params
    const data = yield call(getDoc, doc(db, 'sessions', id))
    const sdata = data.data()
    const { sid, index } = sdata
    // status
    const data2 = yield call(getDoc, doc(db, 'assignworkouts', sid))
    const asdta = data2.data()
    const { Schedule } = asdta
    Schedule[index].BookingStatus = 'Completed'
    yield call(updateDoc, doc(db, 'assignworkouts', sid), { Schedule })
    const status = 1
    const dataup = yield call(updateDoc, doc(db, 'sessions', id), { status })
    if(!dataup?.error) {
      yield put(updatescheduleSuccess(dataup))
    }
  } catch (err) {
    yield put(updatescheduleFailure(err.message || error_msg))
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* qrcodeSaga() {
  yield takeLatest(UPDATE_SECHUDLE, updateschedule)
}
