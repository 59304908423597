import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
// import { showSnackbar } from '../../UI/Snackbar'
import { updatepic } from '../../containers/Auth/actions'
import { withTheme } from '../../Theme/ThemeProvider'
import Button from '../../UI/Button'
import CustomInput from '../../UI/CustomInput'
import CustomModal from '../../UI/CustomModal'
import CustomText from '../../UI/CustomText'
import {
  ICONS, SPACING, COLORS,
} from '../../Theme'
import Icon from '../../UI/Icon'
import { editUser } from '../../containers/Users/actions'
import { pathname_mapping } from '../../utils/navigationConstant'
import { showSnackbar } from '../../UI/Snackbar'
import { myBucket, S3_BUCKET } from '../../utils/awsConfig'

function ProfileBMI({
  styles, user_details, updateUser, isUploadPhot,
  isUploadPhotError, isUploadPhotErrorMsg, ProfilePic, uploadPhotoSuc,
}) {
  const [showChangePicModel, setrenderChangePic] = React.useState(false)
  const [height, setHeight] = useState('')
  const [weight, setWeight] = useState('')
  const [verficationId, setverficationId] = useState('Aadhar')
  const [verficationIdnum, setverficationIdnum] = useState('')
  const [medicalreport, setMedicalreport] = useState('')
  const [images, setImages] = React.useState([])
  const history = useHistory()
  const {
    name, uid, email,
  } = user_details
  let { profile_img } = user_details

  useEffect(() => {
    if (!isUploadPhot) {
      if (isUploadPhotError) {
        showSnackbar(isUploadPhotErrorMsg, {}, 0)
      } else if (!isEmpty(uploadPhotoSuc)) {
        showSnackbar('Profile Picture Updated', {}, 1)
        setrenderChangePic(false)
      }
    }
  }, [isUploadPhot])

  const onPresscalculateBMI = () => {
    const heightInMeters = height / 100
    const bmi = (weight / (heightInMeters * heightInMeters)).toFixed(2)
    if (!height || !weight) {
      showSnackbar('Please enter hight and weight')
    }else {
      updateUser({
        height,
        weight,
        verficationId,
        verficationIdnum,
        medicalreport,
        bmi,
        profile_img,
        uid,
      })
      history.push(pathname_mapping.Plans)
    }
    return null
  }
  const inputRef = React.createRef()
  const onPressChangeprofile = () => {
    uploadFile()
    if (isUploadPhotError) {
      showSnackbar('select profile pic')
    }
  }
  const onClickSelectFile = () => {
    inputRef.current.click()
  }
  const imgfunction = (e) => {
    const file = e.target.files[0]
    const fileReader = new FileReader()
    if (file.type.match('image')) {
      fileReader.onload = function test() {
        const img = document.createElement('img')
        img.src = e.target.result
        document.getElementById('profile').appendChild(img)
      }
      fileReader.readAsDataURL(file)
    }
  }
  const handleFileInput = (e) => {
    setImages([e.target.files[0]])
    profile_img = e.target.files[0]
    imgfunction(e)
  }

  useEffect(() => {

  }, [images])
  const uploadFile = () => {
    // this.thumbnail_image = document.getElementById('thumimg2').src
    // get thumnail image from the genrator
    if (!images) {
      if (!images) showSnackbar('Upload a image')
      return
    }
    const fileName = email + images[0].name
    const params = {
      ACL: 'public-read',
      Body: images[0],
      Bucket: S3_BUCKET,
      Key: fileName,
    }

    myBucket.putObject(params).on('httpUploadProgress')
      .send((err) => {
        if (err) { showSnackbar('Error uploading video') }
      })
    const imagurl = `https://stamin-bucket.s3.ap-south-1.amazonaws.com/${fileName}`
    ProfilePic({ uid, profile_img: imagurl })
    // window.location.reload()
  }
  const renderChangePic = () => {
    return (
      <CustomModal
        visible={showChangePicModel}
        onDismiss={() => setrenderChangePic(false)}
      >
        <div className={styles.modalContainerbox}>
          <CustomText size="medium_1" weight="semi_bold">Change Profile Pic</CustomText>
          <div>
            <button
              type="button"
              className={styles.uploadFileBtn}
              onClick={onClickSelectFile}
            >
              <Icon name={ICONS.UPLOAD} size={14} color={COLORS.GREEN} />
              <CustomText size="small" weight="semi_bold" color="green">Select File</CustomText>
            </button>
            {images && <CustomText size="small" weight="semi_bold" color="green">{images.name}</CustomText>}
            <input
              type="file"
              id="file"
              ref={inputRef}
              onChange={handleFileInput}
              accept="image/jpeg"
              style={{ display: 'none' }}
            />
          </div>
          <div id="profile" />
          <Button text="Confirm" buttonColor="orange" onPress={onPressChangeprofile} />
        </div>
      </CustomModal>
    )
  }
  const isimg = profile_img !== undefined
  return (
    <div className={styles.modalContainer}>
      <div className={styles.detailsContainer}>
        <CustomText size="medium_1" weight="semi_bold" style={{ color: 'cornsilk' }} className={styles.scehead}>User Details</CustomText>
        <div>
          <button type="button" onClick={() => setrenderChangePic(true)}>
            { !isimg ? (<Icon name={ICONS.PROFILE} size={60} className={styles.detailsicon} />)
              : (<img src={profile_img} alt="Profile" className={styles.detailsimage} />)}
          </button>
          {renderChangePic()}
        </div>
        <div className={styles.modalContent}>
          <div className={styles.modalContentone}>
            <CustomInput
              disabled="true"
              label="User Name"
              value={name}
              txt2="*"
              inputProps={{
                type: 'text',
                color: 'red',
              }}
              wrapperStyles={styles.inputWrapper}
              // inputStyles={styles.filterInput}
              placeholder="Enter user name"
              labelColor="red"
            />
            <CustomInput
              onChangeText={e => setHeight(e.target.value)}
              label="Height (in cm)"
              txt2="*"
              inputProps={{
                type: 'Number',
              }}
              wrapperStyles={styles.inputWrapper}
              placeholder="Enter your Height"
            />
            <CustomInput
              onChangeText={e => setWeight(e.target.value)}
              label="Weight (in kg)"
              txt2="*"
              color="white"
              inputProps={{
                type: 'Number',
              }}
              wrapperStyles={styles.inputWrapper}
              placeholder="Enter your Weight"
            />
          </div>
          <div className={styles.modalContenttwo}>
            <select className={styles.droupdown} onChange={e => setverficationId(e.target.value)}>
              <option value="Aadhar">Aadhar Id</option>
              <option value="Pan card">Pan card Id</option>
              <option value="Voter id">Voter Id</option>
            </select>
            <CustomInput
              onChangeText={e => setverficationIdnum(e.target.value)}
              label="Customer ID"
              inputProps={{
                type: 'text',
              }}
              wrapperStyles={styles.inputWrapper}
              placeholder="Id proof"
            />
            <CustomInput
              onChangeText={e => setMedicalreport(e.target.value)}
              label="Medical Report"
              inputProps={{
                type: 'text',
              }}
              wrapperStyles={styles.inputWrapper}
              placeholder="Medical Report"
            />
          </div>
        </div>
        <div className={styles.button}>
          <Button
            text="Submit"
            buttonColor="orange"
            onPress={onPresscalculateBMI}
          />
        </div>
      </div>
    </div>
  )
}
const stylesheet = () => ({

  container: {
    padding: SPACING.SPACE_20,
  },
  scehead: {
    marginLeft: '45%',
  },
  detailsContainer: {
    backgroundColor: '#494949',
    border: '1px solid #ffcc80',
    borderRadius: 12,
    padding: `${SPACING.SPACE_20} ${SPACING.SPACE_20}`,
    width: '90%',
    marginLeft: '5%',
    marginTop: '8%',
  },
  modalContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    padding: SPACING.SPACE_20,
  },
  modalContent: {
    display: 'flex',
    gap: '30px',
    marginLeft: '20%',
    width: '70%',
    marginTop: '-10%',
    marginBottom: '3%',
  },
  modalContentone: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING.SPACE_20,
    width: '100%',
  },
  modalContenttwo: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING.SPACE_20,
    width: '100%',
    marginTop: '22px',
  },
  droupdown: {
    height: '32px',
    borderRadius: '5px',
  },
  detailsimage: {
    border: 'clamp(2px, 0.4vw, 4px) solid wheat',
    borderRadius: '50%',
    cursor: 'pointer',
    height: '130px',
    marginLeft: '20%',
  },
  detailsicon: {
    border: 'clamp(2px, 0.4vw, 4px) solid wheat',
    borderRadius: '50%',
    width: '100px',
    height: '100px',
    padding: '10%',
    marginLeft: '30%',
    marginTop: '20%',
  },
  button: {
    marginLeft: '5%',
    marginTop: '-2%',
  },
  modalContainerbox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: SPACING.SPACE_20,
    padding: SPACING.SPACE_20,
  },
  '@media only screen and (max-width: 940px) ': {
    modalContent: {
      flexWrap: 'wrap',
    },
    scehead: {
      marginLeft: '30%',
      marginBottom: '5%',
    },
    detailsicon: {
      marginLeft: '50%',
      marginTop: '20%',
      marginBottom: '30%',
    },
    detailsimage: {
      border: 'clamp(2px, 0.4vw, 4px) solid wheat',
      borderRadius: '50%',
      cursor: 'pointer',
      height: '110px',
      marginLeft: '50%',
      marginBottom: '30%',
    },
    button: {
      marginTop: '3%',
      marginLeft: '37%',
    },
  },
})
const mapStateToProps = state => ({
  user_details: state.auth.user_details,
  isUploadPhot: state.auth.isUploadPhot,
  isUploadPhotError: state.auth.isUploadPhotError,
  isUploadPhotErrorMsg: state.auth.isUploadPhotErrorMsg,
  uploadPhotoSuc: state.auth.uploadPhotoSuc,
})

const mapDispatchToProps = dispatch => ({
  updateUser: payload => dispatch(editUser(payload)),
  ProfilePic: payload => dispatch(updatepic(payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(stylesheet)(ProfileBMI))
