import { GET_ALL_USERS, GET_ALL_USERS_FAILURE, GET_ALL_USERS_SUCCESS } from './actionTypes'

export function getAllUsers(userType) {
  return {
    type: GET_ALL_USERS,
    userType,
  }
}

export function getAllUsersSuccess(data) {
  return {
    type: GET_ALL_USERS_SUCCESS,
    data,
  }
}

export function getAllUsersFailure(error) {
  return {
    type: GET_ALL_USERS_FAILURE,
    error,
  }
}
